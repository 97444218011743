import types from "./types"

const retrieveReport = (data) => ({
	type: types.RETRIEVE_REPORT,
	data: data
})

const recieveReport = (data) => ({
	type: types.RECIEVE_REPORT,
	data: data
})

export default {
	retrieveReport,
	recieveReport
}