import types from './types'

const INITIAL_STATE = {
    terminals: [],
	loading: true,	
};

const terminalsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.RECEIVE_TERMINAL_STATUSES: {
            return {
                ...state,
                terminals: action.data.terminals,
                loading: action.data.loading
            };
        }
        case types.REQUEST_TERMINAL_STATUSES: {
            return {
                ...state,
                loading: action.data.loading
            };
        }
        default:
            return state;
    }
}

export default terminalsReducer;