import React from "react"
import { withStyles, Divider, Grid, MenuItem, FormControl, Select , Card, CardContent, CardHeader, Container } from "@material-ui/core";

const styles = theme => ({
	StoreSelectionLabelContainer: {
		maxWidth: "150px"
	},
	StoreSelectionLabel: {
		marginBottom: "0",
		paddingTop: "10px",
		paddingBottom: "10px",
	},
	zoneContainer: {
		padding: '20px',
		minHeight: "65vh",
		backgroundColor: '#efefef'
	},
	noResults: {
		display:"flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	zoneItem: {		
		minHeight: "230px",
		"& .MuiCardHeader-root":{
			padding: "10px 10px",
		},
		"& .MuiCardContent-root":{
			padding: "0",
		},

		"& .Content": {
			minHeight: "145px",
			padding: "10px 15px",
			"& .Classification": {
				display:"block",
				fontSize: "1.25em",
			},
		},
		
		"& .Footer": {
			padding: "5px 10px",
			textAlign: "center",
			fontSize: "1.25em",
			fontWeight: "700"
		}
	},
	zoneItemHeader:{
		backgroundColor: '#000000',
		color: '#ffffff',
		textAlign: 'center',
		maxHeight: "50px",		
		whiteSpace: "nowrap",
		padding: "10px 50px",

		
		"& .MuiCardHeader-content":{
			overflow: "hidden",
		},

		"& .MuiTypography-h5": {
			fontSize: '1.5em'
		}
	},
	topMargin: {
		marginTop:"20px"
    }


	
});

const ZoneComponent = ({ zoneName, zoneId, isDefault, classifications, classes }) => {
	return (
		
			<Card className={classes.zoneItem} key={`zone.${zoneId}`}>
				<CardHeader title={zoneName} className={classes.zoneItemHeader} />
				<CardContent>
					<div className="Content">
					{
						classifications.map((classification) => (<span className="Classification">{classification.Name}</span>))
					}
					</div>

					{
						(isDefault ? <div className="Footer">Default Zone</div> : "") 
					}
				</CardContent>
				
			</Card>
	)

}

class KitchenFlowStoresComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.getStores();
	}

	getSelectedStore() {
		const { stores, selectedStoreId } = this.props;

		if (stores !== undefined && stores.length > 0 && 
			selectedStoreId !== undefined || selectedStoreId > 0) 
		{
			let filteredStores = stores.filter(s => s.storeId === selectedStoreId);

			if (filteredStores !== undefined && filteredStores.length > 0) {
				let store = filteredStores[0];

				return store;
			}
		}

		return undefined;
	}

	render() {
		const { stores, selectedStoreId, zones, classes } = this.props;
		
		let selectedStore = this.getSelectedStore();

		return (

			<React.Fragment>
				<Grid container justify="space-between">
					<Grid item xs={12}>
						<p>Select a store using the drop-down menu to view the kitchen classifications that will show in each zone/screen when the item is initially sent to KitchenFlow. Items which have not been classified will appear in the <i>Default Zone</i></p>
					</Grid>

					<Grid item xs={12}>
						<Divider variant="fullWidth" />
					</Grid>

					<Grid item xs={12} >
						<Grid container >
							<Grid item xs={12} className={classes.StoreSelectionLabelContainer} >
								<h3 className={classes.StoreSelectionLabel}>Show Zones For:</h3>
							</Grid>
							<Grid item xs={12} sm={6} md={4}  >
								<FormControl fullWidth className={classes.topMargin}>
									<Select
										id="store-select"
										value={selectedStoreId}
										displayEmpty
										onChange={ (event) => { this.props.getStoreZones(event.target.value) }}
										renderValue={() => {
											if (selectedStore !== undefined) {
												return selectedStore.storeNumber + " - " + selectedStore.name;
											}
									
											return "Select store";
										}}
									>
										{
											stores.map((store) => (
												<MenuItem key={`store.${store.storeId}`} value={store.storeId}>
													{store.storeNumber + " - " + store.name}
												</MenuItem>
											))
										}
									</Select>
								</FormControl>
							</Grid>

						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Divider variant="fullWidth" />
					</Grid>

					<Grid item xs={12} className={classes.zoneContainer} >
					{
						(zones !== undefined && zones.length > 0) ? 
						
							<Grid container spacing={2}>
							{
								zones.map((zone) => (
									
									<Grid item xs={12} sm={6} md={4}  lg={3}  xl={2}>
										<ZoneComponent zoneName={zone.Name} classifications={zone.Classifications} isDefault={zone.IsDefault} classes={classes} />
									</Grid>
								)) 
							}
							</Grid>
						: 
						<Grid container spacing={0} direction="column" align="center" justify="center">
							<Grid item>
								{ selectedStore !== undefined ? `No zones for store ${selectedStore.storeNumber + " - " + selectedStore.name}` : "Select a store" }
							</Grid>
						</Grid>
					}
					</Grid>

				</Grid>

			</React.Fragment>
		)
	}
}

export default withStyles(styles)(KitchenFlowStoresComponent);