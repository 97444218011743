import { connect } from 'react-redux'
import TerminalsComponent from './TerminalsComponent'
import { terminalsOperations } from './duck'
import { dashboardOperations } from '../../duck'

const mapStateToProps = (state) => {
    return {
        ...state,
        login: state.login
    }
};

const mapDispatchToProps = (dispatch) => {
    const getTerminalStatuses = (filterArg) => dispatch(terminalsOperations.getTerminalStatuses(filterArg));
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
    return {
        getTerminalStatuses, 
        updateTitle
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminalsComponent)