import types from './types'

const INITIAL_STATE = {
    users: [],
	loading: true,
	roles: [],
	newUserError: false,
	newUserErrorCause: "",
	creatingOpsUser: false
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.RECEIVE_USERS: {
            return {
                ...state,
                users: action.data.users,
                loading: action.data.loading
            };
        }

        case types.CREATING_OPS: {
            //Flag that Ops users are being created
			return {
				...state,
				creatingOpsUser: true
			};
		}

		case types.CREATED_OPS: {
            //Flag that operation is complete
			return {
				...state,
				creatingOpsUser: false
			};
		}
		
		case types.DELETE_USER: {
            //Remove the user
			let users = state.users.slice();
			users.splice(state.users.indexOf(action.data), 1);

			return {
				...state,
				users: users
			};
        }


        case types.UNSUSPEND_USER:
        case types.SUSPEND_USER: {
            //Suspend them
            action.data.IsSuspended = action.type === types.SUSPEND_USER;

            return {
                ...state,
                //Return the new object if the ids match
                users: state.users.map(user => user.UserId === action.data.UserId ? action.data : user)
            }
        }

        case types.MASS_SUSPEND_USERS: {
            state.users = state.users.map(user => {
                if(action.data.users.indexOf(user.UserId) !== -1)
                    user.IsSuspended = action.data.suspend;
                return user;
            });

            return {
                ...state
            }
        }

        case types.MASS_DELETE_USERS: {
            return {
				...state,
				users: state.users.filter(user => action.data.indexOf(user.UserId) === -1)
            }
        }

        case types.EDIT_USER: {
            return {
                ...state,
                //Return the new object if the ids match
                users: state.users.map(user => user.UserId === action.data.UserId ? action.data : user)
            }
		}
		
		case types.GET_ROLES: {
			return {
				...state,
				roles: action.data
			}
		}

		case types.NEW_USER: {
			//Store error
			if(action.data.error)
			{
				return {
					...state,
					newUserError: true,
					newUserErrorCause: action.data.cause
				};
			}

			let users = state.users.slice();
			users.push(action.data);

			return {
				...state,
				users,
				newUserError: false,
				newUserErrorCause: ""
			}
		}

		case "ResetErrors":
			{
				return {
					...state,
					newUserError: false,
					newUserErrorCause: ""
				}
			}

        default:
            return state;
    }
}

export default userReducer;