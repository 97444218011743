import React from 'react';
import WbHelpers from './WbHelpers';
import classNames from "classnames";

class WbValueCurrency extends React.Component {
    render() {
        const { altcolour,value, classes } = this.props;
        var cls = classNames(classes.wbValueText, altcolour && classes.wbMidFillColour);
        return(<div className={cls}>{value !== undefined ? WbHelpers.formatCurrency(value) : "--" }</div>)
    }
}

export default WbValueCurrency; 