import { connect } from 'react-redux';
import { dashboardOperations } from "../../duck"
import { opsOperations } from "./duck"
import OpsStockComponent from './OpsStockComponent';

const mapStateToProps = (state) => {
	return {
		login:state.login,
		ops:state.ops
	}
}

const mapDispatchToProps = (dispatch) => {
	const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
	const getStoreDetail = (storeNumber)=> dispatch(opsOperations.getStoreDetail(storeNumber));
	const getMenuData = (terminalIdentifier)=> dispatch(opsOperations.getMenuData(terminalIdentifier));
	const setSkuStock = (sku,stocked)=> dispatch(opsOperations.setSkuStock(sku,stocked));
	const setRangeMode = ()=> dispatch(opsOperations.setRangeMode());
	const setStockMode = ()=> dispatch(opsOperations.setStockMode());

	return {
		updateTitle,
		getStoreDetail,
		setSkuStock,
		setRangeMode,
		setStockMode,
		getMenuData
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OpsStockComponent)