import uuidv5 from  'uuid/v5'
import uuidv4 from  'uuid/v4'
function DuckApiCalls(context) {
  var self = this;
  self.context = context;

  //self.baseUrl = "http://localhost:58467/";
    self.baseUrl = "/api/";

  self.getBearerToken = function() {
    if (self.context.token == null) return "";

    var tok = "Bearer " + self.context.token;

    return tok;
  };

  function cacheBust(base) {
    var firstSeperator = base.indexOf("?") === -1 ? "?" : "&";
    var retVal =
      base + firstSeperator + Math.round(new Date().getTime() / 1000);
    return retVal;
  }

  self.getTraceId = () => {
    return uuidv5(uuidv4(), uuidv5.DNS);
  };

  self.postData = (url = ``, data = {}, sendAuth = true) => {
    // Default options are marked with *
    var newUrl = cacheBust(self.baseUrl + url);
    return fetch(newUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit

      headers: {
        "Content-Type": "application/json; charset=utf-8",
        TraceId: self.getTraceId()
        // Authorization: sendAuth ? self.getBearerToken() : ""
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
  };

  self.postFile = (url = ``, file, sendAuth = true) => {
    // Default options are marked with *
    var newUrl = cacheBust(self.baseUrl + url);
    return fetch(newUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit

      headers: {
        "Content-Type": "application/octet-stream",
        TraceId: self.getTraceId()
        // Authorization: sendAuth ? self.getBearerToken() : ""
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: file // body data type must match "Content-Type" header
    });
  };


  self.patchData = (url = ``, data = {}, sendAuth = true) => {
    // Default options are marked with *
    var newUrl = cacheBust(self.baseUrl + url);
    return fetch(newUrl, {
      method: "PATCH", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit

      headers: {
        "Content-Type": "application/json; charset=utf-8",
        TraceId: self.getTraceId()
        // Authorization: sendAuth ? self.getBearerToken() : ""
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
  };

  self.deleteDataWithBody = (url = ``, data = {}, sendAuth = true) => {
    // Default options are marked with *
    var newUrl = cacheBust(self.baseUrl + url);
    return fetch(newUrl, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit

      headers: {
        "Content-Type": "application/json; charset=utf-8",
        TraceId: self.getTraceId()
        // Authorization: sendAuth ? self.getBearerToken() : ""
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
  };

  self.putData = (url = ``, data = {}) => {
    // Default options are marked with *
    return fetch(cacheBust(self.baseUrl + url), {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit
      headers: {
        //  Authorization: self.getBearerToken(),
        "Content-Type": "application/json; charset=utf-8",
        TraceId: self.getTraceId()
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
  };

  self.getData = (url = ``, useBase = true) => {
    // Default options are marked with *
    var completeUrl = "";
    if (useBase) {
      completeUrl = self.baseUrl + url;
    } else {
      completeUrl = url;
    }

    return fetch(cacheBust(completeUrl), {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit

      headers: {
        "Accept-Encoding":"gzip",
        "Content-Type": "application/json; charset=utf-8",
        TraceId: self.getTraceId()
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer" // no-referrer, *client
    });
  };

  self.deleteData = (url = ``, useBase = true) => {
    // Default options are marked with *
    var completeUrl = "";
    if (useBase) {
      completeUrl = self.baseUrl + url;
    } else {
      completeUrl = url;
    }

    return fetch(cacheBust(completeUrl), {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit

      headers: {
        //   Authorization: self.getBearerToken(),
        "Content-Type": "application/json; charset=utf-8",
        TraceId: self.getTraceId()
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer" // no-referrer, *client
    });
  };
}

export default DuckApiCalls;
