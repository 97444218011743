import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

class AmTouchscreensOutOfAction extends React.Component {
    constructor(props) {
        super(props);
        am4core.useTheme(am4themes_animated);
    }
    componentDidMount() {
        const { id, value } = this.props;
        let chart = am4core.create(id, am4charts.GaugeChart);
        let axis = chart.xAxes.push(new am4charts.ValueAxis());         
        axis.renderer.labels.template.fill = am4core.color("#fff");
        axis.renderer.labels.template.fontSize = 11;
        axis.min = 0;
        axis.max = 100;
        axis.strictMinMax = true;
        chart.innerRadius = -25;
        let range = axis.axisRanges.create();
        range.value = 0;
        range.endValue = value;
        range.axisFill.fillOpacity = 1;
        range.axisFill.fill = am4core.color("#fff");
        range.axisFill.zIndex = -1;
        axis.renderer.labels.template.adapter.add("text", (label, target, key) => {
			return label + "%";
		});
		this.chart = chart;
	}    
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
    render() {
		const { id, width = "300px" } = this.props
        return (<div id={id} style={{ width, height: "200px", margin: "auto" }}></div>);
    }
}

export default AmTouchscreensOutOfAction;