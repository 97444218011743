import { connect } from 'react-redux';
import { dashboardOperations } from "../../duck"
import { opsOperations } from "./duck"
import OpsOrdersComponent from './OpsOrdersComponent';

const mapStateToProps = (state) => {
	return {
		login:state.login,
		ops:state.ops
	}
}

const mapDispatchToProps = (dispatch) => {
	const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
	const getStoreDetail = (storeNumber)=> dispatch(opsOperations.getStoreDetail(storeNumber));
	const getOrderDetails = (storeNumber)=> dispatch(opsOperations.getOrderDetails(storeNumber));

	return {
		updateTitle,
		getStoreDetail,
		getOrderDetails
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OpsOrdersComponent)