import types from './types'

const requestTerminalStatuses = (data) => ({
    type: types.REQUEST_TERMINAL_STATUSES,   
    data: data
})

const receiveTerminalStatuses = (data) => ({
    type: types.RECEIVE_TERMINAL_STATUSES,
    data: data
});

export default {
    requestTerminalStatuses,
    receiveTerminalStatuses,
}