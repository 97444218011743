import types from './types';

const INITIAL_STATE = {
    items: {
        "Overall": {
          "TotalBaskets": 0,
          "AverageBasketTotal": .0,
          "BasketsTotal": .0
        },
        "ByDay": [],
        "ByHour": [],
        "TopSellingItems": [],
        "ItemsInOrder": [],
        "DbMs": 0
      },
    loading: false
};

const itemsReportReducer = (state = INITIAL_STATE, action) => {
    //console.log("ordersReportReducer: action.type = " + action.type);
    switch(action.type) {
        case types.RECEIVE_ITEMS_STATS: {
            return {
                ...state,
                items: action.data.items,
                loading: action.data.loading
            };
        }
        case types.REQUEST_ITEMS_STATS: {
            return {
                ...state,
                loading: action.data.loading
            };
        }
        default:
            return state;
    }
}

export default itemsReportReducer;