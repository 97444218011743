const RECEIVE_USERS = "RECEIVE_USERS";
const REQUEST_USERS = "REQUEST_USERS";
const DELETE_USER = "DELETE_USER";
const EDIT_USER = "EDIT_USER";
const MASS_DELETE_USERS = "MASS_DELETE_USERS";
const GET_STORES = "GET_STORES";
const ADD_USER = "ADD_USER";

const RESET_PASSWORD = "RESET_PASSWORD";
const PASSWORD_RESET = "PASSWORD_RESET";

export default {
    RECEIVE_USERS,
    REQUEST_USERS,
    DELETE_USER,
    EDIT_USER,
	MASS_DELETE_USERS,
	GET_STORES,
	ADD_USER,
	RESET_PASSWORD,
	PASSWORD_RESET
}