import types from './types';

const requestTouchScreenOrdersStatsActions = (data) => ({
    type: types.REQUEST_TOUCH_SCREEN_ORDERS_STATS,   
    data: data
});

const receiveTouchScreenOrdersStatsActions = (data) => ({
    type: types.RECEIVE_TOUCH_SCREEN_ORDERS_STATS,
    data: data
});

export default {
    requestTouchScreenOrdersStatsActions,
    receiveTouchScreenOrdersStatsActions,
}