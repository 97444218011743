import Creators from './actions';
import DuckApiCalls from "../../../../utils/duckApiCalls";
import UnauthorizedError from "../../../../utils/unauthorizedError";
import moment from 'moment';

const requestBasketsStatsAction = Creators.requestBasketsStatsActions;
const receiveBasketsStatsAction = Creators.receiveBasketsStatsActions;

const getBasketsStats = (dateFrom, dateTo) => {
    return (dispatch, getState) => {
        dispatch(requestBasketsStatsAction({
            loading: true
        }));
        new DuckApiCalls().getData("reporting/baskets?from=" + moment(dateFrom).format('DD-MM-YYYY') + "&to=" + moment(dateTo).format('DD-MM-YYYY')) 
        .then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
            return response.json()
            .then(payload => {
                dispatch(receiveBasketsStatsAction({
                    baskets: payload,
                    loading: false
                }));
            });
        })
        .catch(console.error);
    };
};

export default {
    getBasketsStats
}