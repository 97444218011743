import React from 'react';
import { withStyles } from '@material-ui/core';
import { Grid, CircularProgress, Toolbar, Paper } from '@material-ui/core';
import AmBasketValuesByHourChart from './AmBasketValuesByHourChart';
import AmBasketValuesByDayChart from './AmBasketValuesByDayChart';
import AmBasketValuesByDateChart from './AmBasketValuesByDateChart';
import AmBasketValuesByKioskChart from './AmBasketValuesByKioskChart';
import AmBasketTakeawayAndEatInChart from './AmBasketTakeawayAndEatInChart';
import DatePicker from 'react-datepicker';
import ReportHelpers from '../ReportHelpers';
import RpSimpleStackedBarChart from '../components/RpSimpleStackedBarChart'
import BasketValuesByIndividualKioskTable from './BasketValuesByIndividualKioskTable';
import moment from 'moment';
import DatePickerInput from '../DatePickerHelper';
import PageTitle from '../../../PageTitle';

const styles = theme => ({
    root: {
		//padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 3}px`,
		//minWidth: "1300px"
	},
	reportArea: {
		paddingTop:`${theme.spacing() * 4}px`,
		paddingLeft: `${theme.spacing() * 3}px`,
		paddingRight: `${theme.spacing() * 3}px`,
		[theme.breakpoints.down("sm")]: {
			paddingTop: theme.spacing() * 2,
			paddingLeft: theme.spacing() * 1,
			paddingRight: theme.spacing() * 1
		}
	},
	paper: {
		padding: `${theme.spacing() * 3}px ${theme.spacing() * 2}px`,
		height: "100%",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			padding: `${theme.spacing() * 1}px ${theme.spacing() * 1}px`,
		}
	},
	paperValues: {
		padding: `${theme.spacing() * 3}px ${theme.spacing() * 2}px`,
		height: "100%",
		textAlign: "left",
		display: "flex",
		justifyContent: "left",
		alignItems: "left",
		flexDirection: "column"
	},
    title: {
        fontSize: "20px",
        color: "#999999"
	},
	toFromField: {
		textAlign: "right",
		color: "#999",
		fontWeight: "bold"
	},
	toFromFieldCaption: {
		marginRight: `${theme.spacing() * 2}px`
	},
	tableHeading: {
		marginLeft: theme.spacing() * 3
	},
    pointerCursor: {
        cursor: "pointer"
	},
	hidden: {
		display:"none"
	},
	stopClicks: {
		pointerEvents:"none"
	},
	valueHeading: {
		fontSize:"1.5rem"
	},
	chartTitle: {
		fontSize:"1.31rem",
		marginTop: "8px",
		marginBottom: "15px",
		textAlign: "left"
	},
	valueValue: {
		fontSize:"3.5rem",
		fontWeight:"bold"
	},
	wrapper: {
		width:"100%",
		height: "600px",
		overflowY: "auto",		
		overflowX: "auto"
	},    
	loading: {
        textAlign: "center",
        padding: theme.spacing() * 2
    }, 
});

class OrdersReportComponent extends React.Component {
	constructor(props) {
		super(props);	
		let dt = new Date();
		let ed = moment(dt).toDate();
		let sd =  moment(dt).subtract(7, "days").toDate();
		let md = moment(dt).subtract(3, "months").toDate();
		let xd = dt;
		this.state = { 
			maxDate: xd,
			minDate: md,
			startDate: sd, 
			endDate: ed,
		};
	}

	componentDidMount() {
		this.props.updateTitle("Orders");
		this.props.getOrdersStats(this.state.startDate,this.state.endDate);
	}

	setEndDate = (date) => {
		this.setState({endDate: date});
		this.update(this.state.startDate,date);
	}
	setStartDate = (date) => {
		this.setState({startDate: date});
		this.update(date,this.state.endDate);
	}
	update = (start, end) => {
		this.props.getOrdersStats(start,end);
		//console.log("UPDATE: from = " + start + ", to = " + end);
	}
	render() {
		const { classes } = this.props;
		const { orders, loading } = this.props.ordersReport;
		const { startDate, endDate, minDate, maxDate } = this.state;
		return (
            <div className={classes.root}>
			    <Toolbar variant="dense">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={6}>
							<PageTitle/>
                        </Grid>

						<Grid item xs={6}>
							<Grid container flexWrap="nowrap" spacing={8} justify="flex-end" alignItems="center">
								<Grid item xs={"auto"} className={classes.toFromField}>
									<span className={classes.toFromFieldCaption}>From</span>
									<DatePicker 
										selected={startDate}
										onChange={date => this.setStartDate(date)}
										selectsStart
										startDate={startDate}
										minDate={minDate}
										maxDate={maxDate}
										endDate={endDate}
										dateFormat="dd/MM/yyyy"
										showPopperArrow={false}
										popperPlacement="bottom-end"
										customInput={<DatePickerInput/>}
										/>
								</Grid>

								<Grid item xs={"auto"} className={classes.toFromField}>
									<span className={classes.toFromFieldCaption}>To</span>
									<DatePicker
										popperPlacement="bottom-end"
										selected={endDate}
										onChange={date => this.setEndDate(date)}
										selectsEnd
										startDate={startDate}
										endDate={endDate}
										minDate={startDate}
										maxDate={maxDate}
										dateFormat="dd/MM/yyyy"
										showPopperArrow={false}
										customInput={<DatePickerInput />}
										/>
                    			</Grid>
							</Grid>
						</Grid>
                    </Grid>
                </Toolbar>
				{loading? (
					<Grid container className={classes.reportArea} >
						<Grid item xs={12} className={classes.loading}>
							<CircularProgress/>
						</Grid>
					</Grid>
				):(
					<Grid container className={classes.reportArea} spacing={1}>
						<Grid item xs={12} md={4}>
							<Paper className={classes.paperValues}>
								<div className={classes.valueHeading}>Number of orders</div>
								<div className={classes.valueValue}>{ReportHelpers.formatNumber(Math.round(orders.Overall.TotalBaskets))}</div>
							</Paper>
						</Grid>

						<Grid item xs={12} md={4}>
							<Paper className={classes.paperValues}>
								<div className={classes.valueHeading}>Orders Total</div>
								<div className={classes.valueValue}>{ReportHelpers.formatCurrency(orders.Overall.BasketsTotal)}</div>	
							</Paper>
						</Grid>

						<Grid item xs={12} md={4}>					
							<Paper className={classes.paperValues}>
								<div className={classes.valueHeading}>Average order value</div>
								<div className={classes.valueValue}>{ReportHelpers.formatCurrency(orders.Overall.AverageBasketTotal)}</div>						
							</Paper>
						</Grid>
						<Grid item xs={12} md={6}>
							<Paper className={classes.paper}>
								<AmBasketValuesByDateChart data={orders.ByDate}/>	
							</Paper>					
						</Grid>	
						<Grid item xs={12} md={6}>
							<Paper className={classes.paper}>
								<AmBasketValuesByHourChart data={orders.ByHour}/>	
							</Paper>					
						</Grid>	
						<Grid item xs={12} md={6}>
							<Paper className={classes.paper}>
								<AmBasketValuesByDayChart data={orders.ByDay}/>	
							</Paper>					
						</Grid>	
						<Grid item xs={12} md={6}>
							<Paper className={classes.paper}>
								<RpSimpleStackedBarChart
									classes={classes}
									heading="Order values by store"
									tableHeight={430}
									data={orders.ByStore} 
									captionkey="Store" 
									valuekey="Total" 
									sortkey="Total"													
									format={(v) => ReportHelpers.formatCurrency(v)}/>
							</Paper>
						</Grid>
						<Grid item xs={12} md={6}>
							<Paper className={classes.paper}>
								<AmBasketValuesByKioskChart data={orders.ByKiosk}/>		
							</Paper>	
						</Grid>	
						<Grid item xs={12} md={6}>
							<Paper className={classes.paper}>
								<BasketValuesByIndividualKioskTable 
									classes={classes}
									heading="Order values by individual POS"
									data={orders.ByIndividualKiosk}
									tableHeight={430}/>
							</Paper>
						</Grid>	
						<Grid item xs={12} md={6}>
							<Paper className={classes.paper}>
								<AmBasketTakeawayAndEatInChart
									classes={classes}
									heading="Order Eat In or Takeaway"
									data={orders.IsEatInOrIsTakeaway}
									tableHeight={430}/>
							</Paper>
						</Grid>	
						<Grid item xs={12} style={{color:"#CCC"}}  className={classes.hidden}>
							{orders.DbMs}ms
						</Grid>	
				</Grid>)}
			</div>
		)
	}
}

export default withStyles(styles)(OrdersReportComponent);