// actions.js
import types from "./types";

const requestProducts = (criteria) => ({
    type: types.REQUEST_PRODUCTS,
    data: criteria
});

const receiveProducts = (products) => ({
    type: types.RECEIVE_PRODUCTS,
    data: products
});

const requestClassifications = () => ({
    type: types.REQUEST_CLASSIFICATIONS
});

const receiveClassifications = (classifications) => ({
    type: types.RECEIVE_CLASSIFICATIONS,
    data: classifications
});

const updateProductClassification = (classification) => ({
    type: types.UPDATE_PRODUCT_CLASSIFICATION,
    data: classification
});

const requestStores = () => ({
    type: types.REQUEST_STORES
});

const receiveStores = (stores) => ({
    type: types.RECEIVE_STORES,
    data: stores
});

const requestStoreZones = (storeId) => ({
    type: types.REQUEST_STORE_ZONES,
    data: storeId
});

const receiveStoreZones = (zones) => ({
    type: types.RECEIVE_STORE_ZONES,
    data: zones
});

const requestChangeRowsPerPage = (rows) => ({
    type: types.REQUEST_CHANGE_ROWS_PER_PAGE,
    data: rows
});

const requestChangePage = (page) => ({
    type: types.REQUEST_CHANGE_PAGE,
    data: page
});

const requestProductSearch = (searchPhrase) => ({
    type: types.REQUEST_PRODUCT_SEARCH,
    data: searchPhrase
});

const requestChangeSortBy = (column) => ({
    type: types.REQUEST_CHANGE_SORT_BY,
    data: column
});

const requestChangeTab = (tabIndex) => ({
    type: types.REQUEST_CHANGE_TAB,
    data: tabIndex
});

export default {
    requestProducts,
    receiveProducts,
    requestClassifications,
    receiveClassifications,
    updateProductClassification,
    requestStores,
    receiveStores,
    requestStoreZones,
    receiveStoreZones,
    requestChangeRowsPerPage,
    requestChangePage,
    requestProductSearch,
    requestChangeSortBy,
    requestChangeTab
}