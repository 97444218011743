import Creators from "./actions";
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";

const requestScreenshotsJsonAction = Creators.requestScreenshotsJson;
const receiveScreenshotsJsonAction = Creators.receiveScreenshotsJson;

const loadScreenshotsJson = () => {
	return (dispatch, getState) => {
	//	dispatch(requestScreenshotsJsonAction({
	//		loading: true
	//	}));

		new DuckApiCalls().getData("terminalstatus/GetScreenshotStatus").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				dispatch(receiveScreenshotsJsonAction({
					receivedData: payload,
					loading: false
				}));
			});
		}).catch(console.error);
	};
};

export default {
	loadScreenshotsJson
};
