import types from "./types"

const INITIAL_STATE = {
    authenticated: false,
    loggingIn: false,
	user: null,
	error: null,
	cause: ""
}

const loginReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.LOGGING_IN_USER: {
            return {
                ...state,
                loggingIn: true
            }
        }

		case types.CHECK_USER:
        case types.LOGIN_USER: {

            return {
                ...state,
				...action.data,
                authenticated: !action.data.error,
				loggingIn: false
            }
		}
		
		case types.UPDATE_USER: {
			return {
				...state,
				...action.data
			}
		}

        case types.LOGOUT_USER: {
            return {
                ...state,
                authenticated: false
            }
		}
		
		default:
			return state;
    }
}

export default loginReducer;