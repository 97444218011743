import Creators from "./actions";
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";

const loginUserAction = Creators.loginUser;
const loggingInUserAction = Creators.loggingInUser;
const logoutUserAction = Creators.logoutUser;
const checkUserAction = Creators.checkUser;

const loginUser = (email, password) => {
	return (dispatch, getState) => {
		dispatch(loggingInUserAction());
		new DuckApiCalls().postData("account/login", {email, password}).then(response => {
			return response.json().then(payload => {
				dispatch(loginUserAction(payload));
			});
		}).catch(console.error);
	}
};

const logoutUser = () => {
	return (dispatch, getState) => {
		new DuckApiCalls().postData("account/logout").then(response => {
			return response.json().then(payload => {
				if(payload)
					dispatch(logoutUserAction());
			})
		}).catch(console.error);
	}
};

const checkUser = () => {
	return (dispatch, getState) => {
		new DuckApiCalls().getData("account/checkUser").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				if(payload)
					dispatch(checkUserAction(payload));
			})
		}).catch(console.error);
	}
}

export default {
	loginUser,
	logoutUser,
	checkUser
}