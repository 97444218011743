import Creators from "./actions";
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";

const requestMenusJsonAction = Creators.requestMenusJson;
const receiveMenusJsonAction = Creators.receiveMenusJson;

const loadMenusJson = () => {
	return (dispatch, getState) => {
		dispatch(requestMenusJsonAction({
			loading: true
		}));

		new DuckApiCalls().getData("management/GetMenus").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				dispatch(receiveMenusJsonAction({
					menus: payload,
					loading: false
				}));
			});
		}).catch(console.error);
	};
};

export default {
  loadMenusJson
};
