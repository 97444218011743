import { connect } from 'react-redux';
import { loginOperations } from "./duck"
import LoginComponent from './LoginComponent';

const mapStateToProps = (state) => {
	return {
		...state.login
	}
}

const mapDispatchToProps = (dispatch) => {
	const loginUser = (email, password) => dispatch(loginOperations.loginUser(email, password));
	const logoutUser = () => dispatch(loginOperations.logoutUser());
	const checkUser = () => dispatch(loginOperations.checkUser());

	return {
		loginUser,
		logoutUser,
		checkUser
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)