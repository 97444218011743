import React from "react"
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search'
import TillSendIcon from "@material-ui/icons/Done";
import { withStyles, CircularProgress} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Toolbar, Grid, Table, TableCell, TableBody, TableRow, TableHead, TablePagination, TableSortLabel, MenuItem, FormControl, Select, TextField, IconButton, Tooltip } from "@material-ui/core";

const productColumns = [
	{ col: "SKU", sort: true, className: "productSKU" },
	{ col: "Name", sort: true, className: "productName"  },
	{ col: "TillsSend", className: "productTillsSend"  },
	{ col: "KitchenClassification", title: "Classification", sort: true, className: "productClassification" },
	{ col: "Description", className: "productDescription"  }
];

const styles = theme => ({
    loading: {
        textAlign: "center",
        padding: theme.spacing.unit * 2
    },
    search: {
        maxWidth: "0",
        transition: "max-width 0.5s ease"
    },
	noResults: {
		textAlign: "center"
	},
	productSKU: {
		width: "10vw"
	},
	productName: {
		width: "25vw"
	},

	productTillsSend: {
		width: "5vw",
		textAlign: "center"
	},

	productClassification: {
		width: "15vw"
	},
	productDescription: {
		width: "50vw"
	},
	loadingWrapper: {
		minHeight: "60vh"
	},
	loadingOverlay: {
		position: "fixed",
		width: "100%",
		height: "100%",
		top: "0",
		left: "0",
		right: "0",
		bottom: "0",
		backgroundColor: 'rgba(0, 0, 0, 0.25)',
		cursor: "pointer",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
})

const ProductRow = (props) => {
	const {
		classifications,
		product,
		onChangeClassification,
		classes
	} = props;

	return (
		<TableRow>
			<TableCell>
				{product.SKU}
			</TableCell>
			
			<TableCell>
				{product.Name}
			</TableCell>

			<TableCell className={classes.productTillsSend}>
				{product.TillsSend ? <TillSendIcon /> : null}
			</TableCell>
			
			<TableCell>
				<FormControl fullWidth >
				<Select  fullWidth 
				value={product.KitchenClassificationId}
				onChange={(e) => onChangeClassification(e)}
				>
					<MenuItem key={`${product.SKU}.None`} value=''>
						NO CLASSIFICATION
					</MenuItem>
					{
						classifications.map((classification) => (
						<MenuItem key={`${product.SKU}.${classification.KitchenClassificationId}`} value={classification.KitchenClassificationId}>
							{classification.Name}
						</MenuItem>
					))
					}
				</Select>
				</FormControl>
			</TableCell>

			<TableCell>
				{product.Description}
			</TableCell>
		</TableRow>
	)
};

ProductRow.propTypes = {
	product: PropTypes.object,
	classes: PropTypes.object,
	onChangeClassification: PropTypes.func
};

const ClassificationRow = (props) => {
	const { 
		classification
	} = props;

	return <TableRow>
		<TableCell>
			{classification.KitchenClassificationId}
		</TableCell>
		
		<TableCell>
			{classification.Name}
		</TableCell>
	</TableRow>
};

ClassificationRow.propTypes = {
	classification: PropTypes.object,
	classes: PropTypes.object
};

class KitchenFlowProductsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchPhrase: ""
		};
	}

	componentDidMount() {
		this.props.getClassifications();
		this.props.getProducts();
	}

	onSort = (column) => {
		this.props.changeSortBy(column);
	}
  
	onChangeClassification  = (ev, product) => {
		if (ev.target.value !== product.KitchenClassificationId) {
			this.props.updateProductClassification({ 
				SKU: product.SKU, 
				Name: product.Name,
				Description: product.Description, 
				KitchenClassificationId: ev.target.value
			});
		}
	}

	onSearchClick = () => {
		this.props.productSearch(this.state.searchPhrase);
    }

	render() {
		const { classes, products, classifications, totalResults, loading, initialLoad, sortColumn, sortDirection, rowsPerPage, page, selectedTab} = this.props;

		return (
			<React.Fragment>
				<Toolbar variant="dense">
					<Grid container justify="space-between" alignItems="center">
						<Grid item xs={12}>
							<Grid container alignItems="baseline" justify="flex-end">
								<Grid item xs="auto">
									<TextField type="search" 
										onKeyPress={(e) => {
											if (e.key === 'Enter') {
												this.onSearchClick();
											}
										}}
										
										label="Search..."
										InputProps={{
											endAdornment: (
											<InputAdornment>
											<Tooltip title="Product search">
												<IconButton onClick={this.onSearchClick}>
												<SearchIcon />
												</IconButton>
												</Tooltip>
											</InputAdornment>
											)
										}}
										
										onChange={(ev) => { this.setState({ searchPhrase: ev.target.value }) }}></TextField>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>

				<Table>
					<TableHead>
						<TableRow>
							{productColumns.map((column) => (
								<TableCell key={column.col || column} className={ `${classes[column.className]}`}>
									<TableSortLabel
										active={sortColumn === column.col && column.sort}
										direction={sortDirection}
										onClick={() => column.sort && this.onSort(column)} >
										{column.title || column.col || column}										
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{ totalResults ? products.map((product) => (
							<ProductRow
								key={product.SKU}
								state={this.state}
								product={product}
								classifications={classifications}
								classes={classes}
								onChangeClassification={(e) => this.onChangeClassification(e, product)}
							/>
						) ) : 
							<TableRow>
								<TableCell className={classes.noResults} colSpan={productColumns.length}>
									{ loading &&  initialLoad ? "Loading" : "No Results"  } 
								</TableCell>
							</TableRow>
						}
					</TableBody>
				</Table>

				<TablePagination component="div"
					page={page}
					count={totalResults}
					onChangePage={  (event, newPage) => { this.props.changePage(newPage) } }
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[25, 50, 75, 100]}
					onChangeRowsPerPage={ (event) => { this.props.changeRowsPerPage(event.target.value)} }/>
				{
					loading ?
					<div className={classes.loadingWrapper}>
						<div className={classes.loadingOverlay}>
							<CircularProgress />
						</div>
					</div>
					
					: ''
				}
			</React.Fragment>
		)
	}
}

export default withStyles(styles)(KitchenFlowProductsComponent);