import types from './types';

const INITIAL_STATE = {
    outOfStock: [],
    loading: false
};

const outOfStockReportReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.RECEIVE_OUT_OF_STOCK: {
            return {
                ...state,
                outOfStock: action.data.outOfStock,
                loading: action.data.loading
            };
        }
        case types.REQUEST_OUT_OF_STOCK: {
            return {
                ...state,
                loading: action.data.loading
            };
        }
        default:
            return state;
    }
}

export default outOfStockReportReducer;