import { connect } from 'react-redux';
import ItemsReportComponent from './ItemsReportComponent';
import { itemsReportOperations } from './duck';
import { dashboardOperations } from '../../../duck';

const mapStateToProps = (state) => {
    return {
        ...state,
        login: state.login
    }
};

const mapDispatchToProps = (dispatch) => {
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
    const getItemsStats = (dateFrom, dateTo) => dispatch(itemsReportOperations.getItemsStats(dateFrom, dateTo));
    return {
        updateTitle, 
        getItemsStats
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsReportComponent)