// DashboardComponent.jsx

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, Typography, Toolbar } from '@material-ui/core';
import PageTitle from '../../PageTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: "600"
  },
  paper: {
    padding: theme.spacing() * 2,
    margin: theme.spacing() * 1,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  progressWrapper: {
    padding: theme.spacing() * 2
  },
  root: {
    textAlign: "center",
    padding: "15px"
  },
  imageWrapper: {
    maxWidth: "100%"
  },
  image: {
    maxWidth: "100%",
    maxHeight: "300px"

  },
  topTitle: {
    marginBottom: "20px"
  },
  timeTitle: {
    fontSize:"12px"
  }

});




class ScreenshotsComponent extends React.Component {


  constructor(props) {
    super(props);

  }


  componentDidMount() {
    this.props.onLoadScreenshotsJson();
    this.props.updateTitle("Screenshots");

    this.timer = setInterval(function() { 
      this.props.onLoadScreenshotsJson();
    }.bind(this), 5000);

    console.log("Loaded");
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    console.log("Unloading");
  }

  render() {
    const { classes, stores, loading, cacheBust } = this.props;

    return (
      <div className={classes.root}>
        <Toolbar variant="dense">
          <Grid container justify="flex-start" alignItems="center" spacing={16}>
            <Grid item xs={6} md="auto">
              <PageTitle />
            </Grid>
          </Grid>
        </Toolbar>



        <Grid container spacing={0}>
          <Grid item xs={12}>
            {loading ? (
              <Grid justify="center" container>
                <Grid item className={classes.progressWrapper}>
                  <CircularProgress></CircularProgress>
                </Grid>
              </Grid>
            ) : stores.map((store, i) => (
              <Paper key={"stx" + i} className={classes.paper}>

                <Typography variant={'h5'} className={classes.topTitle}> {store.StoreNumber} - {store.StoreName}</Typography>


                <Grid container spacing={8}>


                  {store.Screenshots.map((ss, tidx) => (

                    <Grid key={"scrs" + tidx} item xs={4} sm={3} >

                      <Card className={classes.root}>
                        <Typography className={classes.title} color="textPrimary" gutterBottom>
                          {ss.Label}
                        </Typography>
                        <Typography color="textSecondary"  className={classes.timeTitle} gutterBottom>
                          {moment(ss.ScreenshotReceived).fromNow()}
                        </Typography>

                        <div className={classes.imageWrapper} >
                          <img className={classes.image} src={'/Screenshots/' + ss.Filename + cacheBust}></img>
                        </div>

                      </Card>
                    </Grid>




                  ))}

                </Grid>




              </Paper>
            ))}
          </Grid>





        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(ScreenshotsComponent);

