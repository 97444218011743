import types from './types';

const INITIAL_STATE = {
    estateActions: [],
    estateMenuItems: [],
    estateStores: [],
    estateActionsLoading: false,
    estateMenuItemsLoading: false,
    estateStoresLoading: false,
};

const estateEventsReportReducer = (state = INITIAL_STATE, action) => {
    //console.log("estateEventsReportReducer: action.type = " + action.type);
    switch(action.type) {
        case types.RECEIVE_ESTATE_ACTIONS: {
            return {
                ...state,
                estateActions: action.data.estateActions,
                estateActionsLoading: action.data.estateActionsLoading
            };
        }
        case types.REQUEST_ESTATE_ACTIONS: {
            return {
                ...state,
                estateActionsLoading: action.data.estateActionsLoading
            };
        }
        case types.RECEIVE_ESTATE_MENU_ITEMS: {
            return {
                ...state,
                estateMenuItems: action.data.estateMenuItems,
                estateMenuItemsLoading: action.data.estateMenuItemsLoading
            };
        }
        case types.REQUEST_ESTATE_MENU_ITEMS: {
            return {
                ...state,
                estateMenuItemsLoading: action.data.estateMenuItemsLoading
            };
        }
        case types.RECEIVE_ESTATE_STORES: {
            return {
                ...state,
                estateStores: action.data.estateStores,
                estateStoresLoading: action.data.estateStoresLoading
            };
        }
        case types.REQUEST_ESTATE_STORES: {
            return {
                ...state,
                estateStoresLoading: action.data.estateStoresLoading
            };
        }
        default:
            return state;
    }
}

export default estateEventsReportReducer;