import React from "react";
import { Grid, Paper, withStyles, Typography, Box, FormControlLabel, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import { purple } from '@material-ui/core/colors';
import OrderRow from './OrderRow';


const MorrisonsAgendaBold = "MorrisonsAgenda Bold";
const colour03 = "rgba(237,28,36,1)";
const colour05 = "rgba(0,137,59,1)";
const locolor = colour05;
const hicolor = colour03;

const styles = (theme) => ({
  notificationBox: {
    textAlign: "center",
    width: "100%",
    marginBottom: "20px",
    display: "block",
  },
  labelText: {
    marginTop: "-3px",
    fontWeight: "600"
  },
  noteText: {
    fontSize: "0.8em"
  },
  redText: {
    color: "#cc0000"
  },
  greenText: {
    color: "#00563F"
  },
  paperPadding: {
    padding: "10px",
    marginBottom: "10px"
  },
  minHeight: {
    minHeight: "200px"
  },
  borderedBox: {
    textAlign: "center",
    border:"1px solid #000",
    width:"70%",
    margin:"auto",
    padding:"10px",
    marginBottom:"10px"
  }
});


class OpsOrdersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {

    };
  }

  update() { }



  getStoreNumber() {

    if (this.props.ops && this.props.ops.storeDetail && this.props.ops.storeDetail.storeNumber) {
      return this.props.ops.storeDetail.storeNumber;
    }
    return null;
  }

  componentDidMount() {
    this.props.updateTitle("Orders");
    this.props.getOrderDetails(this.getStoreNumber());
    console.log("Start poll");
    this.timer = setInterval(()=>this.props.getOrderDetails(this.getStoreNumber()),5000);

  }


  componentWillUnmount() {
    console.log("Stop poll");
    clearInterval(this.timer);
  }


  render() {
    const { classes } = this.props;
    const { NiceName, name, FirstLineAddress } = this.props.ops.storeDetail;
    const { orderDetails, orderDetailsLoading } = this.props.ops;


    return (
      <React.Fragment>
        {NiceName && <Box m={2}>
          <div className={classes.notificationBox}>
            <span> Today's orders for: </span>
            <b>
              <span> {NiceName} - {FirstLineAddress}</span>
            </b>
          </div>

        </Box>}


        {!orderDetailsLoading && orderDetails && orderDetails.Orders.length==0 && (
<Box m={3}>
  <div className={classes.borderedBox}>

<Typography align={"center"}>There have been no orders yet today</Typography>
</div>
</Box>

        )}


        {!orderDetailsLoading && orderDetails && orderDetails.Orders.map((order, idx) => (
          <OrderRow key={idx} order={order}  ></OrderRow>
        ))}


        {orderDetailsLoading && <Grid align={"center"} >
          <div className={classes.minHeight}>


            <CircularProgress />

          </div>
        </Grid>}

      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OpsOrdersComponent);
