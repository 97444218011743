import Creators from "./actions";
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";

const receivePromotionsAction = Creators.receivePromotions;
const requestPromotionsAction = Creators.requestPromotions;
const updatePromotionAction = Creators.updatePromotion;
const receivePromotionAction = Creators.receivePromotion;
const deletePromotionAction = Creators.deletePromotion;
const movePromotionAction = Creators.movePromotion;
const createPromotionAction = Creators.createPromotion;

const getCategoriesAction = Creators.getCategories;
const getGroupsAction = Creators.getGroups;
const getStoresAction = Creators.getStores;
const createGroupAction = Creators.createGroup;
const removeGroupAction = Creators.removeGroup;
const updateGroupAction = Creators.updateGroup;

const getPromotions = (id,callback) => {
	return (dispatch, getState) => {
		dispatch(requestPromotionsAction());

		new DuckApiCalls().getData("promotions/GetPromotions").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				if(id)
					dispatch(receivePromotionAction(payload, id));
				else
					dispatch(receivePromotionsAction(payload));
				callback && callback(true);					
			});
		}).catch(console.error);
	}
};

const updatePromotion = (promotion,callback) => {
	return (dispatch, getState) => {
		new DuckApiCalls().postData("promotions/UpdatePromotion", promotion).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				dispatch(updatePromotionAction(promotion));
				callback && callback(true);
			}).catch(console.error);
		})
	}
}

const createPromotion = (promotion,callback) => {
	return (dispatch, getState) => {
		new DuckApiCalls().postData("promotions/CreatePromotion", promotion).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
		
			return response.json().then(payload => {
				if(payload !== null)
				{
					promotion.PromotionId = payload;
					dispatch(createPromotionAction(promotion));
					callback && callback(true);
				}
			}).catch(console.error);
		})
	}
}

const deletePromotion = (id) => {
	return (dispatch, getState) => {
		new DuckApiCalls().deleteDataWithBody("promotions/DeletePromotion", { id }).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				if(payload)
					dispatch(deletePromotionAction(id));
			}).catch(console.error);
		})
	}
}

const movePromotion = (oldIndex, newIndex) => {
	return dispatch => {
		dispatch(movePromotionAction({oldIndex, newIndex}));
		
		new DuckApiCalls().postData("promotions/MovePromotion", { oldIndex, newIndex }).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().catch(console.error);
		})
	}
}

const getCategories = () => {
	return dispatch => {
		new DuckApiCalls().getData("menu/GetCategories").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				dispatch(getCategoriesAction(payload));
			}).catch(console.error);
		})
	}
}

const getGroups = () => {
	return dispatch => {
		new DuckApiCalls().getData("promotions/GetStoreGroups").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				dispatch(getGroupsAction(payload));
			}).catch(console.error);
		})
	}
}

const getStores = () => {
	return dispatch => {
		new DuckApiCalls().getData("promotions/GetStores").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				dispatch(getStoresAction(payload));
			}).catch(console.error);
		})
	}
}

const createGroup = (group) => {
	return dispatch => {
		new DuckApiCalls().postData("promotions/AddStoreGroup", group).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				if(payload !== -1)
				{
					group.StoreGroupId = payload;
					dispatch(createGroupAction(group));
				}
			}).catch(console.error);
		})
	}
}

const removeGroup = (group) => {
	return dispatch => {
		new DuckApiCalls().deleteDataWithBody("promotions/RemoveStoreGroup", group).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				if(payload)
					dispatch(removeGroupAction(group));
			}).catch(console.error);
		})
	}
}

const updateGroup = (group) => {
	return dispatch => {
		new DuckApiCalls().postData("promotions/UpdateStoreGroup", group).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				if(payload)
					dispatch(updateGroupAction(group));
			}).catch(console.error);
		})
	}
}

const enablePromotion = (id) => {
	return (dispatch, getState) => {
		var promotion = getState().promotions.promotions.filter(p => p.PromotionId === id)[0];

		promotion.Disabled = false;

		if(promotion)
			new DuckApiCalls().postData("promotions/UpdatePromotion", promotion).then(response => {
				if(response.status === 401)
					throw new UnauthorizedError(dispatch);

				return response.json().then(payload => {
					dispatch(updatePromotionAction(promotion));
				}).catch(console.error);
			})
	}
}

const disablePromotion = (id) => {
	return (dispatch, getState) => {
		var promotion = getState().promotions.promotions.filter(p => p.PromotionId === id)[0];

		promotion.Disabled = true;

		if(promotion)
			new DuckApiCalls().postData("promotions/UpdatePromotion", promotion).then(response => {
				if(response.status === 401)
					throw new UnauthorizedError(dispatch);

				return response.json().then(payload => {
					dispatch(updatePromotionAction(promotion));
				}).catch(console.error);
			})
	}
}

export default {
	getPromotions,
	updatePromotion,
	createPromotion,
	deletePromotion,
	movePromotion,
	getCategories,
	getGroups,
	getStores,
	createGroup,
	removeGroup,
	updateGroup,
	enablePromotion,
	disablePromotion
}