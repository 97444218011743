import types from './types';

const requestOrdersStatsActions = (data) => ({
    type: types.REQUEST_ORDERS_STATS,   
    data: data
});

const receiveOrdersStatsActions = (data) => ({
    type: types.RECEIVE_ORDERS_STATS,
    data: data
});

export default {
    requestOrdersStatsActions,
    receiveOrdersStatsActions,
}