import types from './types';

const requestItemsStatsActions = (data) => ({
    type: types.REQUEST_ITEMS_STATS,   
    data: data
});

const receiveItemsStatsActions = (data) => ({
    type: types.RECEIVE_ITEMS_STATS,
    data: data
});

export default {
    requestItemsStatsActions,
    receiveItemsStatsActions,
}