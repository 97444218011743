import types from "./types"

const INITIAL_STATE = {
	saving: false,
	accountSaved: false,
	changingPassword: false,
	changedPassword: false,
	passwordError: {}
}

const accountReducer = (state = INITIAL_STATE, action) => {
	switch(action.type) {
		case types.SAVING_USER:
			return {
				...state,
				saving: true
			}

		case types.EDIT_ACCOUNT:
			return {
				...state,
				saving: false,
				accountSaved: action.data
			}

		case types.CHANGING_PASSWORD:
			return {
				...state,
				changingPassword: true
			}

		case types.CHANGE_PASSWORD:
			return {
				...state,
				changingPassword: false,
				changedPassword: !action.data.error,
				passwordError: action.data
			}

		case types.RESET_SAVED:
			return {
				...state,
				accountSaved: false
			}

		default:
			return state;
	}
}

export default accountReducer;