import { connect } from 'react-redux'
import KitchenFlowStoresComponent from './KitchenFlowStoresComponent';
import { kitchenFlowOperations } from './duck';

const mapStateToProps = (state)  => {
	return {
		...state.kitchenFlows
	}
};

const mapDispatchToProps = (dispatch) => {
	const getStores = () => dispatch(kitchenFlowOperations.getStores());
	const getStoreZones = (storeId) => dispatch(kitchenFlowOperations.getStoreZones(storeId));

	return {
		getStores,
		getStoreZones
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(KitchenFlowStoresComponent)