import React from 'react';
import classNames from "classnames";

class WbGauge extends React.Component {
    render() {
        const { width, height, expanded, value, classes } = this.props;        
        if(value === undefined) {
            return(<svg width={width} height={height}><text textAnchor="middle" x="50%" y="50%" className={classes.wbSbText} fill="#FFF">--</text></svg>)
        }
        else {

            let highVal = false;
            let val = value;
            if(expanded) {
                val = val / 2;
                if(val < 50)
                    highVal = true;
            }      
            
            let valcol = classNames(classes.wbLowFillColour, classes.wbGaugeTransitions, highVal && classes.wbHighFillColour);
            
            return(<svg width={width} height={height}>
            {expanded ? (
                <>
                    <rect className={valcol} width={val + "%"} x="0" y="10%" height="80%"/> 
                    <rect x="0" y="10%" width="100%" height="80%" fill="transparent" style={{strokeWidth:1,stroke:"rgba(255,255,255,0.5)"}}/>
                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" style={{ fill:"white"}} className={classes.wbMidText}>{value}%</text>
                    <line x1="50%" y1="0" x2="50%" y2="100%" style={{ strokeWidth:2,stroke:"rgba(255,255,255,0.5)"}} strokeDasharray="5 2.5"/>
                </>):(
                <>
                    <rect className={valcol} width={val + "%"} x="0" y="0" height={height}/> 
                    <rect x="0" y="0" width="100%" height="100%" style={{fill:"transparent", strokeWidth:1,stroke:"rgba(255,255,255,0.5)"}}/>
                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" style={{ fill:"white"}} className={classes.wbMidText}>{value}%</text>
                </>
            )}
            </svg>);
        }

    }
}

export default WbGauge;