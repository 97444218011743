import Creators from "./actions";
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";

const requestTerminalStatusesAction = Creators.requestTerminalStatuses;
const receiveTerminalStatusesAction = Creators.receiveTerminalStatuses;

const getTerminalStatuses = (filterArg) => {
    return (dispatch, getState) => {
        dispatch(requestTerminalStatusesAction({
            loading: true
        }));
        new DuckApiCalls().getData("terminalstatus/" + (filterArg == null ? "bad" : filterArg))
        .then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
            return response.json()
            .then(payload => {
                dispatch(receiveTerminalStatusesAction({
                    terminals: payload,
                    loading: false
                }));
            });
        })
        .catch(console.error);
    };
};

export default {
    getTerminalStatuses
}