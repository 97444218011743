import React from "react"
import { connect } from "react-redux"
import { Grid, TextField, withStyles, Paper, Typography, Button, CircularProgress } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle"

const styles = theme => ({
	paper: {
		padding: theme.spacing() * 2,

		"&:first-child": {
			marginBottom: theme.spacing() * 4
		}
	},

	button: {
		marginTop: theme.spacing()
	},

	buttonWrapper: {
		textAlign: "center",
		marginTop: theme.spacing()
	}
});

class AccountComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentPassword: "",
			newPassword: "",
			confirmPassword: ""
		}
	}

	componentDidMount() {
		this.props.updateTitle("My Account");
	}
	
	onTextChange = (ev) => {
		const { user } = this.props.login;
		user[ev.target.name] = ev.target.value;

		//Reset saved state
		this.props.resetSaved();
	}

	onPasswordChange = (ev) => {
		var pass = {};
		pass[ev.target.name] = ev.target.value;

		this.setState(pass);
	}

	changePassword = (ev) => {
		ev.preventDefault();
		this.props.changePassword(this.state);

		//Reset password boxes since they're linked to their own values
		this.setState({
			currentPassword: "",
			newPassword: "",
			confirmPassword: ""
		});
	}

	saveChanges = (ev) => {
		ev.preventDefault();
		this.props.editAccount(this.props.login.user);
	}

	render() {
		const { classes, login, saving, changingPassword, changedPassword, accountSaved, passwordError } = this.props;

		return (
			<Grid container justify="center">
				<Grid item xs={12} md={10} lg={8}>
					<Paper className={classes.paper}>
						<Grid container component="form" onSubmit={this.saveChanges}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h6" gutterBottom>
									My Account
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									name="Email"
									label="Email Address"
									variant="outlined"
									placeholder="example@example.com"
									autoComplete="email"
									type="email"
									margin="normal"
									defaultValue={login.user.Email}
									onChange={this.onTextChange} />
							</Grid>

							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									name="Name"
									label="Name"
									variant="outlined"
									placeholder="John Doe"
									margin="normal"
									defaultValue={login.user.Name}
									onChange={this.onTextChange} />
							</Grid>

							<Grid item xs={12} className={classes.buttonWrapper}>
								{saving ? <CircularProgress /> : accountSaved ? <CheckCircleIcon color="primary" fontSize="large" /> : 
								<Button
									type="submit"
									className={classes.button}
									fullWidth
									color="primary"
									variant="contained">Save Changes</Button>}
							</Grid>
						</Grid>
					</Paper>

					<Paper className={classes.paper}>
						<Grid component="form" container onSubmit={this.changePassword}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h6" gutterBottom>
									Change Password
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									name="currentPassword"
									label="Current password"
									variant="outlined"
									autoComplete="current-password"
									type="password"
									margin="normal"
									value={this.state.currentPassword}
									onChange={this.onPasswordChange}
									error={passwordError.error && passwordError.cause === "password"}
									helperText={passwordError.error && passwordError.cause === "password" && "Current password does not match"}/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									name="newPassword"
									label="New Password"
									variant="outlined"
									autoComplete="new-password"
									type="password"
									margin="normal"
									value={this.state.newPassword}
									onChange={this.onPasswordChange}
									error={passwordError.error && passwordError.cause === "nomatch"}
									helperText={passwordError.error && passwordError.cause === "nomatch" && "Please make sure both passwords match"}/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									name="confirmPassword"
									label="Confirm Password"
									variant="outlined"
									autoComplete="new-password"
									type="password"
									margin="normal"
									value={this.state.confirmPassword}
									onChange={this.onPasswordChange}
									error={passwordError.error && passwordError.cause === "nomatch"}
									helperText={passwordError.error && passwordError.cause === "nomatch" && "Please make sure both passwords match"}/>
							</Grid>

							<Grid item xs={12} className={classes.buttonWrapper}>
								{changingPassword ? <CircularProgress /> : changedPassword ? <CheckCircleIcon color="primary" fontSize="large" /> :
								<Button
									type="submit"
									className={classes.button}
									fullWidth
									color="primary"
									variant="contained">Change Password</Button>}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		)
	}

}

export default connect(state => state)(withStyles(styles)(AccountComponent));