import { connect } from 'react-redux'
import UsersComponent from './UsersComponent'
import { usersOperations } from './duck'
import { dashboardOperations } from '../../duck'

const mapStateToProps = (state) => {
    return {
		...state.users,
		login: state.login
    }
};

const mapDispatchToProps = (dispatch) => {
    const getUsers = () => dispatch(usersOperations.getUsers());
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
    const deleteUser = (user) => dispatch(usersOperations.deleteUser(user));
    const toggleSuspendUser = (user) => dispatch(usersOperations.toggleSuspendUser(user));
    const editUser = (user) => dispatch(usersOperations.editUser(user));
    const massSuspendUsers = (users) => dispatch(usersOperations.massSuspendUsers(users));
    const massUnsuspendUsers = (users) => dispatch(usersOperations.massSuspendUsers(users, false));
	const massDeleteUsers = (users) => dispatch(usersOperations.massDeleteUsers(users));
	const getRoles = () => dispatch(usersOperations.getRoles());
    const newUser = (user) => dispatch(usersOperations.newUser(user));
    const createOpsUsers = (user) => dispatch(usersOperations.createOpsUsers());


	const resetErrors = () => dispatch(usersOperations.resetErrors());

    return {
        getUsers,
        updateTitle,
        deleteUser,
        toggleSuspendUser,
        editUser,
        massSuspendUsers,
        massUnsuspendUsers,
		massDeleteUsers,
		getRoles,
		newUser,
        resetErrors,
        createOpsUsers
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent)