import Creators from "./actions";
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";

const receiveUsersAction = Creators.receiveUsers;
const requestUsersAction = Creators.requestUsers;
const deleteUserAction = Creators.deleteUser;
const editUserAction = Creators.editUser;
const massDeleteUsersAction = Creators.massDeleteUsers;
const getStoresAction = Creators.getStores;
const addUserAction = Creators.addUser;
const resetPasswordAction = Creators.resetPassword;
const passwordResetAction = Creators.passwordReset;

const getUsers = () => {
    return (dispatch, getState) => {
        dispatch(requestUsersAction());

        new DuckApiCalls().getData("CashierUsers/getUsers").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(payload => {
                dispatch(receiveUsersAction({
                    users: payload,
                    loading: false
                }));
            });
        }).catch(console.error);
    };
};

const deleteUser = (user) => {
    return (dispatch, getState) => {
        new DuckApiCalls().deleteDataWithBody("CashierUsers/deleteUser", { userId: user.CashierUserId }).then((response) => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(function (payload) {
                if(payload)
                    dispatch(deleteUserAction(user));
            });
        }).catch(console.error);
    }
};

const editUser = (user) => {
    return (dispatch, getState) => {
        new DuckApiCalls().putData("CashierUsers/EditUser", user).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(payload => {
                if(payload)
                    dispatch(editUserAction(user));
            });
        }).catch(console.error);
    }
}

const massDeleteUsers = (users) => {
    return (dispatch, getState) => {
        new DuckApiCalls().deleteDataWithBody("CashierUsers/deleteUsers", users).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(payload => {
                if(payload)
                    dispatch(massDeleteUsersAction(users));
            });
        }).catch(console.error);
    }
}

const getStores = () => {
	return dispatch => {
		new DuckApiCalls().getData("CashierUsers/GetStores").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(payload => {
                dispatch(getStoresAction(payload));
            });
        }).catch(console.error);
	}
}

const addUser = user => {
	return dispatch => {
		new DuckApiCalls().postData("CashierUsers/AddUser", user).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(payload => {
				dispatch(addUserAction(payload));
            });
        }).catch(console.error);
	}
}

const resetPassword = (user, newPassword, confirmPassword) => {
	return dispatch => {
		dispatch(resetPasswordAction());

		new DuckApiCalls().postData("CashierUsers/ResetPassword", { user, newPassword, confirmPassword }).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(payload => {
				dispatch(passwordResetAction(payload));
            });
        }).catch(console.error);
	}
}

export default {
    getUsers,
    deleteUser,
    editUser,
	massDeleteUsers,
	getStores,
	addUser,
	resetPassword
};
