import { connect } from 'react-redux';
import { dashboardOperations } from "../../duck"
import DashboardComponent from './DashboardComponent';

const mapStateToProps = (state) => {
	return {
		...state.dashboard
	}
}

const mapDispatchToProps = (dispatch) => {
	const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
	const getKPIs = () => dispatch(dashboardOperations.getKPIs())
	const getSummaries = () => dispatch(dashboardOperations.getSummaries())

	return {
		updateTitle,
		getKPIs, 
		getSummaries
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent)