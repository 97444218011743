import types from './types';

const requestBasketsStatsActions = (data) => ({
    type: types.REQUEST_BASKETS_STATS,   
    data: data
});

const receiveBasketsStatsActions = (data) => ({
    type: types.RECEIVE_BASKETS_STATS,
    data: data
});

export default {
    requestBasketsStatsActions,
    receiveBasketsStatsActions,
}