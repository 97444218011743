import types from './types'

const requestUsers = () => ({
    type: types.REQUEST_USERS
})

const receiveUsers = (data) => ({
    type: types.RECEIVE_USERS,
    data
});

const deleteUser = (data) => ({
    type: types.DELETE_USER,
    data
});

const editUser = (data) => ({
    type: types.EDIT_USER,
    data
})

const massDeleteUsers = (data) => ({
    type: types.MASS_DELETE_USERS,
    data
})

const getStores = data => ({
	type: types.GET_STORES,
	data
})

const addUser = data => ({
	type: types.ADD_USER,
	data
})

const resetPassword = () => ({
	type: types.RESET_PASSWORD
})

const passwordReset = (success) => ({
	type: types.PASSWORD_RESET,
	success
})

export default {
    requestUsers,
    receiveUsers,
    deleteUser,
    editUser,
	massDeleteUsers,
	getStores,
	addUser,
	resetPassword,
	passwordReset
};