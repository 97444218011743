// actions.js
import types from './types.js';

const requestMenusJson = (returnUrl) => ({
    type: types.REQUEST_MENUS_JSON
});

const receiveMenusJson = (data) => ({
    type: types.RECEIVE_MENUS_JSON,
    data: data
});

export default {
    requestMenusJson,
    receiveMenusJson
}