import React from 'react';

class WbBorder extends React.Component {
    render() {
        const { classes, heading, children } = this.props;
        return(<div className={classes.wbBorder}>           
            <div className="header">{heading}</div>            
            <div className="content">{children}</div>
        </div>)
    }
}

export default WbBorder;