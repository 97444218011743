// types.js
const REQUEST_PRODUCTS = "REQUEST_PRODUCTS";
const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
const REQUEST_CLASSIFICATIONS = "REQUEST_CLASSIFICATIONS";
const RECEIVE_CLASSIFICATIONS = "RECEIVE_CLASSIFICATIONS";
const UPDATE_PRODUCT_CLASSIFICATION = "UPDATE_PRODUCT_CLASSIFICATION";
const REQUEST_STORES = "REQUEST_STORES";
const RECEIVE_STORES = "RECEIVE_STORES";
const REQUEST_STORE_ZONES = "REQUEST_STORE_ZONES";
const RECEIVE_STORE_ZONES = "RECEIVE_STORE_ZONES";
const REQUEST_CHANGE_ROWS_PER_PAGE = "REQUEST_CHANGE_ROWS_PER_PAGE";
const REQUEST_CHANGE_PAGE = "REQUEST_CHANGE_PAGE";
const REQUEST_PRODUCT_SEARCH = "REQUEST_PRODUCT_SEARCH";
const REQUEST_CHANGE_SORT_BY = "REQUEST_CHANGE_SORT_BY";
const REQUEST_CHANGE_TAB = "REQUEST_CHANGE_TAB";

export default {
	REQUEST_PRODUCTS,
	RECEIVE_PRODUCTS,
	REQUEST_CLASSIFICATIONS,
	RECEIVE_CLASSIFICATIONS,
	UPDATE_PRODUCT_CLASSIFICATION,
	REQUEST_STORES,
	RECEIVE_STORES,
	REQUEST_STORE_ZONES,
	RECEIVE_STORE_ZONES,
	REQUEST_CHANGE_ROWS_PER_PAGE,
	REQUEST_CHANGE_PAGE,
	REQUEST_PRODUCT_SEARCH,
	REQUEST_CHANGE_SORT_BY,
	REQUEST_CHANGE_TAB
}