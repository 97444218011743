import types from './types';

const INITIAL_STATE = {
    kioskmonitoring: [],

    kioskmonitoringLoading: false,
};

const kioskMonitoringReportReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.RECEIVE_KIOSK_MONITORING_ACTIONS: {
            return {
                ...state,
                kioskmonitoring: action.data.kioskmonitoring,
                kioskmonitoringLoading: action.data.kioskmonitoringLoading
            };
        }
        case types.REQUEST_KIOSK_MONITORING_ACTIONS: {
            return {
                ...state,
                kioskmonitoringLoading: action.data.kioskmonitoringLoading
            };
        }
     
        default:
            return state;
    }
}

export default kioskMonitoringReportReducer;