import React from 'react';
import WbHelpers from './WbHelpers';
import classNames from "classnames";

const WbSimpleTableItem = (props) => {
    const { value, classes  } = props;
    return(<div className={classNames(classes.wbRow,classes.wbStText)}>
           {value === undefined ? (null):(
           <>
                <div className={classNames('wb-Column-75',classes.wbTextEllipses)}                 
                    style={{textAlign:"left",padding:"10px", maxWidth: "300px"}}>
                    <div className={classNames(classes.wbTextEllipses)}>{value.caption}</div>
                </div>
                <div className={'wb-Column-25'} style={{textAlign:"right",padding:"10px"}}>{WbHelpers.formatNumber(value.value)}</div>
            </>)}
    </div>)
}

class WbSimpleTable extends React.Component {
    itemLimit = 18; // limit of items
    render() {
        const { values, reverseorder, width, classes } = this.props;
        let isPopulated = !(values === undefined || values.length === 0);         
        let vals = values.sort((a,b) => { return a.value - b.value; })
        if(!reverseorder)
            vals.reverse();
        vals = vals.slice(0,this.itemLimit);

        if(isPopulated === true) {
            return(<div style={{ maxWidth: width }}>
                <div className={classes.wbRow}>
                    <div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[0]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[6]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[12]}/></div>
                </div>
                <div className={classes.wbRow}>
                    <div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[1]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[7]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[13]}/></div>
                </div>
                <div className={classes.wbRow}>
                    <div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[2]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[8]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[14]}/></div>
                </div>
                <div className={classes.wbRow}>
                    <div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[3]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[9]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[15]}/></div>
                </div>
                <div className={classes.wbRow}>
                    <div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[4]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[10]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[16]}/></div>
                </div>
                <div className={classes.wbRow}>
                    <div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[5]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[11]}/></div><div className={'wb-Column'}><WbSimpleTableItem classes={classes} value={vals[17]}/></div>
                </div>
            </div>);
        }
        else {
            return(
                <div style={{ maxWidth: width, height:"300px", fontSize:"1.75rem", fontWeight: "bold", lineHeight: "300px" }}>
                    --
                </div>);
        }
    }
}

export default WbSimpleTable;