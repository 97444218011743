// reducers.js
import types from "./types";

const INITIAL_STATE = {
    products: [],
    totalResults: 0,
    classifications: [],
    stores: [],
    zones:[],
    initialLoad: true,
    loading: true,
    sortColumn: "SKU",
    sortDirection: "asc",
    rowsPerPage: 25,
    page: 0,
    selectedTab: 0,
    searchPhrase: "",
    selectStoreId: 0
};

const kitchenFlowReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.REQUEST_PRODUCTS: {
            return {
                ...state,
                loading: true
            };
        }

        case types.RECEIVE_PRODUCTS: {
            return {
                ...state,
                products: action.data.products,
                totalResults: action.data.totalResults,
                loading: action.data.loading === 0,
                initialLoad: false
            };
        }

        case types.RECEIVE_CLASSIFICATIONS: {
            return {
                ...state,
                classifications: action.data.classifications,
                loading: state.products.length === 0
            };
        }

        case types.UPDATE_PRODUCT_CLASSIFICATION: {
            return {
                ...state,
                // Return the new object if the Id's match
                products: state.products.map(product => product.SKU === action.data.SKU ? action.data : product)
            };
        }

        case types.REQUEST_STORES: {
            return {
                ...state,
                loading: true
            };
        }

        case types.RECEIVE_STORES: {
            return {
                ...state,
                stores: action.data.stores,
                loading: state.stores.length === 0
            };
        }

        case types.REQUEST_STORE_ZONES: {
            return {
                ...state,
                loading: true,
                selectedStoreId: action.data
            };
        }

        case types.RECEIVE_STORE_ZONES: {
            return {
                ...state,
                zones: action.data.zones,
                loading: state.zones.length === 0
            };
        }

        case types.REQUEST_CHANGE_ROWS_PER_PAGE: {
            return {
                ...state,
                loading: true,
                rowsPerPage: action.data,
                page: 0
            };
        }

        case types.REQUEST_CHANGE_PAGE: {
            return {
                ...state,
                loading: true,
                page: action.data
            };
        }

        case types.REQUEST_PRODUCT_SEARCH: {
            return {
                ...state,
                loading: true,
                page: 0,
                searchPhrase: action.data
            };
        }

        case types.REQUEST_CHANGE_SORT_BY: {
            const { sortColumn, sortDirection } = state;

            let newSortBy = action.data.col;
            let newSortDirection = sortColumn === (newSortBy) && sortDirection === "asc" ? "desc" : "asc"

            return {
                ...state,
                loading: true,
                page: 0,
                sortColumn: newSortBy,
                sortDirection: newSortDirection
            };
        }

        case types.REQUEST_CHANGE_TAB:
            return {
                ...state,
                selectedTab: action.data
            };

        default:
            return state;
    }
};

export default kitchenFlowReducer;
