const STORE_DATA_RECEIVED = "STORE_DATA_RECEIVED";
const MENU_DATA_RECEIVED = "MENU_DATA_RECEIVED";
const MENU_DATA_LOADING = "MENU_DATA_LOADING";
const NEW_MOP_STATE_REQUESTED = "NEW_MOP_STATE_REQUESTED";
const SET_SKU_STOCK = "SET_SKU_STOCK";
const SET_SKU_RANGE = "SET_SKU_RANGE";
const ORDER_DETAILS_RECEIVED = "ORDER_DETAILS_RECEIVED";
const ORDER_DETAILS_LOADING = "ORDER_DETAILS_LOADING";
const SET_RANGE_MODE = "SET_RANGE_MODE";
const SET_STOCK_MODE = "SET_STOCK_MODE";

export default {
    STORE_DATA_RECEIVED,
    NEW_MOP_STATE_REQUESTED,
    MENU_DATA_RECEIVED,
    MENU_DATA_LOADING,
    SET_SKU_STOCK,
    SET_SKU_RANGE,
    ORDER_DETAILS_RECEIVED,
    ORDER_DETAILS_LOADING,
    SET_RANGE_MODE,
    SET_STOCK_MODE
}