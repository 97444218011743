import React from "react"
import { Toolbar, withStyles, Grid, Table, TableCell, TableBody, TableRow, TableHead, TableSortLabel, TextField, IconButton, Button, CircularProgress, Link, Switch, FormControlLabel, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import classNames from 'classnames';
import SearchIcon from '@material-ui/icons/Search'
import moment from "moment";
import 'moment-timezone';
import DragIndicator from "@material-ui/icons/DragIndicator"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { connect } from 'react-redux';
import { promotionOperations } from "./duck"
import { dashboardOperations } from '../../duck'

import PageTitle from '../../PageTitle';

const promotionColumns = [
	{ col: "Name", sort: true },
	{ col: "Stores", sort: true, dataCol: "InnerPromotion.Stores" },
	{ col: "Start date", sort: true, dataCol: "InnerPromotion.Schedule.StartDate" },
	{ col: "End date", sort: true , dataCol: "InnerPromotion.Schedule.EndDate" },
	""
];

const styles = theme => ({
	title: {
        fontSize: "20px",
        color: "#999999"
	},
    loading: {
        textAlign: "center",
        padding: theme.spacing() * 2
    },
    search: {
        maxWidth: "0",
        transition: "max-width 0.5s ease"
    },
    searchSlide: {
        maxWidth: "200px"
	},
	link: {
		color: "#000",
		verticalAlign: "middle"
	},
	row: {
		color: "inherit",
		height: "48px",
		display: "table-row",
		outline: "none",
		verticalAlign: "middle"
	},
	rowDrag: {
		display: "flex",
		lineHeight: "48px",
		"& td": {
			flex: "1"
		}
	},
	handle: {
		verticalAlign: "middle"
	},
	disabled: {
		opacity: 0.5
	}
})

//Map object path to a useable value for the sorting
function pathValue(obj, path) {
	var pathParts = path.split(".");

	if(pathParts.length === 1)
		return obj[path];

	var val = pathParts.reduce((prev, curr) => {
		if(prev && prev[curr])
			return prev[curr];
		else
			return null;
	}, obj);

	return val;
}

//Move everything in to one place for sorting, paging and filtering the users
function sortPageFilter(users, state) {
    const { sortColumn, sortDirection, searchPhrase } = state;

    return users
    .map((user, index) => [user, index])//Retain index
    .sort((a, b) => {
		var firstValue = pathValue(a[0], sortColumn);
		var secondValue = pathValue(b[0], sortColumn);

		var order = (firstValue > secondValue ? 1 : -1);
		
		if(moment(firstValue).isValid() && !moment(secondValue).isValid())
			order = -1;

		if(moment(firstValue).isValid() && !moment(secondValue).isValid())
			order = 1;

		if(moment(firstValue).isValid() && moment(secondValue).isValid())
			order = moment.utc(firstValue).diff(moment.utc(secondValue));

		if(order !== 0)
			return order * (sortDirection === "desc" ? -1 : 1);

        //Use index instead
        return a[1] - b[1];
    })
    .map(a => a[0])//Map back
    .filter(user => {
        for(var k in user)
            if(user[k].toString().toLowerCase().indexOf(searchPhrase.toLowerCase()) !== -1)
                return true;
        return false;
    });
}

function sortByExecutionOrder(list) {
	return list.sort((a, b) => {
		return a.Order - b.Order;
	});
}

const Promotion = (props) => {
	const { classes, promotion, executionMode, deletePromotion, enablePromotion, disablePromotion } = props;

	return <React.Fragment>
		<TableCell>
			{executionMode && <DragIndicator className={classes.handle}></DragIndicator>}
			<NavLink to={"/promotions/" + promotion.PromotionId} className={classes.link}>
				{promotion.Name}
			</NavLink>
		</TableCell>
				
		<TableCell>
			{promotion.InnerPromotion.Stores == null ? "All" : (promotion.InnerPromotion.Stores.StoreIds ? promotion.InnerPromotion.Stores.StoreIds.length + " stores" : (promotion.InnerPromotion.Stores.Regions?promotion.InnerPromotion.Stores.Regions.length + " regions":"Error"))}
		</TableCell>

		<TableCell>
			{promotion.InnerPromotion.Schedule && promotion.InnerPromotion.Schedule.StartDate ? moment(promotion.InnerPromotion.Schedule.StartDate).format("DD/MM/YYYY") : "None"}
		</TableCell>

		<TableCell>
			{promotion.InnerPromotion.Schedule && promotion.InnerPromotion.Schedule.EndDate ? moment(promotion.InnerPromotion.Schedule.EndDate).format("DD/MM/YYYY") : "None"}
		</TableCell>

		<TableCell>
			<Grid container spacing={8}>
				<Grid item>
					<NavLink to={"/promotions/" + promotion.PromotionId} className={classes.link}>
						Edit
					</NavLink>
				</Grid>

				{promotion.Disabled ? <Grid item>
					<Link href="#" onClick={() => enablePromotion(promotion.PromotionId)} className={classes.link} underline="always">
						Enable
					</Link>
				</Grid> : <Grid item>
					<Link href="#" onClick={() => disablePromotion(promotion.PromotionId)} className={classes.link} underline="always">
						Disable
					</Link>
				</Grid>}

				<Grid item>
					<Link href="#" onClick={() => deletePromotion(promotion.PromotionId)} className={classes.link} underline="always">
						Delete
					</Link>
				</Grid>
			</Grid>
		</TableCell>
	</React.Fragment>
};

const DeleteDialog = (props) => {
    const { open, onClose, onAccept } = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Are you sure you want to delete this promotion?</DialogTitle>

            <DialogActions>
                <Button onClick={onAccept}>Yes</Button>
                <Button onClick={onClose}>No</Button>
            </DialogActions>
        </Dialog>
    )
}

class PromotionsComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showSearch: false,
            sortColumn: "Name",
			sortDirection: "desc",
			searchPhrase: "",
			executionMode: false,
			showDeleteDialog: false,
			onDeleteDialogAccept: null
		};
	}

	componentDidMount() {
		// [MR-96] May move this to somewhere more central 
		// so the time zones applies to more areas 
		// e.g. users last login
		moment.tz.setDefault("Europe/London");
		this.props.updateTitle("Cafe promotions");
		this.props.getPromotions();
	}

    //Sorting
    onSort = (column) => {
        const { sortColumn, sortDirection } = this.state;

        this.setState({
            sortColumn: column.dataCol || column.col,
            //Only change the direction of the column if it's the current sorting col
            sortDirection: sortColumn === (column.dataCol || column.col) && sortDirection === "desc" ? "asc" : "desc"
        });
    }

    onSearchClick = () => {
        this.setState({ showSearch: !this.state.showSearch });
	}
	
	delete(id) {
		this.setState({ showDeleteDialog: true, onDeleteDialogAccept: () => {
			this.props.deletePromotion(id);
		}});
	}

	onDragEnd(item) {
		var promotion = this.props.promotions.filter(p => p.PromotionId === parseInt(item.draggableId))[0];

		if(promotion && item.source.index !== item.destination.index)
			this.props.movePromotion(item.source.index, item.destination.index);
	}

	render() {
		const { classes, promotions, loading } = this.props;
		const { showSearch, sortDirection, sortColumn, executionMode, showDeleteDialog, onDeleteDialogAccept } = this.state;






		return (
			<React.Fragment>
				<Toolbar variant="dense">
					<Grid container justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={6} md="auto">
							<PageTitle/>
						</Grid>

						<Grid item xs={6} md="auto">
							<NavLink to="/promotions/new" style={{textDecoration: "none"}}>
								<Button variant="contained" color="primary">Create new promotion</Button>
							</NavLink>
						</Grid>

						<Grid item xs={6} md="auto">
							<Link href="/api/Promotions/ExportCSV" download style={{textDecoration: "none"}}>
								<Button variant="contained" color="primary">Export CSV</Button>
							</Link>
						</Grid>

						<Grid item xs={12} md={true}>
                            <Grid container alignItems="baseline" justify="flex-end">
								<Grid item xs="auto">
									<FormControlLabel label="Edit execution order" control={<Switch color="primary" onClick={() => this.setState({ executionMode: !executionMode })}></Switch>}/>
								</Grid>

                                <Grid item xs="auto">
                                    <IconButton onClick={this.onSearchClick}>
                                        <SearchIcon/>
                                    </IconButton>
                                </Grid>

                                <Grid item xs className={classNames(classes.search, showSearch && classes.searchSlide)}>
                                    <TextField type="search" onChange={(ev) => { this.setState({ searchPhrase: ev.target.value }) }}></TextField>
                                </Grid>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
				
				<DragDropContext onDragEnd={(item) => this.onDragEnd(item)}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								<Table>
									<TableHead>
										<TableRow>
											{promotionColumns.map(column => (
												<TableCell key={column.col || column}>
													<TableSortLabel
														active={sortColumn === (column.dataCol || column.col) && column.sort && !executionMode}
														direction={sortDirection}
														onClick={() => column.sort && this.onSort(column)}>
														{column.title || column.col || column}
													</TableSortLabel>
												</TableCell>
											))}
										</TableRow>
									</TableHead>

									<TableBody>
										{loading ? (
											<TableRow>
												<TableCell className={classes.loading} colSpan={promotionColumns.length+2}>
													<CircularProgress></CircularProgress>
												</TableCell>
											</TableRow>
										) : executionMode ?
											sortByExecutionOrder(promotions).map((promotion, index) => (
												<Draggable key={promotion.PromotionId} draggableId={`${promotion.PromotionId}`} index={index}>
													{(provided, snapshot) => (
														<tr className={snapshot.isDragging ? classes.rowDrag: classes.row} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
															<Promotion promotion={promotion} classes={classes} enablePromotion={this.props.enablePromotion} disablePromotion={this.props.disablePromotion} deletePromotion={(id) => this.delete(id)} executionMode></Promotion>
														</tr>
													)}
												</Draggable>
											)) : sortPageFilter(promotions, this.state).map(promotion => (
												<TableRow className={promotion.Disabled ? classes.disabled : ""} key={promotion.PromotionId}>
													<Promotion promotion={promotion} classes={classes} enablePromotion={this.props.enablePromotion} disablePromotion={this.props.disablePromotion} deletePromotion={(id) => this.delete(id)}></Promotion>
												</TableRow>
											))
										}
									</TableBody>
								</Table>
							</div>
						)}
					</Droppable>
				</DragDropContext>

				<DeleteDialog
					open={showDeleteDialog}
					onAccept={() => { onDeleteDialogAccept(); this.setState({ showDeleteDialog: false }) }}
					onClose={() => this.setState({ showDeleteDialog: false })}
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		...state.promotions
	}
}

const mapDispatchToProps = (dispatch) => {
	const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
	const getPromotions = () => dispatch(promotionOperations.getPromotions());
	const deletePromotion = (id) => dispatch(promotionOperations.deletePromotion(id));
	const movePromotion = (oldIndex, newIndex) => dispatch(promotionOperations.movePromotion(oldIndex, newIndex));
	const enablePromotion = (id) => dispatch(promotionOperations.enablePromotion(id));
	const disablePromotion = (id) => dispatch(promotionOperations.disablePromotion(id));

	return {
		updateTitle,
		getPromotions,
		deletePromotion,
		movePromotion,
		enablePromotion,
		disablePromotion
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PromotionsComponent));