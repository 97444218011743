import types from "./types"

const INITIAL_STATE = {
	loading: true,
	promotions: [],
	promotion: null,
	categories: [],
	groups: [],
	stores: []
}

const promotionReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.REQUEST_PROMOTIONS: {
            return {
				...state,
				promotion: null,
				promotions: [],
                loading: true
            }
        }

        case types.RECEIVE_PROMOTIONS: {
            return {
                ...state,
				promotions: action.data,
				promotion: null,
				loading: false
            }
		}

		case types.RECEIVE_PROMOTION: {
			var promotion = action.data.filter(function(p) {
				return p.PromotionId === parseInt(action.id);
			});
			
			return {
				...state,
				promotion: promotion.length > 0 ? promotion[0] : null,
				promotions: action.data,
				loading: false
			}
		}

		case types.UPDATE_PROMOTION: {
			return {
				...state,
				promotion: action.data
			}
		}

		case types.CREATE_PROMOTION: {
			let promotions = state.promotions.slice();
			promotions.push(action.data);

			return {
				...state,
				promotions
			}
		}

		case types.DELETE_PROMOTION: {
            //Remove the promotion
			let promotions = state.promotions.slice();
			
			let promotion = promotions.find(p => p.PromotionId === action.data);
			promotions.splice(state.promotions.indexOf(promotion), 1);

			return {
				...state,
				promotions: promotions
			}
		}

		case types.MOVE_PROMOTION: {
			//Get promotions and sort them by order
			var promotions = state.promotions.slice();
			promotions.sort((a, b) => {
				return a.Order - b.Order;
			});

			//Remove the move promotion and add it back in at the new index
			var movedPromotion = promotions.splice(action.data.oldIndex, 1);
			promotions.splice(action.data.newIndex, 0, movedPromotion[0]);

			//Set each promotions order to the index of itself
			promotions.forEach((p, index) => {
				p.Order = index;
			});

			return {
				...state,
				promotions: promotions
			}
		}

		case types.GET_CATEGORIES: {
			return {
				...state,
				categories: action.data
			}
		}

		case types.GET_GROUPS: {
			return {
				...state,
				groups: action.data
			}
		}

		case types.GET_STORES: {
			return {
				...state,
				stores: action.data
			}
		}

		case types.CREATE_GROUP: {

			var groups = state.groups.slice();
			groups.push(action.data);

			return {
				...state,
				groups
			}
		}

		case types.REMOVE_GROUP: {
			//Remove the group
			let groups = state.groups.slice();
			groups.splice(state.groups.indexOf(action.data), 1);

			return {
				...state,
				groups
			}
		}

		case types.UPDATE_GROUP: {
			return {
				...state,
				groups: state.groups.map(g => g.StateGroupId === action.data.StoreGroupId ? action.data : g)
			}
		}
		
		default:
			return state;
    }
}

export default promotionReducer;