import types from './types'

const requestUsers = () => ({
    type: types.REQUEST_USERS
})

const receiveUsers = (data) => ({
    type: types.RECEIVE_USERS,
    data: data
});

const deleteUser = (data) => ({
    type: types.DELETE_USER,
    data: data
});

const suspendUser = (data) => ({
    type: types.SUSPEND_USER,
    data: data
});

const unsuspendUser = (data) => ({
    type: types.UNSUSPEND_USER,
    data: data
})

const editUser = (data) => ({
    type: types.EDIT_USER,
    data: data
})

const massSuspendUsers = (data) => ({
    type: types.MASS_SUSPEND_USERS,
    data: data
})

const massDeleteUsers = (data) => ({
    type: types.MASS_DELETE_USERS,
    data: data
})

const getRoles = (data) => ({
	type: types.GET_ROLES,
	data: data
})

const newUser = data => ({
	type: types.NEW_USER,
	data
})

const creatingOpsUsers = () => ({
	type: types.CREATING_OPS
})

const createdOpsUsers = () => ({
	type: types.CREATED_OPS
})


export default {
    requestUsers,
    receiveUsers,
    deleteUser,
    suspendUser,
    unsuspendUser,
    editUser,
    massSuspendUsers,
	massDeleteUsers,
	getRoles,
    newUser,
    creatingOpsUsers,
    createdOpsUsers
}