import React from 'react';
import { Grid, Paper, withStyles, Typography, Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import WbGauge  from '../nativeReportsPage/WallboardReport/WbGauge';
import ReportHelpers from '../nativeReportsPage/ReportHelpers';

import AmTouchscreensInUse from './AmTouchscreensInUse';
import AmTouchscreensOutOfAction from './AmTouchscreensOutOfAction';

const MorrisonsAgendaBold = "MorrisonsAgenda Bold";
const colour03 = "rgba(237,28,36,1)";
const colour05 = "rgba(0,137,59,1)";
const locolor = colour05;
const hicolor = colour03;

const styles = theme => ({
	sales: {
		backgroundColor: theme.palette.primary.main,
		padding: theme.spacing() * 2
	},
	paper: {
		padding: theme.spacing() * 2,
		height: "100%",
		overflow: "hidden"
	},
	reportsPaper: {
		padding: `${theme.spacing() * 5}px ${theme.spacing() * 4}px`,
		height: "100%",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& h2": {
			fontSize: "34px",
			lineHeight: "34px",
			flex: "0",
			"& + h3": {
				marginTop: "30px"
			}
		},
		"& h3": {
			fontSize: "16px",
			flex: "0",
		}
	},
	
	reportsPaperWithSummaries: {
		padding: `${theme.spacing() * 5}px ${theme.spacing() * 4}px`,
		height: "100%",
		textAlign: "left",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& h2": {
			fontSize: "34px",
			lineHeight: "34px",
			flex: "0",
			"& + h3": {
				marginTop: "30px"
			}
		},
		"& h3": {
			fontSize: "16px",
			flex: "0",
		}
	},
	hideIt: {
		display: "none"
	},
	reportsWrapper: {
		padding: `${theme.spacing() * 1}px ${theme.spacing() * 1}px`,
	},
	salesWrapper: {
		padding: `${theme.spacing() * 2}px ${theme.spacing() * 4}px`,
		color: theme.palette.common.white,

		"& h2": {
			fontSize: 24,
			lineHeight: "24px",
			fontWeight: 300
		}
	},
	gridFullHeight: {
		height: "100%"
	},
	title: {
		marginBottom: theme.spacing() * 2
	},
	wbLowFillColour: {
		fill: locolor,
		color: locolor
	},
	wbHighFillColour: {
		fill: hicolor,
		color: hicolor, 		
	},
	wbSbText: {
		fontWeight: "bold",
		fontSize: "1.75rem",
		textTransform: "uppercase",
		fontFamily: MorrisonsAgendaBold
	},
	wbGaugeTransitions: {
		transition: "width 0.25s ease, fill 0.25s" 
	},
	gaugeContent: {
		textAlign: "center",		
	},
	gaugeTitle: {

		color: "#fff",
		marginBottom: `${theme.spacing() * 1}px`,

	},
	textEllipsis: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	}
});

const BasketsOverall = (props) => {
    const { classes, items } = props;	
	if(items.length === 0) {
		return (<Grid item xs={12}><Typography variant="body2">Nothing today.</Typography></Grid>);
	}
	else {
		return(items.map((item, index) => {
			return (
				<>
				<Grid key={item.StoreName} item xs={9}><Typography variant="body2" className={classes.textEllipsis}>{item.StoreName}</Typography></Grid>
				<Grid item xs={3}><Typography variant="body2" align="right">{ReportHelpers.formatNumber(item.Total)}</Typography></Grid>
				</>
			)}));
	}	
}

const TopSellingItemsOverall = (props) => {
    const { classes, items } = props;	
	if(items.length === 0) {
		return (<Grid item xs={12}><Typography variant="body2">Nothing today.</Typography></Grid>);
	}
	else {
		return(
		items.map((item, index) => {
			return (
				<>
				<Grid key={item.StoreName} item xs={9}><Typography variant="body2" className={classes.textEllipsis}>{item.MenuItemName}</Typography></Grid>
				<Grid item xs={3}><Typography variant="body2" align="right">{ReportHelpers.formatNumber(item.Total)}</Typography></Grid>
				</>
				)}));	
	}	
}



class DashboardComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = { }
	}

	update() {
		this.props.getKPIs()
		this.props.getSummaries();
	}

	componentDidMount() {
		this.props.updateTitle("Dashboard");
		setInterval(this.update(), 1000 * 60 * 5);
	}

	render() {
		const { classes, kpis, kpiValues, summaries } = this.props;
		return (
			<React.Fragment>
				{kpis && 
				<Grid container spacing={1} className={classes.root}>
					<Grid item xs={12}>
						<Paper className={classes.sales}>
							<Grid container spacing={1}>
								<Grid item s={12} md={6}>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<Typography component="h2" align="center" variant="h6" color="secondary">Touch Screen Status</Typography>
										</Grid>

										<Grid item xs={12} md={6} className={classes.gaugeContent}>
											<div className={classes.gaugeTitle}>Operational Tills</div>
											<AmTouchscreensInUse
												width="100%"
												id="screensinuse"
												value={(kpiValues["TS_IN_USE"] / kpiValues["TS_TOTAL"]) * 100}/>
										</Grid>

										<Grid item xs={12} md={6} className={classes.gaugeContent}>
											<div className={classes.gaugeTitle}>Tills with problems</div>
											<AmTouchscreensOutOfAction
												width="100%"
												id="screensoutofaction"
												value={(kpiValues["TS_OUT_OF_ACTION"] / kpiValues["TS_TOTAL"]) * 100}/>
										</Grid>
									</Grid>
								</Grid>

								<Grid item s={12} md={6}>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<Typography component="h2" align="center" variant="h6" color="secondary">Actual Vs Predicated Sales</Typography>
										</Grid>

										<Grid item xs={12} md={6} className={classes.gaugeContent}>
											<div className={classes.gaugeTitle}>Last 10 mins</div>
											<WbGauge width="80%" classes={classes} expanded height="60px" value={kpiValues["PRED_ORD_PCNT_0010"]}/>
										</Grid>

										<Grid item xs={12} md={6} className={classes.gaugeContent}>
											<div className={classes.gaugeTitle}>Last 30 minutes</div>
											<WbGauge width="80%" classes={classes} expanded height="60px" value={kpiValues["PRED_ORD_PCNT_0030"]}/>
										</Grid>

										<Grid item xs={12} md={6} className={classes.gaugeContent}>
											<div className={classes.gaugeTitle}>Last 60 minutes</div>
											<WbGauge width="80%" classes={classes} expanded height="60px" value={kpiValues["PRED_ORD_PCNT_0060"]}/>
										</Grid>
										
										<Grid item xs={12} md={6} className={classes.gaugeContent}>
											<div className={classes.gaugeTitle}>Today so far</div>
											<WbGauge width="80%" classes={classes} expanded height="60px" value={kpiValues["PRED_ORD_PCNT_TDY"]}/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12} md={4}>
						<Link to="/nativereports/orders" style={{textDecoration: "none"}}>
							<Paper className={classes.reportsPaperWithSummaries}>
								<Grid container justify="space-between">
									<Grid item xs={5}>
										<Typography component="h2">
											Orders
										</Typography>
										<Typography component="h3">
											Basket and order values by store and kiosk
										</Typography>
									</Grid>
									
									<Grid item xs={6} md={6}>
										<Grid container>
											<Grid item xs={12}><span style={{ margin: 0, fontSize: "1.12rem",color: "black"}}>Todays Revenue</span></Grid>
										</Grid>

										<Grid container wrap="nowrap" justify="space-between">
											<Grid item><Typography variant="body2">Number of orders</Typography></Grid>
											<Grid item><Typography variant="body2" align="right">{summaries === null ? "--" : ReportHelpers.formatNumber(summaries.Orders.OverallToday.TotalBaskets)}</Typography></Grid>
										</Grid>

										<Grid container wrap="nowrap" justify="space-between">
											<Grid item><Typography variant="body2">Orders total</Typography></Grid>
											<Grid item><Typography variant="body2" align="right">{summaries === null ? "--" : ReportHelpers.formatCurrency(summaries.Orders.OverallToday.BasketsTotal)}</Typography></Grid>
										</Grid>

										<Grid container wrap="nowrap" justify="space-between">
											<Grid item><Typography variant="body2">Average order value</Typography></Grid>
											<Grid item><Typography variant="body2" align="right">{summaries === null ? "--" : ReportHelpers.formatCurrency(summaries.Orders.OverallToday.AverageBasketTotal)}</Typography></Grid>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Link>
					</Grid>
					<Grid item xs={12} md={4}>
						<Link to="/nativereports/baskets" style={{textDecoration: "none"}}>
							<Paper className={classes.reportsPaperWithSummaries}>
								<Grid container>
									<Grid item xs={5}>
										<Typography component="h2">
											Baskets
										</Typography>
										<Typography component="h3">
											Volume of basket sales
										</Typography>
									</Grid>
									<Grid item xs={2}/>
									<Grid item xs={5}>
									<Grid container>
										<Grid item xs={12}><span style={{ margin: 0, fontSize: "1.12rem",color: "black"}}>Today's Baskets by Store</span></Grid>
										{ summaries ? (
												<BasketsOverall
												classes={classes}
												items={summaries.Baskets.OverallToday}/>
										):(<Grid item xs={12}>--</Grid>)}
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Link>
					</Grid>
					<Grid item xs={12} md={4}>
						<Link to="/nativereports/items" style={{textDecoration: "none"}}>
							<Paper className={classes.reportsPaperWithSummaries}>
								<Grid container>
									<Grid item xs={5}>
										<Typography component="h2">
											Items
										</Typography>
										<Typography component="h3">
											Top selling items
										</Typography>
									</Grid>
									<Grid item xs={2}/>
									<Grid item xs={5}>
									<Grid container>
									<Grid item xs={12}><span style={{ margin: 0, fontSize: "1.12rem",color: "black"}}>Today's Top Selling Items</span></Grid>
										{ summaries ? (
												<TopSellingItemsOverall 
															items={summaries.Items.OverallToday} 
															classes={classes}/>
										):(<Grid item xs={12}>--</Grid>)}
										</Grid>									
									</Grid>
								</Grid>
							</Paper>
						</Link>
					</Grid>
					<Grid item xs={12} md={4} className={classes.hideIt}>
						<Link to="/nativereports/kioskmonitoring" style={{textDecoration: "none"}}>
							<Paper className={classes.reportsPaperWithSummaries}>
								<Grid container>
									<Grid item xs={7}>
										<Typography component="h2">
											Kiosk Monitoring
										</Typography>
										<Typography component="h3" className={classes.hideIt}>									
										</Typography>
									</Grid>
									<Grid item xs={5}/>
								</Grid>
							</Paper>
						</Link>
					</Grid>
					<Grid item xs={12} md={4}>
						<Link to="/nativereports/estateevents" style={{textDecoration: "none"}}>
							<Paper className={classes.reportsPaperWithSummaries}>
								<Grid container>
									<Grid item xs={7}>
										<Typography component="h2">
										Estate Events
										</Typography>
										<Typography component="h3">
											Find out which menu items are being re-ranged or disabled per store
										</Typography>
									</Grid>
									<Grid item xs={5}/>
								</Grid>
							</Paper>
						</Link>
					</Grid>
					<Grid item xs={12} md={4}>
						<Link to="/nativereports/outofstock" style={{textDecoration: "none"}}>
							<Paper className={classes.reportsPaperWithSummaries}>
								<Grid container>
									<Grid item xs={7}>
										<Typography component="h2">
											Out of Stock Items
										</Typography>
										<Typography component="h3">
											Today's and yesterday's out of stock items
										</Typography>
									</Grid>
									<Grid item xs={5}/>
								</Grid>
							</Paper>
						</Link>
					</Grid>
				</Grid> }
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(DashboardComponent);