import React from "react"
import { withStyles, CircularProgress, Grid } from "@material-ui/core";
import Report from 'react-powerbi';
import * as pbi from 'powerbi-client';
import { connect } from "react-redux"
import { reportsOperations } from "./duck"
import { dashboardOperations } from '../../duck'

const styles = theme => ({
	root: {
		position: "relative",
		//Max height the spacer can get
		maxHeight: `calc(100% - 64px)`,
		overflow: "hidden",

		"& .powerbi-frame": {
			position: "absolute",
			height: "100%",
			width: "100%",
			border: 0
		},

		"&:after": {
			content: "\"\"",
			display: "block",
			paddingBottom: `${(771 / 1336) * 100}%`
		}
	},
	progressWrapper: {
		position: "absolute",
		height: "100%"
	}
});

class ReportsPage extends React.Component {
	componentDidMount() {
		var reportName = this.props.match.params.report;

		this.props.updateTitle(reportName);
		this.props.getReport(reportName);
	}

	render() {
		const { classes, loading, reportId, embedToken, embedUrl } = this.props;

		return (
			<div className={classes.root}>
				{loading ? 
					<Grid container justify="center" alignItems="center" className={classes.progressWrapper}>
						<Grid item><CircularProgress/></Grid>
					</Grid> :
					
					<Report
					id={reportId}
					embedUrl={embedUrl}
					accessToken={embedToken}
					filterPaneEnabled={true}
					navContentPaneEnabled={true}
					tokenType={pbi.models.TokenType.Embed} />}
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	const getReport = (name) => dispatch(reportsOperations.getReport(name));
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
	return { getReport, updateTitle }
}

export default withStyles(styles)(connect(state => state.reports, mapDispatchToProps)(ReportsPage));