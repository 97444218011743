// types.js

import keyBy from 'lodash/keyBy';
const prefix = "SCREENSHOTS_";

const typeConvert = (...args) => keyBy(args.map((n)=>(prefix+n)),(o)=>( o.substring(prefix.length)));

export default typeConvert(
    "REQUEST_SCREENSHOTS_JSON",
    "RECEIVE_SCREENSHOTS_JSON"
);