import { connect } from 'react-redux';
import { dashboardOperations } from "../../duck"
import GrafanaDashboardComponent from './GrafanaDashboardComponent';

const mapStateToProps = (state) => {
	return {
		...state.dashboard
	}
}

const mapDispatchToProps = (dispatch) => {
	const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
	return {
		updateTitle
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GrafanaDashboardComponent)