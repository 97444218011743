import types from './types';

const INITIAL_STATE = {
    wallboard: {
        //"DbMs": 0
        Bottom10Stores: [], 
        Top10KiosksClosedEarly: [], 
        Top10MenuItems: [], 
        Top10Promotions: [], 
        Top10Stores: [], 
        Top10OutOfStocks: [], 
        KPIs: []
      },
    loading: false
};

const wallboardReportReducer = (state = INITIAL_STATE, action) => {
    //console.log("wallboardReportReducer: action.type = " + action.type);
    switch(action.type) {
        case types.RECEIVE_WALLBOARD_STATS: {
            return {
                ...state,
                wallboard: action.data.wallboard,
                loading: action.data.loading
            };
        }
        case types.REQUEST_WALLBOARD_STATS: {
            return {
                ...state,
                loading: action.data.loading
            };
        }
        default:
            return state;
    }
}

export default wallboardReportReducer;