import types from './types';

const INITIAL_STATE = {
    baskets: {
        "Overall": {
          "TotalBaskets": 0,
          "AverageBasketTotal": .0,
          "BasketsTotal": .0
        },
        "ByDate": [],
        "ByDay": [],
        "ByHour": [],
        "ByStore": [],
        "ByKiosk": [],
        "ByIndividualKiosk": [],
        "DbMs": 0
      },
    loading: false
};

const basketsReportReducer = (state = INITIAL_STATE, action) => {
    //console.log("ordersReportReducer: action.type = " + action.type);
    switch(action.type) {
        case types.RECEIVE_BASKETS_STATS: {
            return {
                ...state,
                baskets: action.data.baskets,
                loading: action.data.loading
            };
        }
        case types.REQUEST_BASKETS_STATS: {
            return {
                ...state,
                loading: action.data.loading
            };
        }
        default:
            return state;
    }
}

export default basketsReportReducer;