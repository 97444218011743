import types from './types'

const INITIAL_STATE = {
    users: [],
	loading: true,
	stores: [],
	resetting: false,
	resetError: false,
	addError: false
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.RECEIVE_USERS: {
            return {
                ...state,
                users: action.data.users,
                loading: state.stores.length === 0
            };
        }

        case types.DELETE_USER: {
            //Remove the user
			let users = state.users.slice();
			users.splice(state.users.indexOf(action.data), 1);

			return {
				...state,
				users: users
			};
        }

        case types.MASS_DELETE_USERS: {
            return {
				...state,
				users: state.users.filter(user => action.data.indexOf(user.CashierUserId) === -1)
            }
        }

        case types.EDIT_USER: {
            return {
                ...state,
                //Return the new object if the ids match
                users: state.users.map(user => user.CashierUserId === action.data.CashierUserId ? action.data : user)
            }
		}

		case types.GET_STORES: {
			return {
				...state,
				stores: action.data,
				loading: state.users.length === 0
			}
		}

		case types.ADD_USER: {
			if(!action.data)
				return {...state, addError: true};

			let users = state.users.slice();
			users.push(action.data);

			return {
				...state,
				users
			}
		}

		case types.RESET_PASSWORD: {
			return {
				...state,
				resetting: true,
				resetError: false
			}
		}

		case types.PASSWORD_RESET: {
			return {
				...state,
				resetting: false,
				resetError: !action.success
			}
		}

		case "CLEAR_RESET": {
			return {
				...state,
				resetError: false,
				addError: false
			}
		}

        default:
            return state;
    }
}

export default userReducer;