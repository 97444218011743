import { connect } from "react-redux"
import AccountComponent from "./AccountComponent";
import { dashboardOperations } from "../../duck"
import { accountOperations } from "./duck";

const mapStateToProps = (state) => {
	return {
		login: state.login,
		...state.account
	}
}

const mapDispatchToProps = dispatch => {
	const updateTitle = title => dispatch(dashboardOperations.updateTitle(title));
	const editAccount = user => dispatch(accountOperations.editAccount(user));
	const changePassword = passwords => dispatch(accountOperations.changePassword(passwords));
	const resetSaved = () => dispatch(accountOperations.resetSaved());

	return {
		updateTitle,
		editAccount,
		changePassword,
		resetSaved
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountComponent);