import React from "react"
import PropTypes from 'prop-types';
import { withStyles} from "@material-ui/core";
import { Tabs, Tab } from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';
import PageTitle from '../../PageTitle';

import KitchenFlowProductsContainer from './KitchenFlowProductsContainer';
import KitchenFlowStoresContainer from './KitchenFlowStoresContainer';

const styles = theme => ({
	title: {
        fontSize: "20px",
        color: "#999999"
	}
})

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`full-width-tabpanel-${index}`}
		aria-labelledby={`full-width-tab-${index}`}
		{...other}
	  >
		{value === index && (
   
			<div>{children}</div>
  
		)}
	  </div>
	);
}
  
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
  
class KitchenFlowManagementComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchPhrase: ""
		};
	}

	componentDidMount() {
		this.props.updateTitle("Cafe KitchenFlows");
	}

	onSearchClick = () => {
		this.props.productSearch(this.state.searchPhrase);
    }

	onSelectTab = (event, tabIndex) => {
		this.props.changeTab(tabIndex);
	}

	render() {
		const { classes, selectedTab} = this.props;

		return (
			<React.Fragment>
				<PageTitle/>

				<Tabs
					value={selectedTab}
					indicatorColor="primary"
					textColor="primary"
					aria-label="disabled tabs example"
					className={classes.bottomMargin}
					onChange={this.onSelectTab}
				>
					<Tab label="Products" />
					<Tab label="Stores" />
				</Tabs>


				<SwipeableViews
					index={selectedTab}
					onChangeIndex={this.onSelectTab } 
				>
					<TabPanel value={selectedTab} index={0} >
						<KitchenFlowProductsContainer />
					</TabPanel>

					<TabPanel value={selectedTab} index={1} >
						<KitchenFlowStoresContainer />
					</TabPanel>
				</SwipeableViews>

			</React.Fragment>
		)
	}
}

export default withStyles(styles)(KitchenFlowManagementComponent);