import { connect } from "react-redux";
import MenusComponent from "./MenusComponent";
import { menusOperations } from "./duck";
import { dashboardOperations } from '../../duck'

const mapStateToProps = state => {
    const { menus, loading } = state.menus;

    return {
        menus,
        loading
    };
};

const mapDispatchToProps = dispatch => {
    const onLoadMenusJson = () => dispatch(menusOperations.loadMenusJson());
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));

    return {
        onLoadMenusJson,
        updateTitle
    };
};

const MenusContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MenusComponent);

export default MenusContainer;
