import types from './types';

const requestOutOfStockActions = (data) => ({
    type: types.REQUEST_OUT_OF_STOCK,   
    data: data
});

const receiveOutOfStockActions = (data) => ({
    type: types.RECEIVE_OUT_OF_STOCK,
    data: data
});

export default {
    requestOutOfStockActions,
    receiveOutOfStockActions,
}