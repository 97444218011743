import { connect } from 'react-redux';
import EstateEventsReportComponent from './EstateEventsReportComponent';
import { estateEventsReportOperations } from './duck';
import { dashboardOperations } from '../../../duck';

const mapStateToProps = (state) => {
    return {
        ...state,
        login: state.login
    }
};

const mapDispatchToProps = (dispatch) => {
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
    const getEstateActions = (dateFrom, dateTo) => dispatch(estateEventsReportOperations.getEstateActions(dateFrom, dateTo));
    const getEstateMenuItems = (dateFrom, dateTo, esaction) => dispatch(estateEventsReportOperations.getEstateMenuItems(dateFrom, dateTo, esaction));
    const getEstateStores = (dateFrom, dateTo, esaction) => dispatch(estateEventsReportOperations.getEstateStores(dateFrom, dateTo, esaction));
    return {
        updateTitle, 
        getEstateActions, 
        getEstateMenuItems, 
        getEstateStores
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EstateEventsReportComponent)