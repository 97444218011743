import types from './types';

const requestWallboardStatsActions = (data) => ({
    type: types.REQUEST_WALLBOARD_STATS,   
    data: data
});

const receiveWallboardStatsActions = (data) => ({
    type: types.RECEIVE_WALLBOARD_STATS,
    data: data
});

export default {
    requestWallboardStatsActions,
    receiveWallboardStatsActions,
}