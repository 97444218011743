const EDIT_ACCOUNT = "EDIT_ACCOUNT";
const CHANGE_PASSWORD = "CHANGE_PASSWORD";
const SAVING_USER = "SAVING_USER";
const CHANGING_PASSWORD = "CHANGING_PASSWORD";
const RESET_SAVED = "RESET_SAVED";

export default {
	EDIT_ACCOUNT,
	CHANGE_PASSWORD,
	SAVING_USER,
	CHANGING_PASSWORD,
	RESET_SAVED
}