import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    noDataChart: {
      opacity: "0.5",
      pointerEvents:"none"
    },
});

class AmBasketValuesByKioskChart extends React.Component {
    constructor(props) {
        super(props);
		am4core.useTheme(am4themes_animated);
		this.state = { populated: false};
	}
    componentDidMount() {
		let poped = false;
		poped = this.props.data.length > 0;
		if(poped)
			this.setState({ populated: true});
		let chart = am4core.create("baskettakeawayandeatinchardiv", am4charts.XYChart);
		let title = chart.titles.create();
		title.text = "Order Eat In or Takeaway";
		title.fontSize = 21;
		title.align = "left";
		title.marginBottom = 30;
		if(this.props.data.length > 0) {
			chart.exporting.menu = new am4core.ExportMenu();	
			chart.exporting.filePrefix = title.text;	
		}
		//chart.paddingRight = 40;
		chart.paddingLeft = 0;
		chart.data = poped ? this.props.data : [];
		var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "Description";
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 30;
		var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
		valueAxis.min = 0;
//		valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
//			return "£" + label;
//		});
		var series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.integersOnly = true;
		series.dataFields.valueX = "Total";
		series.dataFields.categoryY = "Description";
		series.name = "Total";
		series.columns.template.tooltipText = "{categoryY}: [bold]{valueX}[/]";
		series.columns.template.fillOpacity = .8;
		var columnTemplate = series.columns.template;
		columnTemplate.fill = am4core.color("#004e37");
		columnTemplate.stroke = am4core.color("#00893a");
		columnTemplate.strokeWidth = 2;
		columnTemplate.strokeOpacity = 1;
		this.chart = chart;
    }  
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
    render() {
		const { populated } = this.state;
		const { classes } = this.props
        return (<div id="baskettakeawayandeatinchardiv" style={{ width: "100%", height: "500px" }} className={ classNames( populated === false ? classes.noDataChart : null )}></div>);
    }
}

export default withStyles(styles)(AmBasketValuesByKioskChart);