import React from 'react';
import { withStyles, Toolbar, Grid } from '@material-ui/core';
import PageTitle from '../../PageTitle';

const styles = theme => ({
    grafanaFrame: {
        display: "block",
        width: "100vw",
        height: "95vh",
        maxWidth: "100%",
        margin: "0",
        padding: "0",
        border: "0 none",
        boxSizing: "border-box"
    }
});

class GrafanaDashboardComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.props.updateTitle(this.props.dashTitle);
        let url = `${process.env.REACT_APP_MMOP_DASHBOARD}#feat=viewport-units`;

        if (this.props.bounceKey == "OVERVIEW") url = `${process.env.REACT_APP_OVERVIEW_DASHBOARD}#feat=viewport-units`;

        if (this.props.bounceKey == "SOP") url = `${process.env.REACT_APP_SOP_DASHBOARD}#feat=viewport-units`;

        if (this.props.bounceKey == "CFT") url = `${process.env.REACT_APP_CFT_DASHBOARD}#feat=viewport-units`;


        if (this.props.bounceKey == "DIGITAL") url = `${process.env.REACT_APP_DIGITAL_DASHBOARD}#feat=viewport-units`;


        if (this.props.bounceKey == "DISCOVERY") url = `${process.env.REACT_APP_DISCOVERY_DASHBOARD}#feat=viewport-units`;


        if (this.props.bounceKey == "REGIONAL") url = `${process.env.REACT_APP_REGIONAL_DASHBOARD}#feat=viewport-units`;

        if (this.props.bounceKey == "NEWLOOK") url = `${process.env.REACT_APP_NEWLOOK_DASHBOARD}#feat=viewport-units`;

        if (this.props.bounceKey == "CAFESIGNAGE") url = `${process.env.REACT_APP_CAFESIGNAGE_DASHBOARD}#feat=viewport-units`;

        if (this.props.bounceKey == "SINGLECHANNEL") url = `${process.env.REACT_APP_SINGLECHANNEL_DASHBOARD}#feat=viewport-units`;

        if (this.props.bounceKey == "CASHLEDGER") url = `${process.env.REACT_APP_CASHLEDGER_DASHBOARD}#feat=viewport-units`;


        if (this.props.bounceKey == "WEEKLYSTATS") url = `${process.env.REACT_APP_WEEKLYSTATS_DASHBOARD}#feat=viewport-units`;
        debugger;
        if (window.location.hostname == "localhost")
            url = "http://localhost:58568" + url;
        this.myRef.current.src = url;
    }

    render() {
        const { classes } = this.props;
        return <React.Fragment>
            <Toolbar variant="dense">
                <Grid container justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={6} md="auto">
                        <PageTitle />
                    </Grid>
                </Grid>
            </Toolbar>
            <iframe ref={this.myRef} className={classes.grafanaFrame}></iframe>
        </React.Fragment>
    }
}

export default withStyles(styles)(GrafanaDashboardComponent);