import types from './types'

const updateTitle = (data) => ({
    type: types.UPDATE_TITLE,
    data: data
});

const getKPIs = (data) => ({
	type: types.GET_KPIS,
	data: data
})

const getVersion = (data) => ({
	type: types.GET_VERSION,
	data: data
})

const getSummaries = (data) => ({
	type: types.GET_SUMMARIES,
	data: data
})

const disableSidebar = (data) => ({
    type: types.DISABLE_SIDEBAR,
    data: data
});

export default {
	updateTitle,
	getKPIs,
	getVersion, 
	getSummaries,
	disableSidebar
}