import { connect } from 'react-redux';
import { dashboardOperations } from "../../duck"
import { opsOperations } from "./duck"
import OpsDashboardComponent from './OpsDashboardComponent';

const mapStateToProps = (state) => {
	return {
		login:state.login,
		ops:state.ops
	}
}

const mapDispatchToProps = (dispatch) => {
	const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
	const getStoreDetail = (storeNumber)=> dispatch(opsOperations.getStoreDetail(storeNumber));
	const setMopState = (storeNumber,newState)=> dispatch(opsOperations.setMopState(storeNumber,newState));
	return {
		updateTitle,
		getStoreDetail,
		setMopState 
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OpsDashboardComponent)