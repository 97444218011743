import { connect } from 'react-redux'
import CashierUsersComponent from './CashierUsersComponent'
import { usersOperations } from './duck'
import { dashboardOperations } from '../../duck'

const mapStateToProps = (state) => {
    return {
		...state.cashier,
		login: state.login
    }
};

const mapDispatchToProps = (dispatch) => {
    const getUsers = () => dispatch(usersOperations.getUsers());
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
    const deleteUser = (user) => dispatch(usersOperations.deleteUser(user));
    const editUser = (user) => dispatch(usersOperations.editUser(user));
	const massDeleteUsers = (users) => dispatch(usersOperations.massDeleteUsers(users));
	const getStores = () => dispatch(usersOperations.getStores());
	const addUser = (user) => dispatch(usersOperations.addUser(user));
	const resetPassword = (user, newPassword, confirmPassword) => dispatch(usersOperations.resetPassword(user, newPassword, confirmPassword));
	const clearReset = () => dispatch({ type: "CLEAR_RESET" });

    return {
        getUsers,
        updateTitle,
        deleteUser,
        editUser,
		massDeleteUsers,
		getStores,
		addUser,
		resetPassword,
		clearReset
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CashierUsersComponent)