const REQUEST_PROMOTIONS = "REQUEST_PROMOTIONS";
const RECEIVE_PROMOTIONS = "RECEIVE_PROMOTIONS";
const UPDATE_PROMOTION = "UPDATE_PROMOTION";
const RECEIVE_PROMOTION = "RECEIVE_PROMOTION";
const DELETE_PROMOTION = "DELETE_PROMOTION";
const MOVE_PROMOTION = "MOVE_PROMOTION";
const GET_CATEGORIES = "GET_CATEGORIES";
const CREATE_PROMOTION = "CREATE_PROMOTION";

const GET_GROUPS = "GET_GROUPS";
const CREATE_GROUP = "CREATE_GROUP";
const REMOVE_GROUP = "REMOVE_GROUP";
const UPDATE_GROUP = "UPDATE_GROUP";
const GET_STORES = "GET_STORES";

export default {
	REQUEST_PROMOTIONS,
	RECEIVE_PROMOTIONS,
	UPDATE_PROMOTION,
	RECEIVE_PROMOTION,
	DELETE_PROMOTION,
	MOVE_PROMOTION,
	CREATE_PROMOTION,
	GET_CATEGORIES,
	GET_GROUPS,
	CREATE_GROUP,
	REMOVE_GROUP,
	UPDATE_GROUP,
	GET_STORES
}