import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    noDataChart: {
      opacity: "0.5",
      pointerEvents:"none"
    },
});

class AmBasketsByHourChart extends React.Component {
    constructor(props) {
        super(props);
        am4core.useTheme(am4themes_animated);
	}
    componentDidMount() {
		let chart = am4core.create("basketsbyhourchartdiv", am4charts.XYChart);
		let title = chart.titles.create();
		title.text = "Baskets by Hour";
		title.align = "left";
		title.fontSize = 21;
		title.marginBottom = 30;
		if(this.props.data.length > 0) {
			chart.exporting.menu = new am4core.ExportMenu();	
			chart.exporting.filePrefix = title.text;	
		}
		chart.data = this.props.data;
		var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "Hour";
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
			if (target.dataItem && target.dataItem.index & 2 === 2) {
				return dy + 25;
			}
			return dy;
		});
		if(this.props.data.length > 0) {
			categoryAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
				return label + ":00";
			});
		}
		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
			return label;
		});
		var series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = "Total";
		series.dataFields.categoryX = "Hour";
		series.name = "Total";
		series.columns.template.tooltipText = "{categoryX}:00: [bold]{valueY}[/]";
		series.columns.template.fillOpacity = .8;
		var columnTemplate = series.columns.template;
		columnTemplate.fill = am4core.color("#004e37");
		columnTemplate.stroke = am4core.color("#00893a");
		columnTemplate.strokeWidth = 2;
		columnTemplate.strokeOpacity = 1;
		this.chart = chart;
    }  
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
    render() {
		const { data, classes } = this.props
		return (<div id="basketsbyhourchartdiv" style={{ width: "100%", height: "500px" }} className={ classNames( data.length  === 0 ? classes.noDataChart : null )}></div>);
    }
}

export default withStyles(styles)(AmBasketsByHourChart);