import Creators from './actions';
import DuckApiCalls from "../../../../utils/duckApiCalls";
import UnauthorizedError from "../../../../utils/unauthorizedError";
import moment from 'moment';

const requestEstatesActionsAction = Creators.requestEstateActions;
const receiveEstateActionsAction = Creators.receiveEstateActions;
const requestEstateMenuItemsAction = Creators.requestEstateMenuItems;
const receiveEstateMenuItemsAction = Creators.receiveEstateMenuItems;
const requestEstatesStoresAction = Creators.requestEstateStores;
const receiveEstatesStoresAction = Creators.receiveEstateStores;

const getEstateActions = (dateFrom, dateTo) => {
    return (dispatch, getState) => {
        dispatch(requestEstatesActionsAction({
            estateActionsLoading: true
        }));
        new DuckApiCalls().getData("reporting/estateeventsactions?from=" + moment(dateFrom).format('DD-MM-YYYY') + "&to=" + moment(dateTo).format('DD-MM-YYYY')) 
        .then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
            return response.json()
            .then(payload => {
                dispatch(receiveEstateActionsAction({
                    estateActions: payload,
                    estateActionsLoading: false
                }));
            });
        })
        .catch(console.error);
    };
};

const getEstateMenuItems = (dateFrom, dateTo,estateAction) => {
    return (dispatch, getState) => {
        dispatch(requestEstateMenuItemsAction({
            estateMenuItemsLoading: true
        }));
        new DuckApiCalls().getData("reporting/estateeventsmenuItems?from=" + moment(dateFrom).format('DD-MM-YYYY') + "&to=" + moment(dateTo).format('DD-MM-YYYY') + "&estateAction=" + estateAction) 
        .then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
            return response.json()
            .then(payload => {
                dispatch(receiveEstateMenuItemsAction({
                    estateMenuItems: payload,
                    estateMenuItemsLoading: false
                }));
            });
        })
        .catch(console.error);
    };
};

const getEstateStores = (dateFrom, dateTo,esaction) => {
    return (dispatch, getState) => {
        dispatch(requestEstatesStoresAction({
            estateStoresLoading: true
        }));
        new DuckApiCalls().getData("reporting/estateeventsstores?from=" + moment(dateFrom).format('DD-MM-YYYY') + "&to=" + moment(dateTo).format('DD-MM-YYYY') + "&estateAction=" + esaction) 
        .then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
            return response.json()
            .then(payload => {
                dispatch(receiveEstatesStoresAction({
                    estateStores: payload,
                    estateStoresLoading: false
                }));
            });
        })
        .catch(console.error);
    };
};

export default {
    getEstateActions, 
    getEstateMenuItems, 
    getEstateStores
}