import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from "classnames";
import WbDateTime from './WbDateTime';
import WbBorder from './WbBorder';
import WbValue from './WbValue';
import WbValueCurrency from './WbValueCurrency';
import WbSuperSimpleBarChart from './WbSuperSimpleBarChart';
import WbSimpleTable from './WbSimpleTable';
import WbGauge from './WbGauge';
import PortalSettings from "../../../utils/portalSettings";

const UPDATE_PERIOD_MS = 10000;
const BAR_WIDTH = 940;
const wbWidth = 1920;
const wbHeight = 1080;

const colour01 = "rgba(29,29,29,1)";
const colour02 = "rgb(255,254,254)";
const colour03 = "rgba(237,28,36,1)";
const colour04 = "rgba(236,195,67,1)";
const colour05 = "rgba(0,137,59,1)";
const colour06 = "rgb(226,226,226)";
const backgroundColour = colour01;
const foregroundColour = colour02;
const locolor = colour05;
const midcolor = colour04;
const hicolor = colour03;
const normcolor = colour02;
const MorrisonsAgendaMedium = "MorrisonsAgenda Medium";
const MorrisonsAgendaBold = "MorrisonsAgenda Bold";

const styles = theme => ({
    root: {
		backgroundColor: "green",
		color: foregroundColour,
		padding: 0,
		margin: 0,
		fontFamily: MorrisonsAgendaMedium
	},
	wbOverlay: {
		position: "fixed",
		width: "100%",
		height: "100%",
		left: 0,	
		top: 0,
		background: "white",
		zIndex: "1300", // above app bar and everything else
	},
/*	wbCover: {
		position: "fixed",
		width: "100%",
		height: "100%",
		left: "0",	
		top: "0",
		background: "green",
	},*/
	wbDateTime: {
		fontFamily: MorrisonsAgendaBold,
		textAlign: "right",
		position: "absolute",
		right: "10px",
		top:"10px",
		fontSize: "1.75rem",
	},
	wbLnd: {
		width: wbWidth + "px",
		height: wbHeight + "px",
		backgroundColor: backgroundColour,
		//zoom: 1,
		padding: 0,
		margin: 0,
		color: foregroundColour,
		fontFamily: MorrisonsAgendaMedium
	},
	wbRow: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		width: "100%"
	},
	wbColumn: { // Placed in index.css
		display: "flex",
		flexDirection: "column",
		flexBasis: "100%",
		flex: 1
	},
	column75: { // Placed in index.css
		display: "flex",
		flexDirection: "column",
		flexBasis: "100%",
		flex: 3
	},	
	column25: { // Placed in index.css
		display: "flex",
		flexDirection: "column",
		flexBasis: "100%",
		flex: 1
	},
	wbTextEllipses: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	wbLowFillColour: {
		fill: locolor,
		color: locolor
	},	
	wbMidFillColour: {
		fill: midcolor,
		color: midcolor,
	},	
	wbHighFillColour: {
		fill: hicolor,
		color: hicolor, 		
	},	
	wbMidBgColour: {
		backgroundColor: midcolor,
	},	
	wbHighBgColour: {
		backgroundColor: hicolor, 		
	},	
	wbNormalFillColour: {
		fill: normcolor,
		color: normcolor
	},	
	wbGaugeTransitions: {
		transition: "width 0.25s ease, fill 0.25s" 
	},	
	wbValueTransitions: {
		transition: "fill 1s"
	},	
	wbGaugeTransX: {
		transition: "transform 0.25s ease"
	},	
	wbSectionSpacer: {
		margin: "3.75rem"
	},
	wbMidText: {
		fontFamily: MorrisonsAgendaBold,
		fontSize: "1.375rem"
	},
	wbSmallText: {
		fontSize: "1.125rem"
	},
	wbValueText: {
		fontFamily: MorrisonsAgendaBold,
		fontSize: "2.375rem",
		textAlign: "center"
	},
	wbLogo: { 
		fill: "white",
		//marginTop: "-30px",
		marginLeft: "10px",
		width: "162px",
		height: "150px"
	},
	wbHeader: {
		height: "150px",
		position: "relative"
	},
	wbLdHeading: {
		fontSize: "1.125rem",
		textAlign: "center",
		paddingTop: "0.25rem",
		paddingBottom: "1rem"	
	},	
	wbSmHeading: {
		fontSize: "1.125rem",
		textAlign: "center",
		paddingTop: "0.125rem",
		paddingBottom: "0.25rem"
	},
	wbBorder: {
		margin: "10px",
		/*width: 100%;*/   
		//border: "1px solid " + foregroundColour,
		'& .header':  {
			textTransform: "uppercase",
			float: "left",
			fontSize: "28px",
			fontWeight: "bold",
			paddingLeft: "410px",
			paddingRight: "5px",
			margin: "-18px 0 0 10px",
			background: backgroundColour
		},
		'& .content': {
			padding: "20px 10px 10px 10px"
		}
	},	
	wbValContainer: {
		width: "300px",
		height: "60px",
		borderStyle: "solid",
		borderColor: "rgba(255,255,255,.5)",
		borderWidth: "1px"
	},
	wbValInner: {    
		transition: "width 0.5s",
		backgroundColor: "green",
		height: "100%"
	},	
	wbValValue: {
		fontFamily: MorrisonsAgendaMedium,
		textAlign: "center",
		margin: "auto",
		fontSize: "1.375rem",
		verticalAlign: "middle",
		lineHeight: "60px"       
	},	
	// simple bar	
	wbSbText: {
		fontWeight: "bold",
		fontSize: "1.75rem",
		textTransform: "uppercase",
		fontFamily: MorrisonsAgendaBold
	},	  
	wbSbTextColor: {
		fill: colour01
	},	
	wbSbTextBoundsColor: {
		fill: colour02
	},	
	wbSbBarColor: {
		backgroundColor: colour02,
		fill: colour02
	},	
	wbSbBarAltColor: {
		backgroundColor: colour06,
		fill: colour06
	},	
	wbSbBarHeading: {
		fontFamily: MorrisonsAgendaBold,
		fontSize: "1.75rem"
	},	
	wbSbBarSubheading: {
		textTransform: "uppercase",
		fontSize: "1.25rem",
		paddingBottom: "20px"
	},	
	wbStText: {
		fontFamily:  MorrisonsAgendaBold,
		fontSize: "1.75rem",
		textTransform: "uppercase"
	},	
	wbSbBarSection: {
		height: "950px"
	},	
	wbSbBarSubsection: {
		height: "50%"
	},	
	wbSbBarSectionContainer: {
		position: "relative" 
	},	
	wbHide: {
		display: "none"
	},	
	wbSbBar: {
		opacity: 0,
		transform: "translateY(10%)",
		transition: "all 500ms"
	},		
	wbSbBarOn: {
		transform: "translateY(0%)",
		opacity: 1
	}
});

// Map value / caption
// Bottom10Stores = StoreName / OrdersTotal / OrdersCount
// Top10KiosksClosedEarly = StoreName / TerminalsCount
// Top10MenuItems = MenuItemName / MenuItemsCount
// Top10Promotions = PromtionsName / PromtionsCount
// Top10Stores = StoreName / OrdersTotal / OrdersCount
// Top10OutOfStocks = MenuItemName / MenuItemsCount
// KPIs
// PRED_ORD_PCNT_0010, Predicted 10 minutes (1.25000)
// PRED_ORD_PCNT_0030, Predicted 30 minutes (1.10000)
// PRED_ORD_PCNT_TDY,Predicted today (0.90000)
// CV_RATE_LAST_0060, Order conversion rate for last hour (0.25000)
// ORDERS_0001, Orders in last minute (7.00000)
// ORDERS_0010, Orders in last 10 minutes (51.00000)
// ORDERS_0030, Orders in last 30 minutes (140.00000)
// ORDERS_TODAY, Number of orders today (325.00000)
// AVGBASKET_0060, Average basket size in last 60 minutes (9.87000)
// New KPIs
// AVGBASKET_TDY
// TOTBASKET_0060
// TOTBASKET_TDY
// PRED_ORD_PCNT_0060

class WallboardReportComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = { firstLoad: true, pnl: -1, zoomLevel: 1};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	remapBottom10Stores(vals) {
		let valp = [];
		if(vals !== undefined)
			vals.forEach((v) => { valp.push({ caption: v.StoreName, value: v.OrdersCount }); });
		return valp;
	}

	remapTop10KiosksClosedEarly(vals) {
		let valp = [];
		if(vals !== undefined)
			vals.forEach((v) => { valp.push({ caption: v.StoreName, value: v.TerminalsCount }); });
		return valp;
	}

	remapTop10MenuItems(vals) {
		let valp = [];
		if(vals !== undefined)
			vals.forEach((v) => { valp.push({ caption: v.MenuItemName, value: v.MenuItemsCount }); });
		return valp;
	}

	remapTop10Promotions(vals) {
		let valp = [];
		if(vals !== undefined)
			vals.forEach((v) => { valp.push({ caption: v.PromtionsName, value: v.PromtionsCount }); });
		return valp;
	}

	remapTop10Stores(vals) {
		let valp = [];
		if(vals !== undefined)
			vals.forEach((v) => { valp.push({ caption: v.StoreName, value: v.OrdersCount }); });
		return valp;
	}

	remapTop10OutOfStocks(vals) {
		let valp = [];
		if(vals !== undefined)
			vals.forEach((v) => { valp.push({ caption: v.MenuItemName, value: v.MenuItemsCount }); });
		return valp;
	}

	remapKPIs(vals) {
		var valp = {};
		if(vals !== undefined)
			vals.forEach((v) => { valp[v.Key] = v.Value; });
		return valp;
	}

	updateValues() {
        this.setState((state) => {
            //return {pnl: 0}
            return {pnl: state.pnl  === 2 ? state.pnl = 0 : state.pnl = state.pnl + 1}
		});
		//this.props.getWallboardStats(new Date());
		this.props.getWallboardStats(new Date());
	}

	updateWindowDimensions() {
		//console.log("width:" + document.documentElement.offsetWidth);
		this.setState({ zoomLevel: document.documentElement.offsetWidth / wbWidth});
	};
	
	componentDidMount() {
		//console.log("width:" + document.documentElement.offsetWidth);
		window.addEventListener("resize", this.updateWindowDimensions);
		this.updateValues();
		this.props.updateTitle("Wallboard");
		this.timer = setInterval(() => this.updateValues(), UPDATE_PERIOD_MS);
	}

    componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions);
        clearInterval(this.timer);
        this.timer = null;
    }

	render() {

		const { classes } = this.props;
		const { //loading, 
				wallboard } = this.props.wallboardReport;
		const { pnl, zoomLevel } = this.state;

		const { 
				//DbMs, 
				Bottom10Stores, 
				Top10KiosksClosedEarly, 
				Top10MenuItems, 
				Top10Promotions, 
				Top10Stores, 
				Top10OutOfStocks, 
				KPIs
		} = wallboard;

		let bottom10Stores = this.remapBottom10Stores(Bottom10Stores);
		let top10KiosksClosedEarly = this.remapTop10KiosksClosedEarly(Top10KiosksClosedEarly);
		let top10MenuItems = this.remapTop10MenuItems(Top10MenuItems);
		let top10Promotions = this.remapTop10Promotions(Top10Promotions);
		let top10Stores = this.remapTop10Stores(Top10Stores);
		let top10OutOfStocks = this.remapTop10OutOfStocks(Top10OutOfStocks);
		let kpis = this.remapKPIs(KPIs);

		return (
			<div className={classNames(classes.wbOverlay)}>
			
				<div className={classes.wbLnd} style={{ zoom: zoomLevel }}>

				<div className={classNames(classes.wbRow)}>
                        <div className={'wb-Column'}>
                            <div className={classes.wbRow}>
                                <div className={classNames('wb-Column', classes.wbHeader)}>
                                    <div className={classes.wbRow}>
                                        <div className={'wb-Column'}>
											<img src={PortalSettings.wallboardLogo} style={{ cursor: "pointer" }} className={classes.wbLogo} onClick={() => this.props.history.goBack()} />
											{/* pnl */}
											{/* zoomLevel */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.wbRow}>
                                <div className={'wb-Column'}>
                                    <WbBorder classes={classes} heading="Live Data">
							   
							        <div className={classes.wbSectionSpacer}></div>
                                
								        <div className={classes.wbRow}>

                                            <div className={'wb-Column'}>
                                                <div className={classes.wbLdHeading}>ORDERS TOTAL</div>
                                                <div className={classes.wbRow}>
                                                    <div className={'wb-Column'}>
                                                        <div className={classes.wbSmHeading}>Last hour</div>
                                                        <WbValueCurrency classes={classes} value={kpis.TOTBASKET_0060}/>
                                                    </div>
                                                    <div className={'wb-Column'}>
                                                        <div className={classes.wbSmHeading}>Today so far</div>
                                                        <WbValueCurrency classes={classes} altcolour value={kpis.TOTBASKET_TDY}/>                                    
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'wb-Column'}>
                                                <div className={classes.wbLdHeading}>AVERAGE ORDER SIZE</div>
                                                <div className={classes.wbRow}>
                                                    <div className={'wb-Column'}>                                    
                                                        <div className={classes.wbSmHeading}>Last hour</div>
                                                        <WbValueCurrency classes={classes} value={kpis.AVGBASKET_0060}/> 
                                                    </div>
                                                    <div className={'wb-Column'}>                                    
                                                        <div className={classes.wbSmHeading}>Today so far</div>
                                                        <WbValueCurrency classes={classes} altcolour value={kpis.AVGBASKET_TDY}/>                                     
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className={classes.wbSectionSpacer}></div>

                                        <div className={classes.wbRow}>                                   
                                            <div className={classNames(classes.wbLdHeading,'wb-Column')}>NUMBER OF ORDERS PLACED</div>
                                        </div>

                                        <div className={classes.wbRow}>
                                            <div className={'wb-Column'}>
                                                <div className={classes.wbSmHeading}>Last minute</div>
                                                <WbValue classes={classes} value={kpis.ORDERS_0001}/>                          
                                            </div>
                                            <div className={'wb-Column'}>
                                                <div className={classes.wbSmHeading}>Last 10 mins</div>
                                                <WbValue classes={classes} value={kpis.ORDERS_0010}/>  
                                            </div>
                                            <div className={'wb-Column'}>
                                                <div className={classes.wbSmHeading}>Last 30 mins</div>
                                                <WbValue classes={classes} value={kpis.ORDERS_0030}/>  
                                            </div>
                                            <div className={'wb-Column'}>
                                                <div className={classes.wbSmHeading}>Today so far</div>
                                                <WbValue classes={classes} value={kpis.ORDERS_TODAY}/>  
                                            </div>
                                        </div>

                                        <div className={classes.wbSectionSpacer}></div>

                                        <div className={classes.wbRow}>                                   
                                            <div className={'wb-Column'}>
                                                <div className={classes.wbLdHeading}>ACTUAL Vs PREDICTED SALES</div>
                                                <div className={classes.wbRow}>
                                                    <div className={'wb-Column'}>
                                                        <div className={classes.wbSmHeading}>Last 10 mins</div>
                                                        <div style={{margin: "auto"}}>
                                                            <WbGauge classes={classes} expanded height="60px" value={kpis.PRED_ORD_PCNT_0010}/>
                                                        </div>
                                                    </div>
                                                    <div className={'wb-Column'}>
                                                        <div className={classes.wbSmHeading}>Last 30 mins</div>
                                                        <div style={{margin: "auto"}}>
                                                            <WbGauge classes={classes} expanded height="60px" value={kpis.PRED_ORD_PCNT_0030}/>
                                                        </div>
                                                    </div>  
                                                </div>
                                                <div className={classes.wbRow}>
                                                    <div className={'wb-Column'}>
                                                        <div className={classes.wbSmHeading}>Last hour</div>
                                                        <div style={{margin: "auto"}}>
                                                            <WbGauge classes={classes} expanded height="60px" value={kpis.PRED_ORD_PCNT_0060}/>
                                                        </div>
                                                    </div>
                                                    <div className={'wb-Column'}>
                                                        <div className={classes.wbSmHeading}>Today so far</div>
                                                        <div style={{margin: "auto"}}>
                                                            <WbGauge classes={classes} expanded height="60px" value={kpis.PRED_ORD_PCNT_TDY}/>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div> 

                                        <div className={classes.wbSectionSpacer}></div>

                                        <div className={classes.wbRow}>
                                            <div className={'wb-Column'} >
                                                <div className={classes.wbLdHeading}>CONVERSION RATE</div>
                                                <div style={{margin: "auto"}}>
                                                    <WbGauge classes={classes} width="760px" height="60px" value={kpis.CV_RATE_LAST_0060}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.wbSectionSpacer}></div>

                                    </WbBorder>
									
                                </div>
                            </div>
                        </div>

                        <div className={'wb-Column'}>

                            <div className={classes.wbRow}>
                                <div className={classNames('wb-Column', classes.wbHeader)}>
                                    <WbDateTime classes={classes} />
                                    <div style={{ position:"absolute",right:10, bottom: "25px", fontSize:"1.75rem", color: "#a4a4a4", fontWeight: "bold"}}>{PortalSettings.wallboardName}</div>
								</div>
                            </div>

                            <div className={classes.wbRow}>
                                <div className={classNames('wb-Column', classes.wbSbBarSectionContainer)}>

                                    <div className={classNames(classes.wbRow,classes.wbSbBarSection, classes.wbSbBar, pnl === 0 && classes.wbSbBarOn)} style={{position: "absolute", top: 0, left: 0}}>
                                        <div className={'wb-Column'}>
                                            <div className={classNames(classes.wbRow,classes.wbSbBarSubsection)}>
                                                <div className={'wb-Column'}>
                                                    <div style={{textAlign:"center"}}>
                                                        <div className={classes.wbSbBarHeading}>TOP SELLING ITEMS</div>
                                                        <div className={classes.wbSbBarSubheading}>Sold today</div>
														<WbSuperSimpleBarChart classes={classes} width={BAR_WIDTH} 
															topFill={classes.wbMidBgColour}
															values={top10MenuItems}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classNames(classes.wbRow, classes.wbSbBarSubsection)}>
                                                <div className={'wb-Column'}>
                                                    <div style={{textAlign:"center"}}>
                                                        <div className={classes.wbSbBarHeading}>TOP PERFORMING PROMOTIONS</div>
                                                        <div className={classes.wbSbBarSubheading}>Applied today</div>
														<WbSuperSimpleBarChart classes={classes} width={BAR_WIDTH} 
															topFill={classes.wbMidBgColour}
															values={top10Promotions}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classNames(classes.wbRow,classes.wbSbBarSection, classes.wbSbBar, pnl === 1 && classes.wbSbBarOn)} style={{position: "absolute", top: 0, left: 0}}>
                                        <div className={'wb-Column'}>
                                            <div className={classNames(classes.wbRow,classes.wbSbBarSubsection)}>
                                                <div className={'wb-Column'}>
                                                    <div style={{textAlign:"center"}}>
                                                        <div className={classes.wbSbBarHeading}>TOP PERFORMING STORES</div>
                                                        <div className={classes.wbSbBarSubheading}>Orders today</div>
														<WbSuperSimpleBarChart classes={classes} width={BAR_WIDTH} 
															topFill={classes.wbMidBgColour}
															values={top10Stores}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classNames(classes.wbRow, classes.wbSbBarSubsection)}>
                                                <div className={'wb-Column'}>
                                                    <div style={{textAlign:"center"}}>
                                                        <div className={classes.wbSbBarHeading}>TOP OUT-OF-STOCK ITEMS</div>
                                                        <div className={classes.wbSbBarSubheading}>Number of stores yesterday</div>
														<WbSuperSimpleBarChart classes={classes} width={BAR_WIDTH} 														
															topFill={classes.wbHighBgColour}
															values={top10OutOfStocks}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classNames(classes.wbRow,classes.wbSbBarSection, classes.wbSbBar, pnl === 2 && classes.wbSbBarOn)} style={{position: "absolute", top: 0, left: 0}}>
                                        <div className={'wb-Column'}>
                                            <div className={classNames(classes.wbRow, classes.wbSbBarSubsection)}>
                                                <div className={'wb-Column'}>
                                                    <div style={{textAlign:"center"}}>
                                                        <div className={classes.wbSbBarHeading}>WORST PERFORMING STORES</div>
                                                        <div className={classes.wbSbBarSubheading}>Orders today</div>
														<WbSuperSimpleBarChart classes={classes} reverseorder width={BAR_WIDTH} 
															topFill={classes.wbHighBgColour}
															values={bottom10Stores}/>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                    </div>

                                </div>
                            </div> 
							                                     
                        </div>

                    </div>

				</div>
			
			</div>)
	}
}

export default withStyles(styles)(WallboardReportComponent);

/*

		let top10MenuItems = [
			{
			   "caption":"Refillable Tea",
			   "value":4602
			},
			{
			   "caption":"Big Breakfast",
			   "value":2720
			},
			{
			   "caption":"Refillable Latte",
			   "value":2198
			},
			{
			   "caption":"Fizzy",
			   "value":2136
			},
			{
			   "caption":"Small breakfast",
			   "value":1690
			},
			{
			   "caption":"Refillable Black Coffee",
			   "value":1370
			},
			{
			   "caption":"Regular Fizzy (Dispensed)",
			   "value":1364
			},
			{
			   "caption":"Big Daddy Breakfast",
			   "value":1318
			},
			{
			   "caption":"Create Your Own",
			   "value":1258
			},
			{
			   "caption":"Refillable Cappuccino",
			   "value":1186
			}
		];

		let vals1 = [
			{value:4102,caption:"Ultimate Breakfast Deal"},
			{value:4019,caption:"Coffee & a Cake"},
			{value:3241,caption:"Kids Eat Free"},
			{value:2610,caption:"Feed a Family for £10"},
			{value:1454,caption:"Tea Time Meal Deal"},
			{value:1370,caption:"Refillable Black Coffee"}
		];
					 
		let vals2 = [
		  {value:102,caption:"Ultimate Breakfast Deal"},
		  {value:7019,caption:"Coffee & a Cake"},
		  {value:1241,caption:"Kids Eat Free"},
		  {value:610,caption:"Feed a Family for £10"},
		  {value:154,caption:"Tea Time Meal Deal"},
		  {value:2340,caption:"Free Baby Food"}
		];

		let vals4 = [
			{value:250,caption:"OLDHAM"},
			{value:258,caption:"BOLTON ATLAS"},
			{value:1,caption:"STOCKTON"},
			{value:4,caption:"WALSALL"},
			{value:3,caption:"TYNEMOUTH"},
			{value:1,caption:"KENDAL"},
			{value:1,caption:"STOKE"},
			{value:1,caption:"LINCOLN"}
		  ];

*/