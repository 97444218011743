import { connect } from 'react-redux'
import KitchenFlowProductsComponent from './KitchenFlowProductsComponent';
import { kitchenFlowOperations } from './duck';

const mapStateToProps = (state)  => {
	return {
		...state.kitchenFlows
	}
};

const mapDispatchToProps = (dispatch) => {
	const getProducts = (criteria) => dispatch(kitchenFlowOperations.getProducts(criteria));
	const getClassifications = () => dispatch(kitchenFlowOperations.getClassifications());
	const updateProductClassification = (product) => dispatch(kitchenFlowOperations.updateProductClassification(product));
	const changeRowsPerPage = (rows) => dispatch(kitchenFlowOperations.changeRowsPerPage(rows));
	const changePage = (page) => dispatch(kitchenFlowOperations.changePage(page));
	const productSearch = (searchPhrase) => dispatch(kitchenFlowOperations.productSearch(searchPhrase));
	const changeSortBy = (column) => dispatch(kitchenFlowOperations.changeSortBy(column));

	return {
		getProducts,
		getClassifications,
		updateProductClassification,
		changeRowsPerPage,
		changePage,
		productSearch,
		changeSortBy
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(KitchenFlowProductsComponent)