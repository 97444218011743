// reducers.js
import types from "./types";

const INITIAL_STATE = {
  stores: [],
  loading: true,
  cacheBust:""
};

const screenshotsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_SCREENSHOTS_JSON:
      return {
        ...state,
        loading: true,
      };

    case types.RECEIVE_SCREENSHOTS_JSON:
      return {
        ...state,
        stores: action.data.receivedData.Stores,
        loading: false,
        cacheBust: "?v=" + new Date().getTime()
      };

    default:
      return state;
  }
};

export default screenshotsReducer;
