import React from "react"
import { Redirect } from "react-router-dom"
import { Toolbar, Typography, withStyles, Grid, AppBar, Divider, TextField, Button, Paper, CircularProgress } from "@material-ui/core";
import PortalSettings from "../../utils/portalSettings";

const name = PortalSettings.name;

const styles = theme => ({
	wrapper: {
		height: "calc(100vh - 202px)"
	},
	grid: {
		padding: theme.spacing() * 3,
		color: theme.palette.grey[500]
	},
	title: {
		textAlign: "center",
		padding: theme.spacing() * 4,
		color: "#666666"
	},
	field: {
		fontSize: "14px",
		paddingLeft: theme.spacing()
	},
	logo: {
		width: "20%",
		maxWidth:200
	}
})

class LoginComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			username: "",
			password: ""
		}
	}

	componentDidMount() {
		this.props.checkUser();
	}

	onSubmit = (ev) => {
		ev.preventDefault();
		this.props.loginUser(this.state.username, this.state.password);
	}

	onUsernameChange = (ev) => {
		this.setState({ username: ev.target.value });
	}

	onPasswordChange = (ev) => {
		this.setState({ password: ev.target.value });
	}

	render() {
		const { classes, loggingIn, error, cause, authenticated, location } = this.props;

		var redirectTo = (location.state && location.state.from) || { pathname: "/" };
		if(authenticated) return <Redirect to={redirectTo} />

		return (
			<React.Fragment>
				<AppBar>
					<Toolbar>
						<Typography component="h1" color="inherit" variant="h6" noWrap>
							{name}
						</Typography>
					</Toolbar>
				</AppBar>

				<Grid container className={classes.wrapper}>
					<Grid item xs={12} style={{textAlign: "center"}}>
						<Toolbar></Toolbar>
						<img className={classes.logo} src={PortalSettings.logo} alt="Morrisons logo"></img>
					</Grid>

					<Grid item xs={12}>
						<Grid container alignItems="center" justify="center">
							<Grid item>
								<Paper component="form" onSubmit={this.onSubmit}>
									<div className={classes.title}>
										<Typography component="h2" variant="subtitle1" color="inherit" noWrap>
											Log in to {name}
										</Typography>
									</div>

									<Divider/>

									<Grid container className={classes.grid}>
										{error && cause === "suspended" && 
											<Grid item xs={12}>
												<Typography gutterBottom component="p" variant="caption" color="error" align="center">Account Suspended</Typography>
											</Grid>
										}

										<Grid item xs={12}>
											<TextField
												fullWidth
												required
												type="test"
												inputProps={{className: classes.field}}
												placeholder="Email Address or Ops name*" 
												margin="dense"
												autoComplete="email"
												error={error && cause === "email"}
												onChange={this.onUsernameChange}
												helperText={error && cause === "email" && "Email address is incorrect"} />
										</Grid>

										<Grid item xs={12}>
											<TextField
												fullWidth
												required
												inputProps={{className: classes.field}}
												placeholder="Password*"
												margin="normal"
												type="password"
												autoComplete="current-password"
												error={error && (cause === "password" || cause==="servererror")}
												onChange={this.onPasswordChange}
												helperText={(error && cause === "password" && "Password is incorrect") || error && cause === "servererror" && "Server error" } />
										</Grid>
									</Grid>

									<Divider/>

									<Grid container className={classes.grid} justify="center">
										<Grid item xs={!loggingIn && 12}>
											{loggingIn ? <CircularProgress/> : 
												<Button 
													type="submit"
													fullWidth
													variant="contained"
													color="primary">SIGN IN</Button>}
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}

export default withStyles(styles)(LoginComponent);