import React from 'react'
import {connect} from 'react-redux'
import { Link } from "react-router-dom"
import { dashboardOperations } from '../../duck'
import { Paper, Grid, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	paper: {
		padding: `${theme.spacing() * 3}px ${theme.spacing() * 2}px`,
		height: "100%",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column"
	},
	root: {
		"& a": {
			textDecoration: "none"
		},
		"& h2": {
			fontSize: "34px",
			lineHeight: "34px",
			flex: "0",

			"& + h3": {
				marginTop: "30px"
			}
		},
		"& h3": {
			fontSize: "16px",
			flex: "0",
		}
	}
});

class ReportsComponent extends React.Component {
	componentDidMount() {
		this.props.dispatch(dashboardOperations.updateTitle("Reports"));
	}

	render() {
		const { classes } = this.props;

		return (
			<Grid container spacing={16} className={classes.root}>
				<Grid item xs={12} md={4}>
					<Link to="/reports/Morrisons Cafe - Orders">
						<Paper className={classes.paper}>
							<Typography component="h2">
								Total value of order by date
							</Typography>
						</Paper>
					</Link>
				</Grid>

				<Grid item xs={12} md={4}>
					<Link to="/reports/Morrisons Cafe - Baskets">
						<Paper className={classes.paper}>
							<Typography component="h2">
								Orders
							</Typography>

							<Typography component="h3">
								Basket and order values by store and kiosk
							</Typography>
						</Paper>
					</Link>
				</Grid>

				<Grid item xs={12} md={6} lg={4}>
					<Link to="/reports/Morrisons Cafe - Estate Events">
						<Paper className={classes.paper}>
							<Typography component="h2">
								Ranging report
							</Typography>

							<Typography component="h3">
								Find out which menu items are being re-ranged or disabled per store
							</Typography>
						</Paper>
					</Link>
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<Link to="/reports/Morrisons Cafe - Monitoring">
						<Paper className={classes.paper}>
							<Typography component="h2">
								Monitoring report
							</Typography>
							
							<Typography component="h3">
								Summary per kiosk of open %, deranged items, issues and orders
							</Typography>
						</Paper>
					</Link>
				</Grid>

				<Grid item xs={12} md={6}>
					<Link to="/reports/Morrisons Cafe - Transaction Report">
						<Paper className={classes.paper}>
							<Typography component="h2">
								All transactions
							</Typography>

							<Typography component="h3">
								Export full transaction details
							</Typography>
						</Paper>
					</Link>
				</Grid>

				<Grid item xs={12} md={6}>
					<Link to="/reports/Morrisons Cafe - Upsold Items">
						<Paper className={classes.paper}>
							<Typography component="h2">
								Upsold items
							</Typography>
						</Paper>
					</Link>
				</Grid>
			</Grid>
		)
	}
}

export default withStyles(styles)(connect()(ReportsComponent));