import Creators from "./actions";
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";
import moment from 'moment';

const receiveUsersAction = Creators.receiveUsers;
const requestUsersAction = Creators.requestUsers;
const deleteUserAction = Creators.deleteUser;
const suspendUserAction = Creators.suspendUser;
const unsuspendUserAction = Creators.unsuspendUser;
const editUserAction = Creators.editUser;
const massSuspendUserAction = Creators.massSuspendUsers;
const massDeleteUsersAction = Creators.massDeleteUsers;
const getRolesAction = Creators.getRoles;
const newUserAction = Creators.newUser;
const creatingOpsUsersAction = Creators.creatingOpsUsers;
const createdOpsUsersAction = Creators.createdOpsUsers;

const getUsers = () => {
    return (dispatch, getState) => {
        dispatch(requestUsersAction());

        new DuckApiCalls().getData("users/getUsers").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(payload => {
                //Fix date
                payload.forEach(element => {
                    element.LastLoggedIn = moment(element.LastLoggedIn);
                });

                dispatch(receiveUsersAction({
                    users: payload,
                    loading: false
                }));
            });
        }).catch(console.error);
    };
};

const deleteUser = (user) => {
    return (dispatch, getState) => {
        new DuckApiCalls().deleteDataWithBody("users/deleteUser", { userId: user.UserId }).then((response) => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(function (payload) {
                if(payload)
                    dispatch(deleteUserAction(user));
            });
        }).catch(console.error);
    }
};

const toggleSuspendUser = (user) => {
    return (dispatch, getState) => {
        new DuckApiCalls().putData("users/suspendUser", { userId: user.UserId, suspend: !user.IsSuspended }).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(payload => {
                if(payload)
                    dispatch(user.IsSuspended ? unsuspendUserAction(user) : suspendUserAction(user));
            });
        }).catch(console.error);
    }
}

const editUser = (user) => {
    return (dispatch, getState) => {
        new DuckApiCalls().putData("users/edituser", user).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(payload => {
                if(payload)
                    dispatch(editUserAction(user));
            });
        }).catch(console.error);
    }
}

const massSuspendUsers = (users, suspend = true) => {
    return (dispatch, getState) => {
        new DuckApiCalls().putData("users/suspendUsers", { userIds: users, suspend }).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
			
			return response.json().then(payload => {
                if(payload)
                    dispatch(massSuspendUserAction({users, suspend}));
            });
        }).catch(console.error);
    }
}

const massDeleteUsers = (users) => {
    return (dispatch, getState) => {
        new DuckApiCalls().deleteDataWithBody("users/deleteUsers", users).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

            return response.json().then(payload => {
                if(payload)
                    dispatch(massDeleteUsersAction(users));
            });
        }).catch(console.error);
    }
}

const getRoles = () => {
	return (dispatch, getState) => {
		new DuckApiCalls().getData("roles/getRoles").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				dispatch(getRolesAction(payload));
			});
		}).catch(console.error);
	}
}

const newUser = user => {
	return dispatch => {
		new DuckApiCalls().postData("users/createUser", user).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				if(!payload.error)
				{
					user.UserId = payload.id;
					dispatch(newUserAction(user));
				} else
					dispatch(newUserAction(payload));
			});
		}).catch(console.error);
	}
}


const createOpsUsers = user => {
	return dispatch => {
		new DuckApiCalls().postData("users/createOpUsers").then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
                dispatch(createdOpsUsersAction());
                dispatch(getUsers());
			});
        }).catch(console.error);
        dispatch(creatingOpsUsersAction());
	}
}

const resetErrors = () => {
	return dispatch => {
		dispatch({ type: "ResetErrors" });
	}
}

export default {
    getUsers,
    deleteUser,
    toggleSuspendUser,
    editUser,
    massSuspendUsers,
	massDeleteUsers,
	getRoles,
	newUser,
    resetErrors,
    createOpsUsers
};
