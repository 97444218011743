const UPDATE_TITLE = "UPDATE_TITLE";
const GET_KPIS = "GET_KPIS";
const GET_VERSION = "GET_VERSION";
const GET_SUMMARIES = "GET_SUMMARIES";

const DISABLE_SIDEBAR = "DISABLE_SIDEBAR";

export default {
	UPDATE_TITLE,
	GET_KPIS,
	GET_VERSION, 
	GET_SUMMARIES,
	DISABLE_SIDEBAR
};