import types from './types';

const requestEstateActions = (data) => ({
    type: types.REQUEST_ESTATE_ACTIONS,   
    data: data
});

const receiveEstateActions = (data) => ({
    type: types.RECEIVE_ESTATE_ACTIONS,
    data: data
});

const requestEstateMenuItems = (data) => ({
    type: types.REQUEST_ESTATE_MENU_ITEMS,   
    data: data
});

const receiveEstateMenuItems = (data) => ({
    type: types.RECEIVE_ESTATE_MENU_ITEMS,
    data: data
});

const requestEstateStores = (data) => ({
    type: types.REQUEST_ESTATE_STORES,   
    data: data
});

const receiveEstateStores = (data) => ({
    type: types.RECEIVE_ESTATE_STORES,
    data: data
});

export default {
    requestEstateActions,
    receiveEstateActions,
    requestEstateMenuItems, 
    receiveEstateMenuItems, 
    requestEstateStores, 
    receiveEstateStores
}