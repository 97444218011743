import Creators from "./actions"
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";

const retrieveReportAction = Creators.retrieveReport;
const recieveReportAction = Creators.recieveReport;

const getReport = (reportName) => {
	return (dispatch, getState) => {
		dispatch(retrieveReportAction());

		new DuckApiCalls().postData("dashboard/Report", { reportName }).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			//404 if report not found
			if(response.status !== 404)
				return response.json().then(payload => {
					dispatch(recieveReportAction(payload));
				})
		}).catch(console.error)
	};
};

export default {
	getReport
};