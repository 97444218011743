import Creators from './actions';
import DuckApiCalls from "../../../../utils/duckApiCalls";
import UnauthorizedError from "../../../../utils/unauthorizedError";
import moment from 'moment';

const requestOutOfStockAction = Creators.requestOutOfStockActions;
const receiveOutOfStockAction = Creators.receiveOutOfStockActions;

const getOutOfStock = (ondate) => {
    return (dispatch, getState) => {
        dispatch(requestOutOfStockAction({
            loading: true, 
            outOfStock: []
        }));
        new DuckApiCalls().getData("reporting/outofstock?ondate=" + moment(ondate).format('DD-MM-YYYY')) 
        .then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
            return response.json()
            .then(payload => {
//                if(payload.length > 0) {
//                   payload[0].StoreName = "Morely";
//               }
                dispatch(receiveOutOfStockAction({
                    outOfStock: payload,
                    loading: false
                }));
            });
        })
        .catch(console.error);
    };
};

export default {
    getOutOfStock
}