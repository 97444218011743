import React from 'react'
import PropTypes from 'prop-types';
import { Typography, withStyles, Checkbox, CircularProgress, Button, ButtonBase, Drawer, Divider, AppBar, Toolbar, Grid, TextField, Menu, IconButton, MenuItem, NativeSelect, Select, OutlinedInput, FormControl, InputLabel } from '@material-ui/core';
import { Dialog, DialogTitle, DialogActions } from '@material-ui/core'
import { TableHead, TableRow, TableCell, Table, TableBody, TablePagination, TableSortLabel } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import types from './duck/types'
import classNames from 'classnames';
import { checkRole } from "../../utils/userUtils";
import moment from "moment";
import PageTitle from '../../PageTitle';

const userColumns = [{ col: "Name", sort: true }, { col: "CashierNumber", title: "Cashier Number" }, { col: "StoreId", sort: true, title: "Store" }, { col: "Flags" }];

var flags = [
	{ type: "Manager", flag: 1 },
	{ type: "Over 18", flag: 2 }
];

const styles = theme => ({
    root: {
        // padding: theme.spacing.unit * 3
    },
    title: {
        fontSize: "20px",
        color: "#999999"
    },
    loading: {
        textAlign: "center",
        padding: theme.spacing() * 2
    },
    actions: {
        "& button": {
            textDecoration: "underline",

            "& + button": {
                paddingLeft: "5px"
            }
        }
    },
    drawer: {
        width: "25%",

        [theme.breakpoints.down('md')]: {
            width: "50%",
        },

        [theme.breakpoints.down('sm')]: {
            width: "auto",
        },
    },
    drawerTitle: {
        fontSize: "20px",
        color: theme.palette.common.white
    },
    drawerField: {
        padding: theme.spacing() * 2,
        paddingBottom: 0,

        '& + .drawerField': {
            paddingTop: theme.spacing() * 2
        }
    },
    search: {
        maxWidth: "0",
        transition: "max-width 0.5s ease"
    },
    searchSlide: {
        maxWidth: "100%"
	},
	resetError: {
		fontSize: "14px",
		fontWeight: "bold",
		textAlign: "center",
		padding: "20px 16px 0"
	},
	fullNative: {
		"& select": {
			width: "calc(100% - 32px)"
		}
	}
});

function displayColumn(user, column, stores) {
	var data = user[column.col || column];

	if(Array.isArray(data))
		return data.reduce((prev, curr) => prev + ", " + curr.Role, "").substr(2);

	//Format dates
	if(moment.isMoment(data))
		return data.format("HH:mm DD/MM/YYYY");

	//Show flags differently
	if(column.col === "Flags")
		return flags.filter(f => (data & f.flag) !== 0).map(a => a.type).join(", ");

	if(column.col === "StoreId")
	{
		var store = stores.filter(s => s.storeId === parseInt(data));
		return store.length > 0 ? store[0].name : data;
	}

	return data;
}

const UsersRow = (props) => {
    const { state, user, onSelect, onEdit, onDelete, classes, isEditor, stores, onReset } = props;

    var isSelected = state.selected.indexOf(user.CashierUserId) !== -1;

    return (
        <TableRow>
            <TableCell padding="checkbox">
                <Checkbox color="primary" checked={isSelected} onChange={(e) => onSelect(e)}></Checkbox>
            </TableCell>

            {userColumns.map((column) => (
				<TableCell key={column.col || column}>
					{displayColumn(user, column, stores)}
				</TableCell>
            ))}

            <TableCell className={classes.actions}>
				{isEditor && <ButtonBase disableRipple={true} onClick={onEdit}>Edit</ButtonBase>}

				{isEditor && <ButtonBase disableRipple={true} onClick={onReset}>Reset Password</ButtonBase>}
				
                <ButtonBase disableRipple={true} onClick={onDelete}>Delete</ButtonBase>
            </TableCell>
        </TableRow>
    );
};

UsersRow.propTypes = {
    user: PropTypes.object,
    onSelect: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
	classes: PropTypes.object,
	isEditor: PropTypes.bool
};

const DeleteDialog = (props) => {
    const { open, onClose, onAccept, multiple } = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Are you sure you want to delete {multiple ? "these users" : "this user"}?</DialogTitle>

            <DialogActions>
                <Button onClick={onAccept}>Yes</Button>
                <Button onClick={onClose}>No</Button>
            </DialogActions>
        </Dialog>
    )
}

DeleteDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    multiple: PropTypes.bool
}

class EditDrawer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			newPass: "",
			confirmPass: "",
			error: false,
			passwordError: false,
			newPasswordError: false,
			confirmPasswordError: false
		};
	}
	
	onChange = ev => {
		var user = this.state.user || this.props.user || {};

		//limit number
		if(ev.target.name === "CashierNumber")
		{
			this.setState({ error: parseInt(ev.target.value) < 1000 || parseInt(ev.target.value) > 9999 });
		}

		//limit password
		if(ev.target.name === "Password")
		{
			this.setState({ passwordError: !!ev.target.value && ev.target.value.length !== 4 });
		}

		user[ev.target.name] = ev.target.checked || ev.target.value;
		this.setState({ user });
	}

	onChangeFlags = ev => {
		var user = this.state.user || this.props.user || {};
		user.FlagsArr = ev.target.value;

		user.Flags = 0;
		ev.target.value.forEach(f => {
			user.Flags = user.Flags | f;
		});

		this.setState({ user });
	}

	onChangeReset = ev => {
		if(ev.target.name === "NewPassword")
		{
			this.setState({ newPass: ev.target.value });
			this.setState({ newPasswordError: !!ev.target.value && ev.target.value.length !== 4 });
		}

		if(ev.target.name === "OldPassword")
		{
			this.setState({ confirmPass: ev.target.value });
			this.setState({ confirmPasswordError: !!ev.target.value && ev.target.value.length !== 4 });
		}
	}

	onClose = () => {
		this.setState({ user: null, error: false, passwordError: false });
		this.props.onClose();
	}

	onSave(ev) {
		delete this.state.user.FlagsArr;
		this.props.onSave(ev, this.state.user);
		this.setState({ user: null });
	}

	onReset(ev) {
		this.props.onReset(ev, this.props.user, this.state.newPass, this.state.confirmPass);
		this.setState({ user: null, newPass: "", confirmPass: "" });
	}

	renderValue = flag => {
		if(!flag.length)
			return "None";

		return flags.filter(f => flag.indexOf(f.flag) !== -1).map(a => a.type).join(", ")
	}

	render() {
		const { open, classes, stores, reset, resetting, resetError, addError } = this.props;
		var { user, error, passwordError, newPasswordError, confirmPasswordError } = this.state;
		const newUser = !this.props.user;

		if(!user && !newUser && !reset)
		{
			user = this.props.user;
			user.FlagsArr = flags.filter(f => (user.Flags & f.flag) !== 0).map(a => a.flag);
		}

		return (
			//Apply the class to the paper within the drawer
			<Drawer anchor="right" open={open} onClose={this.onClose} classes={{paper: classes.drawer}}>
				<div>
					<AppBar position="static">
						<Toolbar>
							<Typography component="p" className={classes.drawerTitle}>
								{reset ? `Resetting password for "${this.props.user && this.props.user.Name}"` : !newUser ? `Editing user "${user && user.Name}"` : "Creating new user"}
							</Typography>
						</Toolbar>
					</AppBar>

					<Divider />

					{!reset ? <Grid container component="form" onSubmit={ev => this.onSave(ev)}>
						<Grid item xs={12} className={classes.drawerField}>
							<TextField name="Name" autoComplete="off" required fullWidth label="Name" placeholder="Name" variant="outlined" defaultValue={user && user.Name} onChange={this.onChange}/>
						</Grid>

						<Grid item xs={12} className={classes.drawerField}>
							<TextField name="CashierNumber" type="number" autoComplete="off" required fullWidth label="Cashier Number" placeholder="1234" variant="outlined" error={addError || error} helperText={(addError || error) && "Must be between 1000 and 9999 and unique for this store"} defaultValue={user && user.CashierNumber} onChange={this.onChange} />
						</Grid>

						{newUser && <Grid item xs={12} className={classes.drawerField}>
							<TextField name="Password" type="password" autoComplete="off" required fullWidth label="Password" placeholder="1234" variant="outlined" error={passwordError} helperText={passwordError && "Must be 4 characters long"} onChange={this.onChange} />
						</Grid>}

						<Grid item xs={12} className={classes.drawerField}>
							<FormControl fullWidth variant="outlined">
								<InputLabel htmlFor="flags" shrink>
									Flags *
								</InputLabel>
								
								<Select multiple name="Flags" fullWidth value={(user && user.FlagsArr) || []} onChange={this.onChangeFlags} displayEmpty renderValue={this.renderValue} input={<OutlinedInput notched id="flags" labelWidth={50}/>}>
									<MenuItem disabled value={0}>None</MenuItem>

									{flags.map(f => (
										<MenuItem key={f.flag} value={f.flag}>{f.type}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} className={classes.drawerField}>
							<FormControl fullWidth variant="outlined">
								<InputLabel htmlFor="store" shrink>
									Store *
								</InputLabel>

								<NativeSelect className={classes.fullNative} name="StoreId" required fullWidth value={(user && user.StoreId) || ""} onChange={this.onChange} input={<OutlinedInput notched id="store" labelWidth={50}/>}>
									<option value="" disabled>
										Select a store
									</option>

									{stores.map(s => <option key={s.storeId} value={s.storeId}>{`${s.storeNumber} - ${s.name}`}</option>)}
								</NativeSelect>
							</FormControl>
						</Grid>

						<Grid item xs={12} className={classes.drawerField}>
							<Grid container justify="space-between">
								<Grid item>
									<Button disabled={error} type="submit" variant="contained" color="primary">
										Save
									</Button>
								</Grid>
								
								<Grid item>
									<Button variant="contained" color="secondary" onClick={this.onClose}>
										Cancel
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid> :
					<Grid container component="form" onSubmit={ev => this.onReset(ev)}>
						{resetting ? 
							<Grid item xs={12} style={{textAlign:"center", margin: "20px 0"}}>
								<CircularProgress></CircularProgress>
							</Grid> :
							<React.Fragment>
								{resetError && <Grid item xs={12}>
									<Typography className={classes.resetError} color="error">Please make sure both passwords match</Typography>
								</Grid>}

								<Grid item xs={12} className={classes.drawerField}>
									<TextField name="NewPassword" type="password" autoComplete="off" required fullWidth label="New password" placeholder="1234" variant="outlined" onChange={this.onChangeReset} error={newPasswordError} helperText={newPasswordError && "Must be 4 characters long"} />
								</Grid>

								<Grid item xs={12} className={classes.drawerField}>
									<TextField name="OldPassword" type="password" autoComplete="off" required fullWidth label="Confirm new password" placeholder="1234" variant="outlined" onChange={this.onChangeReset} error={confirmPasswordError} helperText={confirmPasswordError && "Must be 4 characters long"} />
								</Grid>

								<Grid item xs={12} className={classes.drawerField}>
									<Grid container justify="space-between">
										<Grid item>
											<Button type="submit" variant="contained" color="primary">
												Reset
											</Button>
										</Grid>
										
										<Grid item>
											<Button variant="contained" color="secondary" onClick={this.onClose}>
												Cancel
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</React.Fragment>}
					</Grid>}
				</div>
			</Drawer>
		);
	}
}

EditDrawer.propTypes = {
    user: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    classes: PropTypes.object
}

//Move everything in to one place for sorting, paging and filtering the users
function sortPageFilter(users, state) {
    const { sortColumn, sortDirection, rowsPerPage, page, searchPhrase } = state;

    return users
    .map((user, index) => [user, index])//Retain index
    .sort((a, b) => {
        var order = (a[0][sortColumn] > b[0][sortColumn] ? 1 : -1) * (sortDirection === "desc" ? -1 : 1);

        if(order !== 0) return order;

        //Use index instead
        return a[1] - b[1];
    })
    .map(a => a[0])//Map back
    .filter(user => {
        for(var k in user)
			if(user[k] && user[k].toString().toLowerCase().indexOf(searchPhrase.toLowerCase()) !== -1)
                return true;
        return false;
    })
    .slice(rowsPerPage * page, (rowsPerPage * page) + rowsPerPage);
}

class CashierUsersComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            selected: [],
            sortColumn: "Name",
            sortDirection: "desc",
            rowsPerPage: 25,
            page: 0,
            showDeleteDialog: false,
            onDeleteDialogAccept: null,
			showEditDrawer: false,
			showResetDrawer: false,
            editingUser: null,
            showSearch: false,
            showMenu: false,
            menuAnchorElement: null,
            searchPhrase: ""
        };
    }

    componentDidMount() {
        this.props.updateTitle("Cashier Users Management");
		this.props.getUsers();
		this.props.getStores();
    }

    //Checkbox selection
    onSelectAll = (event) => {
        const { users } = this.props;

        if(event.target.checked) {
            this.setState({ selected: users.map(user => user.CashierUserId) });
            return;
        }

        this.setState({ selected: [] });
    }
    
    onSelect = (ev, user) => {
        const { selected } = this.state;

        if(ev.target.checked) {
            this.setState({
                selected: [...selected, user.CashierUserId]
            });
            return;
        }

        //Deselect by removing the userid from the array
        selected.splice(selected.indexOf(user.CashierUserId), 1);
        this.setState(this.state);
    }

    //Sorting
    onSort = (column) => {
        const { sortColumn, sortDirection } = this.state;

        this.setState({
            sortColumn: column.col,
            //Only change the direction of the column if it's the current sorting col
            sortDirection: sortColumn === column.col && sortDirection === "desc" ? "asc" : "desc"
        });
    }
    
    //Paging
    onChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0
        });
    }

    onChangePage = (event, newPage) => {
        this.setState({page: newPage});
    }

    //User actions
    onUserEdit = (user) => {
        //Open the edit drawer and create a clone of the user being edited
        this.setState({ showEditDrawer: true, editingUser: Object.assign({}, user) });
	}

	onUserReset = user => {
        this.setState({ showResetDrawer: true, editingUser: Object.assign({}, user) });
	}
	
	onNewUser = () => {
        this.setState({ showEditDrawer: true, editingUser: null });
	}

    onUserDelete = (user) => {
        this.setState({ showDeleteDialog: true, onDeleteDialogAccept: (confirmed) => {
            if(confirmed)
                this.props.deleteUser(user);
        }});
    }

    //Close delete dialog
    onCloseDialog = () => {
        this.setState({ showDeleteDialog: false });
    }

    //Edit drawer
    onDrawerClose = () => {
		this.props.clearReset();
        //Was setting editingUser to null but causes text etc to dissapear when drawer animates when closing
        this.setState({ showEditDrawer: false, showResetDrawer: false });
    }

    onDrawerSave = (ev, user) => {
		ev.preventDefault();

		if(user.CashierUserId)
			this.props.editUser(user);
		else
			this.props.addUser(user);

        this.onDrawerClose();
	}
	
	onDrawerReset = (ev, user, newPass, confirm) => {
		ev.preventDefault();
		this.props.resetPassword(user, newPass, confirm);
        this.onDrawerClose();
	}

    //Options
    onSearchClick = () => {
        this.setState({ showSearch: !this.state.showSearch });
    }

    onMoreClick = (ev) => {
        this.setState({ showMenu: !this.state.showMenu, menuAnchorElement: ev.currentTarget });
    }

    onMenuItemClick = (type) => {
        var users = this.props.users.filter(user => this.state.selected.indexOf(user.CashierUserId) > -1).map(user => user.CashierUserId);

        //Don't bother
        if(!users.length)
            return;

        //Use duck types to determine what to do
        switch(type) {
            case types.DELETE_USER: {
                this.setState({ showDeleteDialog: true, onDeleteDialogAccept: (confirmed) => {
                    if(confirmed)
                        this.props.massDeleteUsers(users);
                }});
                break;
            }

            default:
                return;
        }

        this.setState({ showMenu: false });
    }

    render() {
        const { classes, users, loading, login, stores, resetting, resetError, addError } = this.props;
        const { sortColumn, sortDirection, rowsPerPage, page, showDeleteDialog, showEditDrawer, editingUser, showSearch, showMenu, menuAnchorElement, onDeleteDialogAccept, selected, showResetDrawer } = this.state;

        return (
            <div className={classes.root}>
                <Toolbar variant="dense">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12} md="auto">
							<Grid container spacing={2} alignItems="center">
								<Grid item xd="auto">
                                    <PageTitle/>
								</Grid>

								<Grid item xs>
									<Button variant="contained" color="primary" onClick={this.onNewUser}>Add Cashier</Button>
								</Grid>
							</Grid>
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <Grid container alignItems="baseline" justify="flex-end">
                                <Grid item xs="auto">
                                    <IconButton onClick={this.onSearchClick}>
                                        <SearchIcon/>
                                    </IconButton>
                                </Grid>

                                <Grid item xs className={classNames(classes.search, showSearch && classes.searchSlide)}>
                                    <TextField type="search" onChange={(ev) => { this.setState({ searchPhrase: ev.target.value }) }}></TextField>
                                </Grid>

                                <Grid item xs="auto">
                                    <IconButton onClick={this.onMoreClick}>
                                        <MoreVertIcon/>
                                    </IconButton>

                                    <Menu open={showMenu} anchorEl={menuAnchorElement} onClose={this.onMoreClick} keepMounted>
                                        <MenuItem onClick={() => this.onMenuItemClick(types.DELETE_USER)}>
                                            Delete
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox onChange={this.onSelectAll}></Checkbox>
                            </TableCell>

                            {userColumns.map(column => (
                                <TableCell key={column.col || column}>
                                    <TableSortLabel
                                        active={sortColumn === column.col && column.sort}
                                        direction={sortDirection}
                                        onClick={() => column.sort && this.onSort(column)}>
                                        {column.title || column.col || column}
                                    </TableSortLabel>
                                </TableCell>
                            ))}

                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell className={classes.loading} colSpan={userColumns.length+2}>
                                    <CircularProgress></CircularProgress>
                                </TableCell>
                            </TableRow>
                        ) : sortPageFilter(users, this.state).map(user => (
                            <UsersRow
                                key={user.CashierUserId}
                                state={this.state}
								user={user}
								stores={stores}
								isEditor={checkRole(login, "Editor")}
                                onSelect={(e) => this.onSelect(e, user)}
                                onEdit={() => this.onUserEdit(user)}
                                onReset={() => this.onUserReset(user)}
                                onDelete={() => this.onUserDelete(user)}
                                classes={classes}/>
                        ))}
                    </TableBody>
                </Table>

                <TablePagination component="div"
                    page={page}
                    count={users.length}
                    onChangePage={this.onChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[25, 50, 75, 100]}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}/>

                <DeleteDialog 
                    open={showDeleteDialog}
                    multiple={selected.length > 0}
                    onAccept={() => {
                        onDeleteDialogAccept(true);
                        this.onCloseDialog();
                    }}
                    onClose={this.onCloseDialog}/>

                <EditDrawer
                    user={editingUser}
                    open={showEditDrawer || showResetDrawer || resetError || addError}
                    onSave={this.onDrawerSave}
					onClose={this.onDrawerClose}
					onReset={this.onDrawerReset}
					stores={stores}
                    classes={classes}
					reset={showResetDrawer || resetError}
					resetting={resetting}
					resetError={resetError}
					addError={addError} />
            </div>
        )
    }
}

export default withStyles(styles)(CashierUsersComponent);