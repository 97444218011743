import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Accordion, AccordionSummary , AccordionDetails, Select, MenuItem, Grid, Typography, Button, TextField, Divider, IconButton } from "@material-ui/core";
import { Field } from "redux-form";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

const Stores = props => {
	const { handleSelect, stores, value, classes } = props;

	const renderSelect = selected => {
		var selectedNames = stores.filter(a => selected.indexOf(a.storeId) !== -1).map(m => m.name);
		return selected.length === 0 ? <em>Select multiple</em> : selectedNames.join(", ")
	};

	return (
		<Select displayEmpty onChange={handleSelect} value={value} style={{width: "100%"}} multiple renderValue={renderSelect} MenuProps={{ className: classes.menu }}>
			{stores.length === 0 ? 
				<MenuItem key="-1">Loading stores...</MenuItem> : stores.map(store => (
				<MenuItem key={store.storeId} value={store.storeId}>{`${store.storeNumber} - ${store.name}`}</MenuItem>
			))}
		</Select>
	);
}

class StoreGroupDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			group: {
				Name: "",
				Stores: []
			},
			groupStates: {}
		}
	}

	handleSelect = (event) => {
		var group = this.state.group;
		group.Stores = event.target.value;
		this.setState({ group });
	}

	handleName = (event) => {
		var group = this.state.group;
		group.Name = event.target.value;
		this.setState({ group });
	}

	render() {
		const { open, handleClose, stores, createGroup, removeGroup, updateGroup, classes, groups } = this.props;

		return (
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Store groups</DialogTitle>

				<DialogContent>
					<DialogContentText style={{marginBottom: 20}}>
						Select an existing store group or create a new one
					</DialogContentText>

					{groups.map(group => (
						<Accordion key={group.StoreGroupId}>
							<AccordionSummary  classes={{content: classes.expansionSummary}}>
								<Grid container alignItems="center">
									<Grid item xs>
										<Typography>{group.Name}</Typography>
									</Grid>

									<Grid item>
										{<IconButton onClick={() => removeGroup(group)}>
											<RemoveCircle style={{color: "#CC0000"}}></RemoveCircle>
										</IconButton>}
									</Grid>
								</Grid>
							</AccordionSummary>

							<AccordionDetails>
								<Grid container >
									<Grid item xs={12}>
										<Stores classes={classes} value={this.state.groupStates[group.StoreGroupId] || group.Stores} handleSelect={event => {
											const groupStates = this.state.groupStates;
											groupStates[group.StoreGroupId] = event.target.value;
											group.Stores = event.target.value;
											this.setState({ groupStates });
										}} stores={stores}></Stores>
									</Grid>

									<Grid item xs={12}>
										<Grid container justify="space-between">
											<Grid item>
												<Button variant="contained" onClick={() => updateGroup(group)}>Save</Button>
											</Grid>

											<Grid item>
												<Field name="InnerPromotion.Stores.StoreIds" component={props =>
													<Button variant="contained" color="primary" onClick={() => {
														props.input.onChange(group.Stores);
														handleClose();
													}}>Select</Button>
												} />
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
					))}

					<Divider style={{marginBottom: 20, marginTop: 20}} />

					<Grid container>
						<Grid item xs={12}>
							<Typography>Add a new store group</Typography>
						</Grid>

						<Grid item xs={12}>
							<TextField inputProps={{maxLength:200}} value={this.state.group.Name} onChange={this.handleName} style={{width: "100%"}} placeholder="Group name"></TextField>
						</Grid>

						<Grid item xs={12}>
							<Stores classes={classes} value={this.state.group.Stores} handleSelect={this.handleSelect} stores={stores}></Stores>
						</Grid>

						<Grid item>
							<Button variant="contained" onClick={() => {
								createGroup(this.state.group);
								this.setState({ group: {
									Name: "",
									Stores: []
								} });
							}}>Add Store Group</Button>
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default StoreGroupDialog;