import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    noDataChart: {
      opacity: "0.5",
      pointerEvents:"none"
    },
});

class AmItemsPerOrderChart extends React.Component {
    constructor(props) {
        super(props);
        am4core.useTheme(am4themes_animated);
    }
    componentDidMount() {
		const pieColour = "#527060"; // move to a common resource or whatever
		let chart = am4core.create("itemsperorderchartdiv", am4charts.PieChart);
		chart.data = this.props.data;
		let title = chart.titles.create();
		title.text = "Items per order";
		title.fontSize = 21;
		title.align = "left";
		title.marginBottom = 30;	
		if(this.props.data.length > 0) {
			chart.exporting.menu = new am4core.ExportMenu();	
			chart.exporting.filePrefix = title.text;	
		}		
		let pieSeries = chart.series.push(new am4charts.PieSeries());
		pieSeries.dataFields.value = "Total";
		pieSeries.dataFields.category = "Items";
		pieSeries.colors.list = [
			am4core.color(pieColour).lighten(-0.70),
			am4core.color(pieColour).lighten(-0.60),
			am4core.color(pieColour).lighten(-0.50),
			am4core.color(pieColour).lighten(-0.40),
			am4core.color(pieColour).lighten(-0.30),
			am4core.color(pieColour).lighten(-0.20),
			am4core.color(pieColour).lighten(-0.10),
			am4core.color(pieColour),
			am4core.color(pieColour).lighten(0.10),

			am4core.color(pieColour).lighten(0.20),

			am4core.color(pieColour).lighten(0.30),

			am4core.color(pieColour).lighten(0.40),

			am4core.color(pieColour).lighten(0.50),

			am4core.color(pieColour).lighten(0.60),

			am4core.color(pieColour).lighten(0.70),

		];
		this.chart = chart;
    }
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
	render() {
		const { data, classes } = this.props
		return (<div id="itemsperorderchartdiv" style={{ width: "100%", height: "500px" }} className={ classNames( data.length  === 0 ? classes.noDataChart : null )}></div>);
	}
}

export default withStyles(styles)(AmItemsPerOrderChart);