import Creators from "./actions";
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";

const storeDataReceivedAction = Creators.storeDataReceived;
const menuDataReceivedAction = Creators.menuDataReceived;
const orderDetailsReceivedAction = Creators.orderDetailsReceived;

const loadingMenuDataAction = Creators.loadingMenuData;
const loadingOrderDetailsAction = Creators.loadingOrderDetails;
const newMopOrderStateRequestedAction = Creators.newMopOrderStateRequested;
const setSkuStockAction = Creators.setSkuStock;
const setSkuRangeAction = Creators.setSkuRange;
const setRangeModeAction = Creators.setRangeMode;
const setStockModeAction = Creators.setStockMode;

const getStoreDetail = (storeNumber) => {
  return (dispatch, getState) => {
    //dispatch(requestPromotionsAction());
    new DuckApiCalls()
      .getData(`ops/getStore?storeNumber=${storeNumber}`)
      .then((response) => {
        if (response.status === 401) throw new UnauthorizedError(dispatch);

        return response.json().then((payload) => {
          dispatch(storeDataReceivedAction(payload));
        });
      })
      .catch(console.error);
  };
};

const getMenuData = (terminalIdentifier) => {
  return (dispatch, getState) => {
    dispatch(loadingMenuDataAction());
    new DuckApiCalls()
      .getData(`ops/getMenuData?tid=${terminalIdentifier}`)
      .then((response) => {
        if (response.status === 401) throw new UnauthorizedError(dispatch);

        return response.json().then((payload) => {
          dispatch(menuDataReceivedAction(payload, terminalIdentifier));
        });
      })
      .catch(console.error);
  };
};

const getOrderDetails = (storeNumber) => {
  return (dispatch, getState) => {
    //	dispatch(loadingOrderDetailsAction());
    new DuckApiCalls()
      .getData(`ops/getOrderDetails?storeNumber=${storeNumber}`)
      .then((response) => {
        if (response.status === 401) throw new UnauthorizedError(dispatch);

        return response.json().then((payload) => {
          dispatch(orderDetailsReceivedAction(payload, storeNumber));
        });
      })
      .catch(console.error);
  };
};

const setMopState = (storeNumber, newState) => {
  return (dispatch, getState) => {
    dispatch(newMopOrderStateRequestedAction(newState));
    new DuckApiCalls()
      .postData(`ops/setMopState`, {
        storeNumber: storeNumber,
        newState: newState,
      })
      .then((response) => {
        if (response.status === 401) throw new UnauthorizedError(dispatch);

        return response.json().then((payload) => {
          dispatch(storeDataReceivedAction(payload));
        });
      })
      .catch(console.error);
  };
};

const setSkuStock = (sku, stocked) => {
  return (dispatch, getState) => {
    var state = getState();

	let terminalIdentifier = state.ops.terminalIdentifier;
	
    if (!state.ops.rangeMode) {
      dispatch(setSkuStockAction(sku, stocked));
      new DuckApiCalls()
        .postData(`ops/setStockState`, {
          terminalIdentifier: terminalIdentifier,
          sku: sku,
          stocked: stocked,
        })
        .then((response) => {
          if (response.status === 401) throw new UnauthorizedError(dispatch);

          return response.json().then((payload) => {
            console.log("State changed");
          });
        })
        .catch(console.error);
    } else {
		dispatch(setSkuRangeAction(sku, stocked));
		new DuckApiCalls()
		  .postData(`ops/setRangeState`, {
			terminalIdentifier: terminalIdentifier,
			sku: sku,
			stocked: stocked,
		  })
		  .then((response) => {
			if (response.status === 401) throw new UnauthorizedError(dispatch);
  
			return response.json().then((payload) => {
			  console.log("State changed");
			});
		  })
		  .catch(console.error);
	  }
  };
};

const setRangeMode = () => {
  return (dispatch, getState) => {
    dispatch(setRangeModeAction());
  };
};

const setStockMode = () => {
  return (dispatch, getState) => {
    dispatch(setStockModeAction());
  };
};

export default {
  getStoreDetail,
  setMopState,
  getMenuData,
  setSkuStock,
  getOrderDetails,
  setRangeMode,
  setStockMode,
};
