import { connect } from 'react-redux';
import WallboardReportComponent from './WallboardReportComponent';
import { wallboardReportOperations } from './duck';
import { dashboardOperations } from '../../../duck';

const mapStateToProps = (state) => {
    return {
        ...state,
        login: state.login
    }
};

const mapDispatchToProps = (dispatch) => {
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
    const getWallboardStats = (onDate) => dispatch(wallboardReportOperations.getWallboardStats(onDate));
    return {
        updateTitle, 
        getWallboardStats
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WallboardReportComponent)