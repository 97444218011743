import React from "react";
import { Switch, Route } from "react-router";
import ReportsComponent from "./ReportsComponent";
import ReportsPage from "./ReportsPage";

class ReportsContainer extends React.Component {
	render() {
		return (
			<Switch>
				<Route exact path="/reports" component={ReportsComponent} />
				<Route path="/reports/:report" component={ReportsPage} />
			</Switch>
		)
	}
}

export default ReportsContainer;