import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    noDataSimpleStackedBarChart: {
      opacity: "0.5",
      pointerEvents:"none"
    },
});

class RpSimpleStackedBarChart extends React.Component {
    render() {
        const { heading, data, captionkey, valuekey, format, reverseorder, sortkey, classes, tableHeight} = this.props;
        const barHeight = 30;
        const rowPadding = 5;
        const isPopulated = data.length > 0;
        let sk = valuekey;
        if(sortkey !== undefined)
            sk = sortkey;
        // only supporting numeric values atm
        let vals = data.sort((a,b) => { 
            return a[sk] - b[sk]; 
        });        
        if(!reverseorder)
            vals = vals.reverse();
        const maxValue = Math.max.apply(Math, vals.map(function(o) { return o[valuekey]; }));
        return(
            <div style={{ width: "95%" }} className={ classNames( data.length  === 0 ? classes.noDataSimpleStackedBarChart : null )}>
            <div className={classes.chartTitle}>{heading}</div>
                <div style={{ 
                    width: "100%",
                    height: tableHeight + "px", overflowY: "auto" }}>
                    { isPopulated ? (<table cellSpacing={rowPadding} style={{ width: "100%"}}>
                    <tbody>
        { vals.map((item, index) => (
            <tr key={index}>
                <td width="25%" style={{ textAlign: "left" }}>{item[captionkey]}</td>
                <td style={{ height: "1px"}}>
                    <div style={{ fontWeight: "bold", color: "#ffc20e", lineHeight: (barHeight - 3) + "px",width: ((item[valuekey]/maxValue)*100) + "%" , backgroundColor: "#527060", textAlign: "center", height: barHeight + "px" }}>
                    {format === undefined ? item[valuekey] : format(item[valuekey])}
                    </div>
                </td>
            </tr>)) }
            </tbody>
            </table>):(<div style={{ textAlign: "center", lineHeight: tableHeight + "px", color: "#000"}}>No data.</div>)}
            </div>
            </div>
        ) 
    }
}

export default withStyles(styles)(RpSimpleStackedBarChart);