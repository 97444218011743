import React from 'react';
import { withStyles } from '@material-ui/core';
import { Grid, CircularProgress, Toolbar, Paper } from '@material-ui/core';
import AmBasketValuesByDayChart from '../OrdersReport/AmBasketValuesByDayChart';
import AmBasketValuesByHourChart from '../OrdersReport/AmBasketValuesByHourChart';
import DatePicker from 'react-datepicker';
import ReportHelpers from '../ReportHelpers';
import RpSimpleStackedBarChart from '../components/RpSimpleStackedBarChart'
import moment from 'moment';
import DatePickerInput from '../DatePickerHelper';
import PageTitle from '../../../PageTitle';

const styles = theme => ({
    root: {
		padding: `${theme.spacing() * 0}px ${theme.spacing() * 3}px`,
		minWidth: "1300px"
	},
	reportArea: {
		paddingTop:`${theme.spacing() * 4}px`,
		paddingLeft: `${theme.spacing() * 3}px`,
		paddingRight: `${theme.spacing() * 3}px`,
	},
	paper: {
		padding: `${theme.spacing() * 3}px ${theme.spacing() * 2}px`,
		height: "100%",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column"
	},
	paperValues: {
		padding: `${theme.spacing() * 3}px ${theme.spacing() * 2}px`,
		height: "100%",
		textAlign: "left",
		display: "flex",
		justifyContent: "left",
		alignItems: "left",
		flexDirection: "column"
	},
    title: {
        fontSize: "20px",
        color: "#999999"
	},
	toFromField: {
		textAlign: "right",
		color: "#999",
		fontWeight: "bold"
	},
	toFromFieldCaption: {
		marginRight: `${theme.spacing() * 2}px`
	},
	tableHeading: {
		marginLeft: theme.spacing() * 3
	},
    pointerCursor: {
        cursor: "pointer"
	},
	hidden: {
		display:"none"
	},
	stopClicks: {
		pointerEvents:"none"
	},
	valueHeading: {
		fontSize:"1.5rem"
	},
	chartTitle: {
		fontSize:"1.31rem",
		marginTop: "8px",
		marginBottom: "15px",
		textAlign: "left"
	},
	valueValue: {
		fontSize:"3.5rem",
		fontWeight:"bold"
	},
	wrapper: {
		width:"100%",
		height: "600px",
		overflowY: "auto",		
		overflowX: "auto"
	},    
	loading: {
        textAlign: "center",
        padding: theme.spacing() * 2
    }, 
});

class TouchScreenOrdersReportComponent extends React.Component {
	constructor(props) {
		super(props);	
		let dt = new Date();
		let ed = moment(dt).toDate();
		let sd =  moment(dt).subtract(7, "days").toDate();
		let md = moment(dt).subtract(3, "months").toDate();
		let xd = dt;
		this.state = { 
			maxDate: xd,
			minDate: md,
			startDate: sd, 
			endDate: ed,
		};
	}

	componentDidMount() {
		this.props.updateTitle("Touch Screen Orders");
		this.props.getTouchScreenOrdersStats(this.state.startDate,this.state.endDate);
	}

	setEndDate = (date) => {
		this.setState({endDate: date});
		this.update(this.state.startDate,date);
	}
	setStartDate = (date) => {
		this.setState({startDate: date});
		this.update(date,this.state.endDate);
	}
	update = (start, end) => {
		this.props.getTouchScreenOrdersStats(start,end);
		//console.log("UPDATE: from = " + start + ", to = " + end);
	}
	render() {
		const { classes } = this.props;
		const { touchScreenOrders, loading } = this.props.touchScreenOrdersReport;
		const { startDate, endDate, minDate, maxDate } = this.state;
		return (
            <div className={classes.root}>
			    <Toolbar variant="dense">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={8}>
                           <PageTitle/>
                        </Grid>
						<Grid item xs={4}>
							<Grid container alignItems="center">

								<Grid xs={2}></Grid>

								<Grid item xs={5} className={classes.toFromField}>
									<span className={classes.toFromFieldCaption}>From</span>
									<DatePicker 
										selected={startDate}
										onChange={date => this.setStartDate(date)}
										selectsStart
										startDate={startDate}
										minDate={minDate}
										maxDate={maxDate}
										endDate={endDate}
										dateFormat="dd/MM/yyyy"
										popperPlacement="bottom-end"
										showPopperArrow={false}
										customInput={<DatePickerInput />}
										/>
								</Grid>
								<Grid item xs={5} className={classes.toFromField}>
									<span className={classes.toFromFieldCaption}>To</span>
									<DatePicker
										popperPlacement="bottom-end"
										selected={endDate}
										onChange={date => this.setEndDate(date)}
										selectsEnd
										startDate={startDate}
										endDate={endDate}
										minDate={startDate}
										maxDate={maxDate}
										dateFormat="dd/MM/yyyy"
										showPopperArrow={false}
										customInput={<DatePickerInput />}
										/>
                    			</Grid>
							</Grid>
						</Grid>
                    </Grid>
                </Toolbar>
				{loading? (
					<Grid container className={classes.reportArea} >
						<Grid item xs={12} className={classes.loading}>
							<CircularProgress/>
						</Grid>
					</Grid>
				):(
					<Grid container className={classes.reportArea} spacing={16}>
					<Grid item xs={4}>
						<Paper className={classes.paperValues}>
							<div className={classes.valueHeading}>Number of orders</div>
							<div className={classes.valueValue}>{Math.round(touchScreenOrders.Overall.TotalBaskets)}</div>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.paperValues}>
							<div className={classes.valueHeading}>Orders Total</div>
							<div className={classes.valueValue}>{ReportHelpers.formatCurrency(touchScreenOrders.Overall.BasketsTotal)}</div>	
						</Paper>
					</Grid>
					<Grid item xs={4}>					
						<Paper className={classes.paperValues}>
							<div className={classes.valueHeading}>Average order value</div>
							<div className={classes.valueValue}>{ReportHelpers.formatCurrency(touchScreenOrders.Overall.AverageBasketTotal)}</div>						
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper className={classes.paper}>
						<RpSimpleStackedBarChart
								classes={classes}
								heading="Orders by Store"
								tableHeight={430}
								data={touchScreenOrders.ByStore} 
								captionkey="StoreName" 
								valuekey="Total" 
								sortkey="Total"/>
						</Paper>					
					</Grid>	
					<Grid item xs={6}>
						<Paper className={classes.paper}>
							<AmBasketValuesByDayChart data={touchScreenOrders.ByDay}/>	
						</Paper>					
					</Grid>	
					<Grid item xs={6}>
						<Paper className={classes.paper}>
							<AmBasketValuesByHourChart data={touchScreenOrders.ByHour}/>	
						</Paper>					
					</Grid>	
					<Grid item xs={12} style={{color:"#CCC"}} className={classes.hidden}>
						{touchScreenOrders.DbMs}ms
					</Grid>	
				</Grid>)}
			</div>
		)
	}
}

export default withStyles(styles)(TouchScreenOrdersReportComponent);