import { combineReducers } from "redux";
import dashboardReducer from "./duck/reducers";
import menuReducer from "./pages/menusPage/duck/reducers";
import usersReducer from "./pages/usersPage/duck/reducers"
import opsReducer from "./pages/opsDashboardPage/duck/reducers"
import loginReducer from "./pages/loginPage/duck/reducers"
import reportsReducer from "./pages/reportsPage/duck/reducers"
import accountReducer from "./pages/accountPage/duck/reducers"
import terminalStatusesReducer from "./pages/terminalsPage/duck/reducers"
import outOfStockReportReducer from "./pages/nativeReportsPage/OutOfStockReport/duck/reducers"
import itemsReportReducer from "./pages/nativeReportsPage/ItemsReport/duck/reducers"
import touchScreenOrdersReportReducer from "./pages/nativeReportsPage/TouchScreenOrdersReport/duck/reducers"
import estateEventsReportReducer from "./pages/nativeReportsPage/EstateEventsReport/duck/reducers"
import ordersReportReducer from "./pages/nativeReportsPage/OrdersReport/duck/reducers"
import basketsReportReducer from "./pages/nativeReportsPage/BasketsReport/duck/reducers"
import wallboardReportReducer from "./pages/nativeReportsPage/WallboardReport/duck/reducers"
import kioskMonitoringReportReducer from "./pages/nativeReportsPage/KioskMonitoringReport/duck/reducers"
import promotionsReducer from "./pages/promotionsPage/duck/reducers"
import cashierUsersReducer from "./pages/cashierUsersPage/duck/reducers"
import screenshotsReducer from "./pages/screenshotsPage/duck/reducers"
import kitchenFlowReducer from "./pages/kitchenFlowsPage/duck/reducers"

import { reducer as formReducer } from "redux-form"

const rootReducer = combineReducers({
  menus: menuReducer,
  dashboard: dashboardReducer,
  users: usersReducer,
  login: loginReducer,
  reports: reportsReducer,
  account: accountReducer,
  terminalStatuses: terminalStatusesReducer,
  touchScreenOrdersReport: touchScreenOrdersReportReducer,
  itemsReport: itemsReportReducer,
  outOfStockReport: outOfStockReportReducer,
  estateEventsReport: estateEventsReportReducer,
  ordersReport: ordersReportReducer, 
  basketsReport: basketsReportReducer,
  wallboardReport: wallboardReportReducer,
  kioskMonitoringReport: kioskMonitoringReportReducer,
  promotions: promotionsReducer,
  form: formReducer,
  screenshots: screenshotsReducer,
  cashier: cashierUsersReducer,
  ops:opsReducer,
  kitchenFlows: kitchenFlowReducer
});

export default rootReducer;
