const RECEIVE_USERS = "RECEIVE_USERS";
const REQUEST_USERS = "REQUEST_USERS";
const DELETE_USER = "DELETE_USER";
const SUSPEND_USER = "SUSPEND_USER";
const UNSUSPEND_USER = "UNSUSPEND_USER";
const EDIT_USER = "EDIT_USER";
const MASS_SUSPEND_USERS = "MASS_SUSPEND_USERS";
const MASS_DELETE_USERS = "MASS_DELETE_USERS";
const GET_ROLES = "GET_ROLES";
const NEW_USER = "NEW_USER";
const CREATING_OPS = "CREATING_OPS";
const CREATED_OPS = "CREATED_OPS";

export default {
    RECEIVE_USERS,
    REQUEST_USERS,
    DELETE_USER,
    SUSPEND_USER,
    UNSUSPEND_USER,
    EDIT_USER,
    MASS_SUSPEND_USERS,
	MASS_DELETE_USERS,
	GET_ROLES,
    NEW_USER,
    CREATING_OPS,
	CREATED_OPS
}