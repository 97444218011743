import { connect } from 'react-redux'
import KitchenFlowManagementComponent from './KitchenFlowManagementComponent';
import { kitchenFlowOperations } from './duck';
import { dashboardOperations } from '../../duck'

const mapStateToProps = (state)  => {
	return {
		...state.kitchenFlows
	}
};

const mapDispatchToProps = (dispatch) => {
	const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
	const changeTab = (tabIndex) => dispatch(kitchenFlowOperations.changeTab(tabIndex));

	return {
        updateTitle,
		changeTab
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(KitchenFlowManagementComponent)