// DashboardComponent.jsx

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing() * 2,
    margin:theme.spacing() * 1,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  progressWrapper: {
    padding: theme.spacing() * 2
  }
});

class MenusComponent extends React.Component {

  componentDidMount() {
    this.props.onLoadMenusJson();
    this.props.updateTitle("Menus");
  }

  render() { 
    const { classes, menus, loading } = this.props;

    return (
      <div className={classes.root}>
          <Grid container spacing={0}>
              <Grid item xs={2}>
                  {loading ? (
                    <Grid justify="center" container>
                      <Grid item className={classes.progressWrapper}>
                        <CircularProgress></CircularProgress>
                      </Grid>
                    </Grid>
                  ) : menus.map((item, i) => (
                    <Paper className={classes.paper}>{item.Description}</Paper>
                ))}
              </Grid>

              <Grid item xs={2}>
                  <Paper className={classes.paper}>xs=6</Paper>
              </Grid>

              <Grid item xs={2}>
                  <Paper className={classes.paper}>xs=3</Paper>
              </Grid>

              <Grid item xs={2}>
                  <Paper className={classes.paper}>xs=3</Paper>
              </Grid>

              <Grid item xs={2}>
                  <Paper className={classes.paper}>xs=3</Paper>
              </Grid>

              <Grid item xs={2}>
                  <Paper className={classes.paper}>xs=3</Paper>
              </Grid>
          </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(MenusComponent);

