const LOGIN_USER = "LOGIN_USER";
const LOGGING_IN_USER = "LOGGING_IN_USER";
const LOGOUT_USER = "LOGOUT_USER";
const CHECK_USER = "CHECK_USER";
const UPDATE_USER = "UPDATE_USER";


export default {
    LOGIN_USER,
    LOGGING_IN_USER,
	LOGOUT_USER,
	CHECK_USER,
	UPDATE_USER

}