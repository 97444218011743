import { connect } from 'react-redux';
import OrdersReportComponent from './OrdersReportComponent';
import { ordersReportOperations } from './duck';
import { dashboardOperations } from '../../../duck';

const mapStateToProps = (state) => {
    return {
        ...state,
        login: state.login
    }
};

const mapDispatchToProps = (dispatch) => {
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
    const getOrdersStats = (dateFrom, dateTo) => dispatch(ordersReportOperations.getOrdersStats(dateFrom, dateTo));
    return {
        updateTitle, 
        getOrdersStats
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersReportComponent)