import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import SearchIcon from '@material-ui/icons/Search';
import { Typography, TableHead, TableRow, TableCell, Table, TableBody, TableSortLabel, IconButton,TextField,
    withStyles, CircularProgress, Drawer, Toolbar, Grid, AppBar, Button, MenuItem, Select } from '@material-ui/core';
import classNames from 'classnames';
import PageTitle from '../../PageTitle';
import { checkRole } from "../../utils/userUtils";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const terminalColumns = [
    { col: "TerminalName", title: "Name", isSortable: true }, 
    { col: "StoreName", title: "Store", isSortable: true }, 
    { col: "StoreIsOpen", isTrafficLight: true, TrafficLightMode: "alt", title: "Store Open", isSortable: true },
    { col: "FaultKioskPrinter", isTrafficLight: true, title: "Printer", isSortable: true },
    { col: "FaultKioskPaper", isTrafficLight: true, title: "Paper", isSortable: true },
    //{ col: "FaultLogwood", isTrafficLight: true, title: "Logwood", isSortable: true },
    { col: "FaultScanner", isTrafficLight: true, title: "Scanner", isSortable: true },
    { col: "FaultPED", isTrafficLight: true, title: "PED", isSortable: true },
    { col: "FaultLineDisplay", isTrafficLight: true, title: "Line", isSortable: true },
    { col: "FaultCashDrawer", isTrafficLight: true, title: "Drawer", isSortable: true },
    { col: "FaultScannerMessage", title: "Scanner Status", isSortable: true }, 
    { col: "FaultPEDMessage", title: "PED Status", isSortable: true }, 
    { col: "FaultPrinterMessage", title: "Printer Status", isSortable: true }, 
    { col: "LastReportedIn", isSince: true, isSortable: true, title: "Last Reported In"}, 
    { col: "IPAddress", title: "IP Address", isHidden: true }, 
    { col: "PhoneNumber", title: "Phone", isHidden: true }
];

const styles = theme => ({
    root: {
        //padding: theme.spacing.unit * 3,
        //minWidth: "1300px"
    },
    title: {
        fontSize: "20px",
        color: "#999999"
    },
    drawerTitle: {
        fontSize: "20px",
        color: theme.palette.common.white
    },
    loading: {
        textAlign: "center",
        padding: theme.spacing() * 2
    },  
    sticky: {
        left: 0,
        top: 0,
        zIndex: 2,
        position: "sticky"
    },
    tableWrapper: {
        maxHeight: 440,
        overflow: "auto"
    },
    search: {
        maxWidth: "0",
        transition: "max-width 0.5s ease"
    },
    pointerCursor: {
        cursor: "pointer"
    },
    trafficLightCol: {
        width: 70,
        paddingLeft: 0,
        paddingRight: 5
    },
    searchSlide: {
        maxWidth: "100%"
    },
    redLight: {backgroundColor: "red", width: "65px", height: "24px"}, 
    greenLight: {backgroundColor: "green", width: "65px", height: "24px"}, 
    amberLight: {backgroundColor: "#fc0", width: "65px", height: "24px"}, 
});

const InfoCell = (props) => {
    const {terminal, column, classes } = props;
	var data = terminal[column.col || column];
    if (column.isTrafficLight === true)
        return (<TrafficLight item={data} classes={classes} mode={column.TrafficLightMode}/>);
    else if (column.isSince === true)
        return(moment(data).fromNow());
    else
        return(data);
}

const TrafficLight = (props) => {   
    const {classes, item, mode } = props;
    if(mode === "alt") {
        if(item === true)
            return (<div className={classes.greenLight}></div>)
        else //if(item === false)
            return (<div className={classes.amberLight}></div>)
    }
    else {
        if(item === true)
            return (<div className={classes.redLight}></div>)
        else if(item === false)
            return (<div className={classes.greenLight}></div>)
        else 
            return (<div className={classes.amberLight}></div>)
    }
}

TrafficLight.propTypes = {
    classes: PropTypes.object
}

function filterHidden(c) {
    return c.isHidden === true ? false: true; 
}

const TerminalRow = (props) => {
    const { classes,terminal,onClick,rowSelected } = props;
    return (<TableRow onClick={onClick} selected={rowSelected} className={classes.pointerCursor}>
            {terminalColumns.filter((c) => filterHidden(c)).map((column) => (
				<TableCell key={column.col || column} className={column.isTrafficLight === true ? classes.trafficLightCol : ""}>
					<InfoCell terminal={terminal} column={column} classes={classes}/>
				</TableCell>
            ))}
        </TableRow>);
}

TerminalRow.propTypes = {
    classes: PropTypes.object
}

const ExtendedTerminalDetails = (props) => {
    const { classes,terminal, login } = props;

    let createTicketUrl = null;

    // hard code this for now
    if(terminal.JiraTicketTitle.length > 0 && checkRole(login, "Editor")) {
        const projectId = 16613;
        const issueType = 6;
        const defaultResolution = 10200 // "unresolved"
        createTicketUrl = "https://hwdevelopment.atlassian.net/secure/CreateIssueDetails!init.jspa?resolution=" + defaultResolution + "&issuetype=" + issueType + "&pid=" + projectId + "&summary=" + encodeURIComponent(terminal.JiraTicketTitle);
    }

    if(terminal != null)
        return (<div>
                <AppBar position="static">
                    <Toolbar>
                    <Typography component="p" className={classes.drawerTitle}>
                            Information
                        </Typography>
                    </Toolbar>
                </AppBar>
                 <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>IP Address</TableCell>
                        <TableCell>{terminal.IPAddress}
                            <CopyToClipboard text={terminal.IPAddress}>
                                <IconButton style={{ margin: "-2px 5px" }}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    style={{ fill: "#999" }}
                                    width="16" height="16" viewBox="0 0 24 24">
                                        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
                                </IconButton>
                            </CopyToClipboard>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Phone</TableCell>
                        <TableCell>{terminal.PhoneNumber}</TableCell>
                    </TableRow>
                    {createTicketUrl != null ? (<TableRow>
                        <TableCell colSpan={2}><Button variant="contained" color="primary" target="_blank" href={createTicketUrl}>Create Ticket</Button></TableCell>
                    </TableRow>):(null)}
                </TableBody>
            </Table>            
            </div>);
}

function sortAndFilter(items, state) {
    const { sortColumn, sortDirection, searchPhrase } = state;
    return items
    .map((item, index) => [item, index])
   .sort((a, b) => {
        var order = (a[0][sortColumn] > b[0][sortColumn] ? 1 : -1) * (sortDirection === "desc" ? -1 : 1);
        if(order !== 0) return order;
        return a[1] - b[1];
    })
    .map(a => a[0])
    .filter(item => {
        if(searchPhrase.length > 0) {
            for(var k in item) {
                if(item[k] != null) {
                    if(item[k].toString().toLowerCase().includes(searchPhrase.toLowerCase())) {
                        //console.log("HIT: \"" + item[k].toString() + "\", \"" + searchPhrase);  
                        return true;
                    }
                }
            }        
            return false;
        }
        else
            return true;
    });
}

class TerminalsComponent extends React.Component {
	constructor(props) {
        super(props);
        this.state = { 
            drawerOpen: false, 
            drawerItem: null,            
            sortColumn: "StoreName",
            sortDirection: "desc",
            showSearch: false,           
            searchPhrase: "",
            statusFilter: "Bad"
        };
    }	

    onSort = (column) => {
        const { sortColumn, sortDirection } = this.state;
        //console.log("onSort: " + column.col);
        this.setState({
            sortColumn: column.col,
            sortDirection: sortColumn === column.col && sortDirection === "desc" ? "asc" : "desc"
        });
    }

    toggleDrawer = () => {
        this.setState({ drawerOpen: !this.state.drawerOpen});
    };
    
    itemClick = (selectedItem) => {
        this.setState({ drawerOpen: true, drawerItem: selectedItem });       
    };

    onSearchClick = () => {
        this.setState({ showSearch: !this.state.showSearch });
    }

    handleSelectChange = event => {
        this.setState({statusFilter: event.target.value});
        console.log("MENU: " + event.target.value);
        this.props.getTerminalStatuses(event.target.value);
    };

    componentDidMount() {
        this.props.updateTitle("Status");
        this.props.getTerminalStatuses(this.state.statusFilter);
    }

    componentWillUnmount() {
    }

    render() {
        let cnt = 0;
        const { classes, login } = this.props;
        const { loading , terminals } = this.props.terminalStatuses;
        const { sortColumn, sortDirection, showSearch, statusFilter} = this.state;

        return (
            <div className={classes.root}>
                <Toolbar variant="dense">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12} md="auto">
                            <PageTitle/>
                        </Grid>
                        <Grid item xs={12} md="auto">
                            <Grid container alignItems="baseline" justify="flex-end">
                                <Grid item xs="auto">
                                    <IconButton onClick={this.onSearchClick}>
                                        <SearchIcon/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs className={classNames(classes.search, showSearch && classes.searchSlide)}>
                                    <TextField type="search" onChange={(ev) => { this.setState({ searchPhrase: ev.target.value }) }}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="baseline">
                            <Grid item xs={12} md="auto">
                            <Select
	                            value={statusFilter}
	                            onChange={this.handleSelectChange}>
                                    <MenuItem value={"All"}>Show All</MenuItem>
                                    <MenuItem value={"Bad"}>Show issues</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>

				<Table>            
					<TableHead>
						<TableRow>
						{terminalColumns.filter((c) => filterHidden(c)).map(column => (
							<TableCell className={column.isTrafficLight === true ? classes.trafficLightCol : ""} key={column.col || column}>
								<TableSortLabel active={sortColumn === column.col && column.isSortable}
									direction={sortDirection}
									onClick={() => column.isSortable && this.onSort(column)}>
										{column.title || column.col || column}
								</TableSortLabel>
							</TableCell>))}
						</TableRow>
					</TableHead>

					<TableBody>
						{loading ? (
							<TableRow>
								<TableCell className={classes.loading} colSpan={terminalColumns.length}>
									<CircularProgress/>
								</TableCell>
							</TableRow>
						):(
							sortAndFilter(terminals, this.state).map(t => {
								cnt++;
								return (<TerminalRow key={t.TerminalName} terminal={t} 
									classes={classes} onClick={() => this.itemClick(t)}/>)  
							})
						)}

						{cnt === 0 && loading === false ? (
							<TableRow>
								<TableCell colSpan={terminalColumns.length}>Nothing to see.</TableCell>
							</TableRow>
						) : (null)}
					</TableBody>
                </Table>

                <Drawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer}>
                    <div
                        role="presentation"
                        style={{ width: 350 }}
                        onClick={this.toggleDrawer}>

                        <ExtendedTerminalDetails
							classes={classes} 
                        	login={login}
                             terminal={this.state.drawerItem}/>
                    </div>
                </Drawer>
            </div>);             
    }
}

export default withStyles(styles)(TerminalsComponent);