// actions.js
import types from './types.js';

const requestScreenshotsJson = (returnUrl) => ({
    type: types.REQUEST_SCREENSHOTS_JSON
});

const receiveScreenshotsJson = (data) => ({
    type: types.RECEIVE_SCREENSHOTS_JSON,
    data: data
});

export default {
    requestScreenshotsJson,
    receiveScreenshotsJson
}