import { connect } from 'react-redux';
import KioskMonitoringReportComponent from './KioskMonitoringReportComponent';
//import { kioskMonitoringOperations } from './duck';
import { dashboardOperations } from '../../../duck';

const mapStateToProps = (state) => {
    return {
        ...state,
        login: state.login
    }
};

const mapDispatchToProps = (dispatch) => {
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
    return {
        updateTitle
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(KioskMonitoringReportComponent)