import React from "react";
import { connect } from "react-redux";
import { withStyles, IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from "react-router";

const styles = theme => ({
    title: {
        fontSize: "1.75rem",
        color: "#999999",
        marginTop: "-4px"
    },
    goBackLink: {
        cursor: "pointer",
        marginRight: "5px"
    },
    titleContainer: {
        display: "flex", 
        marginLeft: "-17px", 
        alignItems: "center"
    }
});

class PageTitle extends React.Component {
    render() {
    	const { classes, dashboard } = this.props;
        return(
            <div className={classes.titleContainer}>
                <div>
                    <IconButton className={classes.goBackLink} onClick={() => this.props.history.goBack()}>
                        <ArrowBackIcon/>
                    </IconButton>
                </div>
                <div className={classes.title}>
                    {dashboard.title}
                </div>
            </div>);
    }
}

export default withRouter(withStyles(styles)(connect(({ dashboard }) => { return { dashboard }})(PageTitle)));