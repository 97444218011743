import types from "./types"

const loginUser = (data) => ({
    type: types.LOGIN_USER,
    data: data
})

const loggingInUser = (data) => ({
    type: types.LOGGING_IN_USER,
    data: data
})

const logoutUser = () => ({
    type: types.LOGOUT_USER
})

const checkUser = (data) => ({
	type: types.CHECK_USER,
	data: data
})

const updateUser = (data) => ({
	type: types.UPDATE_USER,
	data: data
})

export default {
    loginUser,
    loggingInUser,
	logoutUser,
	checkUser,
	updateUser
}