import Creators from './actions';
import DuckApiCalls from "../../../../utils/duckApiCalls";
import UnauthorizedError from "../../../../utils/unauthorizedError";
import moment from 'moment';

const requestWallboardStatsAction = Creators.requestWallboardStatsActions;
const receiveWallboardStatsAction = Creators.receiveWallboardStatsActions;

const getWallboardStats = (onDate) => {
    return (dispatch, getState) => {
        dispatch(requestWallboardStatsAction({
            loading: true
        }));
        new DuckApiCalls().getData("reporting/wallboard?ondate=" + moment(onDate).format('DD-MM-YYYY')) 
        .then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
            return response.json()
            .then(payload => {
                dispatch(receiveWallboardStatsAction({
                    wallboard: payload,
                    loading: false
                }));
            });
        })
        .catch(console.error);
    };
};

export default {
    getWallboardStats
}