import types from "./types"

const storeDataReceived = (storeDetail) => ({
    type: types.STORE_DATA_RECEIVED,
    storeDetail: storeDetail
})


const newMopOrderStateRequested = (newState) => ({
    type: types.NEW_MOP_STATE_REQUESTED,
    newState: newState
})

const menuDataReceived = (menuData,terminalIdentifier) => ({
    type: types.MENU_DATA_RECEIVED,
    menuData: menuData,
    terminalIdentifier:terminalIdentifier
})

const orderDetailsReceived = (orderDetails,storeNumber) => ({
    type: types.ORDER_DETAILS_RECEIVED,
    orderDetails: orderDetails,
    storeNumber: storeNumber
})

const loadingMenuData = () => ({
    type: types.MENU_DATA_LOADING,
})

const loadingOrderDetails = () => ({
    type: types.ORDER_DETAILS_LOADING,
})



const setSkuStock = (sku,stocked) => ({
    type: types.SET_SKU_STOCK,
    sku:sku,
    stocked:stocked
})

const setSkuRange = (sku,stocked) => ({
    type: types.SET_SKU_RANGE,
    sku:sku,
    stocked:stocked
})

const setRangeMode= () => ({
    type: types.SET_RANGE_MODE
})


const setStockMode = () => ({
    type: types.SET_STOCK_MODE
})






export default {
    storeDataReceived,
    newMopOrderStateRequested,
    menuDataReceived,
    loadingMenuData,
    setSkuStock,
    setSkuRange,
    loadingOrderDetails,
    orderDetailsReceived,
    setRangeMode,
    setStockMode
}