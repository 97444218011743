import types from "./types"

const requestPromotions = () => ({
    type: types.REQUEST_PROMOTIONS
})

const receivePromotions = (data) => ({
    type: types.RECEIVE_PROMOTIONS,
    data
});

const updatePromotion = (data) => ({
	type: types.UPDATE_PROMOTION,
	data
});

const receivePromotion = (data, id) => ({
	type: types.RECEIVE_PROMOTION,
	data,
	id
})

const deletePromotion = (data) => ({
	type: types.DELETE_PROMOTION,
	data
})

const movePromotion = (data) => ({
	type: types.MOVE_PROMOTION,
	data
})

const createPromotion = data => ({
	type: types.CREATE_PROMOTION,
	data
})

const getCategories = (data) => ({
	type: types.GET_CATEGORIES,
	data
})

const getGroups = (data) => ({
	type: types.GET_GROUPS,
	data
})

const getStores = (data) => ({
	type: types.GET_STORES,
	data
})

const createGroup = (data) => ({
	type: types.CREATE_GROUP,
	data
})

const removeGroup = data => ({
	type: types.REMOVE_GROUP,
	data
})

const updateGroup = data => ({
	type: types.UPDATE_GROUP,
	data
})

export default {
	requestPromotions,
	receivePromotions,
	receivePromotion,
	updatePromotion,
	deletePromotion,
	movePromotion,
	getCategories,
	getStores,
	getGroups,
	createGroup,
	removeGroup,
	updateGroup,
	createPromotion
}