import Creators from './actions';
import DuckApiCalls from "../../../../utils/duckApiCalls";
import UnauthorizedError from "../../../../utils/unauthorizedError";
import moment from 'moment';

const requestOrdersStatsAction = Creators.requestOrdersStatsActions;
const receiveOrdersStatsAction = Creators.receiveOrdersStatsActions;

const getOrdersStats = (dateFrom, dateTo) => {
    return (dispatch, getState) => {
        dispatch(requestOrdersStatsAction({
            loading: true
        }));
        new DuckApiCalls().getData("reporting/orders?from=" + moment(dateFrom).format('DD-MM-YYYY') + "&to=" + moment(dateTo).format('DD-MM-YYYY')) 
        .then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
            return response.json()
            .then(payload => {
                dispatch(receiveOrdersStatsAction({
                    orders: payload,
                    loading: false
                }));
            });
        })
        .catch(console.error);
    };
};

export default {
    getOrdersStats
}