import types from "./types";

const INITIAL_STATE = {
  storeDetail: {},
  settingMopState: false,
  takingMOPOrdersAccepted:false,
  menuData:null,
  menuDataLoading:false,
  terminalIdentifier:null,
  orderDetails:null,
  orderDetailsLoading:true,
  rangeMode:false
};

const opsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.STORE_DATA_RECEIVED: {

      if (!action.storeDetail.NiceName) action.storeDetail.NiceName = action.storeDetail.name;
      return {
        ...state,
        storeDetail: action.storeDetail,
        settingMopState: false,
        takingMOPOrdersAccepted: action.storeDetail.TakingMOPOrders
      };
    }
    case types.MENU_DATA_RECEIVED: {
      return {
        ...state,
        menuData:action.menuData,
        menuDataLoading:false,
        terminalIdentifier:action.terminalIdentifier
      };
    }

    case types.ORDER_DETAILS_RECEIVED: {
      return {
        ...state,
        orderDetails:action.orderDetails,
        orderDetailsLoading:false
      };
    }
    case types.MENU_DATA_LOADING: {
      return {
        ...state,
        menuDataLoading:true

      };
    }
    case types.SET_RANGE_MODE: {
      return {
        ...state,
        rangeMode:true

      };
    }
    case types.SET_STOCK_MODE: {
      return {
        ...state,
        rangeMode:false

      };
    }
    case types.ORDER_DETAILS_LOADING: {
      return {
        ...state,
        orderDetailsLoading:true

      };
    }
    case types.SET_SKU_STOCK: {

      let newData = JSON.parse(JSON.stringify(state.menuData));

      state.menuData.Categories.map((category)=>{
        category.MenuItems.map((menuItem)=>{
          if (menuItem.Sku==action.sku) menuItem.Stocked = action.stocked;
          if (menuItem.Components && menuItem.Components.length>0) {
            menuItem.Components.map((smenuItem)=>{
              if (smenuItem.Sku==action.sku) smenuItem.Stocked = action.stocked;
            })
          }
        });
      });
      return {
        ...state
      };
    }
    case types.SET_SKU_RANGE: {
      let newData = JSON.parse(JSON.stringify(state.menuData));

      state.menuData.Categories.map((category)=>{
        category.MenuItems.map((menuItem)=>{
          if (menuItem.Sku==action.sku) menuItem.Ranged = action.stocked;

          if (menuItem.Components && menuItem.Components.length>0) {
            menuItem.Components.map((smenuItem)=>{
              if (smenuItem.Sku==action.sku) smenuItem.Ranged = action.stocked;
            })
          }
        });
      });
      return {
        ...state
      };
    }
    case types.NEW_MOP_STATE_REQUESTED: {
      let updatedStoreDetails = { ...state.storeDetail };
      updatedStoreDetails.TakingMOPOrders = action.newState;
      return {
        ...state,
        storeDetail: updatedStoreDetails,
        settingMopState: true,
      };
    }
    default:
      return state;
  }
};

export default opsReducer;
