// types.js

import keyBy from 'lodash/keyBy';
const prefix = "MENUS_";

const typeConvert = (...args) => keyBy(args.map((n)=>(prefix+n)),(o)=>( o.substring(prefix.length)));

export default typeConvert(
    "REQUEST_MENUS_JSON",
    "RECEIVE_MENUS_JSON"
);