import React from 'react';
import { withStyles } from '@material-ui/core';
import { Grid,  MenuItem, Select, Toolbar } from '@material-ui/core';
import ReactTable from 'react-table-v6'
import moment from 'moment'
import PageTitle from '../../../PageTitle';

const DaySelection = {
	YESTERDAY: "yesterday",
	TODAY: "today"
}

const StoreSelection = {
	SHOW_ALL: "all"
}

const styles = theme => ({
    root: {
		//padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 3}px`,
		//minWidth: "1300px"
	},
	reportArea: {
		paddingTop:`${theme.spacing() * 2}px`,
		paddingLeft: `${theme.spacing() * 3}px`,
		paddingRight: `${theme.spacing() * 3}px`,
	},
	title: {
        fontSize: "20px",
        color: "#999999"
	},
	disableStoreSelector: {
        cursor: "not-allowed",
		pointerEvents: "none",
		opacity: .5
	},
	daySelector: {
	},
	hidden: {
		display:"none"
	},
	stopClicks: {
		pointerEvents:"none"
	},
	tableHeader: {
		textAlign: "left",
		fontWeight: "bold"
	},
	tableDefs: {
		height: "88vh"
	},
	minorBodge: {
		userSelect: "none",
		opacity: 0
	}
});

class OutOfStockReportComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = { dayFilter: DaySelection.YESTERDAY };
	}

	customFilter = ({ fieldName, filter, onChange }) => {
		const { classes } = this.props;
		const { outOfStock } = this.props.outOfStockReport;
		return (
			
		  <select 
			style={{ width: "100%"}}
			onChange={event => onChange(event.target.value)}
			className={`${outOfStock.length === 0 ? classes.disableStoreSelector: null}`}
			value={filter ? filter.value : StoreSelection.SHOW_ALL }> 
			<option value={StoreSelection.SHOW_ALL}>Show All</option>
			{this.props.outOfStockReport.outOfStock
			  .map(item => item[fieldName])	
			  .filter((item, i, s) => s.lastIndexOf(item) === i)
			  .map(function (value) {
				return (<option key={value} value={value}>{value}</option>);
			  })}
		  </select>

		);
	  };

	componentDidMount() {
		this.props.updateTitle("Out of Stock");
		this.props.getOutOfStock(this.getYesterdaysDate()); 
	}

	getTodaysDate() {
		let todaysDate =  new Date();		
		//let todaysDate = new Date("2019-2-27");
		return todaysDate;
	}

	getYesterdaysDate() {
		let yesterdaysDate = moment(this.getTodaysDate()).subtract(1, "days").toDate();
		//let yesterdaysDate = moment("2019-2-26").subtract(1, "days").toDate();
		return yesterdaysDate;
	}

	handleSelectChange = event => {
        this.setState({dayFilter: event.target.value});
		if(event.target.value === DaySelection.TODAY)
			this.props.getOutOfStock(this.getTodaysDate()); 
		else if(event.target.value === DaySelection.YESTERDAY)
			this.props.getOutOfStock(this.getYesterdaysDate()); 
    };

	render() {
		const { classes } = this.props;
		const { loading, outOfStock } = this.props.outOfStockReport;
		const { dayFilter } = this.state;
        const columns = 
        [
            {
				Header: "Menu Item",
				accessor: 'MenuItemName', 
				headerClassName: classes.tableHeader,
				Filter: <div className={classes.minorBodge}>x</div>,
				minWidth: 175
            },
            {
				Header: "SKU",
				accessor: 'SKU', 
				headerClassName: classes.tableHeader,
				Filter: <div className={classes.minorBodge}>x</div>
			},
			{
				Header: "Store",
				accessor: 'StoreName', 
				headerClassName: classes.tableHeader,
				filterMethod: (filter, row) => { 
					if(filter.value === StoreSelection.SHOW_ALL)
						return true;
					else 	
						return row[filter.id] === filter.value; 
				},
				Filter: ({ filter, onChange }) => this.customFilter({ fieldName: 'StoreName', filter, onChange }),
				minWidth: 200
			},
			{
				Header: "Store Number",
				accessor: 'StoreNumber', 

				headerClassName: classes.tableHeader,
				sortable: false,
				Filter: <div className={classes.minorBodge}>x</div>
			},
			{
				Header: "Terminal",
				accessor: 'TerminalName', 
				headerClassName: classes.tableHeader,
				sortable: false,
				Filter: <div className={classes.minorBodge}>x</div>
			},		  
		];
		return (<div className={classes.root}>
				<Toolbar variant="dense">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={6}>
							<PageTitle/>
                        </Grid>

						<Grid item xs={6} style={{ textAlign: "right" }}>
							<Select className={classes.daySelector} value={dayFilter} onChange={this.handleSelectChange}>
								<MenuItem value={DaySelection.YESTERDAY}>Yesterday</MenuItem>
								<MenuItem value={DaySelection.TODAY}>Today</MenuItem>
							</Select>
						</Grid>
                    </Grid>
                </Toolbar>

				<Grid container justify="space-between" alignItems="center" className={classes.reportArea}>
					<Grid item xs={12}>
						<ReactTable 
							className={classes.tableDefs}
							filterable
							defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value }
							noDataText="Nothing to report."
							defaultPageSize={20}
							loading={loading}
							columns={columns} 
							data={outOfStock}/>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styles)(OutOfStockReportComponent);