import types from "./types"

const editAccount = data => ({
	type: types.EDIT_ACCOUNT,
	data: data
})

const changePassword = data => ({
	type: types.CHANGE_PASSWORD,
	data: data
})

const savingUser = data => ({
	type: types.SAVING_USER,
	data: data
})

const changingPassword = data => ({
	type: types.CHANGING_PASSWORD,
	data: data
})

const resetSaved = data => ({
	type: types.RESET_SAVED,
	data: data
})

export default {
	editAccount,
	changePassword,
	savingUser,
	changingPassword,
	resetSaved
}