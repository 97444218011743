import React from "react";
import { Grid, Paper, withStyles, Typography, Box, FormControlLabel } from "@material-ui/core";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import { purple } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const MorrisonsAgendaBold = "MorrisonsAgenda Bold";
const colour03 = "rgba(237,28,36,1)";
const colour05 = "rgba(0,137,59,1)";
const locolor = colour05;
const hicolor = colour03;

const styles = (theme) => ({
  notificationBox: {
    textAlign: "center",
    width: "100%",
    marginBottom: "20px",
    display: "block"
    },
   openingTimes: {
       width: "300px",
       marginLeft: "auto",
       marginRight:"auto",
       textAlign: "left",
        marginBottom: "20px",
       display: "block",
       '& ul': {
           listStyleType: "none",
       },
       '& span': {
           fontWeight: "600",
       }
    },
  innerNotificationBox: {
    textAlign: "center",
    border:"1px solid #000",
    width:"70%",
    margin:"auto",
    padding:"10px",
    marginBottom:"10px"

  },
  labelText: {
    marginTop: "-3px",
    fontWeight: "600"
  },
  noteText: {
    fontSize: "0.8em"
  },
  redText: {
    color: "#cc0000"
  },
  greenText: {
    color: "#00563F"
  },
  tickCrossBox: {

  },
  svgMove: {
    color:"#f00",
      "transform":"translate(0,7px)"
    }
    ,
  svgMoveGreen: {
    color:"#0f0",
      "transform":"translate(0,7px)"
    }
  
});

const GreenSwitch = withStyles({
  switchBase: {
    color: "#00563F",
    '&$checked': {
      color: "#00563F",
    },
    '&$checked + $track': {
      backgroundColor: '#00563F',
    },
    MuiSwitchBar: '#000000'
  },
  checked: {},
  track: {},
})(Switch);

class OpsDashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  update() { }


  getStoreNumber() {
    let storeNumber = null;
    if (this.props.login && this.props.login.user) {
      storeNumber = this.props.login.user.ManagesStoreNumbers[0];
    }
    return storeNumber;
  }


  componentDidMount() {
    this.props.updateTitle("Dashboard");
    this.props.getStoreDetail(this.getStoreNumber());
  }

  handleChange(event) {
    this.props.setMopState(this.getStoreNumber(), event.target.checked);
  }

  render() {
    const { classes } = this.props;
    const { NiceName, name, FirstLineAddress, IsOpen, IncludeInMOPActiveList, TakingMOPOrders, StoreHealth,OpeningTimesHtml } = this.props.ops.storeDetail;
    const { settingMopState, takingMOPOrdersAccepted } = this.props.ops;

    let kitchenScreenOk = StoreHealth && StoreHealth.KitchenScreenOk;
    let TFXTransOk = StoreHealth && StoreHealth.TFXTransOk;


    let takingOrders = takingMOPOrdersAccepted;
    if (!kitchenScreenOk || !TFXTransOk) {
      takingOrders = false;
    }

    return (
      <React.Fragment>
        {NiceName && <div>
          <div className={classes.notificationBox}>
            <span> You are managing: </span>
            <b>
        <span> {NiceName} {FirstLineAddress && <span>-</span>} {FirstLineAddress}</span>
            </b>
          </div>

          {IncludeInMOPActiveList && <div>

            <div>
              <div className={classes.notificationBox}>
                <span>Choose whether to accept mobile orders below: </span>
              </div>
              <div className={classes.notificationBox}>

                <Grid component="label" container alignItems="center" justify="center" spacing={0}>
                  <Grid item className={classes.labelText}>Don't accept</Grid>
                  <Grid item>
                    <GreenSwitch color={"primary"} size={"large"} checked={TakingMOPOrders} onChange={this.handleChange.bind(this)} disabled={settingMopState} />
                  </Grid>
                  <Grid item className={classes.labelText}>Accept</Grid>
                </Grid>
              </div>

              {takingOrders &&
                <div className={classes.notificationBox}>
                  <span>Mobile orders <b className={classes.greenText}>ARE</b> being accepted</span>
                </div>}

              {!takingOrders &&
                <div className={classes.innerNotificationBox}>
                  <span>Mobile orders are <b className={classes.redText}>NOT</b> being accepted</span><br /><br />
                  <div className={classes.tickCrossBox}>
                    {kitchenScreenOk && false &&  <span><CheckIcon className={classes.svgMoveGreen}></CheckIcon>&nbsp;Kitchen screen available</span>}
                    {!kitchenScreenOk && <span><ClearIcon  className={classes.svgMove}></ClearIcon>&nbsp;Orders are not being accepted as the Kitchen Screen is unavailable</span>}

                  </div>
                  <div className={classes.tickCrossBox}>
                    {TFXTransOk && false &&  <span><CheckIcon className={classes.svgMoveGreen}></CheckIcon>&nbsp;Store Server available</span>}
                    {!TFXTransOk && <span><ClearIcon  className={classes.svgMove}></ClearIcon>&nbsp;Orders are not being accepted as the Store Server is unavailable</span>}

                  </div>

                </div>}

              <div className={classes.notificationBox}>
                <span>Unless you override Mobile Ordering above, it will be available to place orders during these times:</span>
              </div>
                        <div className={classes.openingTimes} dangerouslySetInnerHTML={{ __html: OpeningTimesHtml}}>
   
                        </div>

            </div>
          </div>}

          {!IncludeInMOPActiveList && <div>

            <div className={classes.notificationBox}>
              <span > This store is <b className={classes.redText}>Not</b> enabled for online orders yet.</span>
            </div>
          </div>}
        </div>}

        {!NiceName && <div className={classes.notificationBox}>
          <span> Loading store detail.. </span>
        </div>}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OpsDashboardComponent);
