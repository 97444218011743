import React from "react";
import { Grid, Paper, withStyles, Typography, Box, FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { purple } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import {ORDER_TYPES} from '../../utils/miscUtils';

const MorrisonsAgendaBold = "MorrisonsAgenda Bold";
const colour03 = "rgba(237,28,36,1)";
const colour05 = "rgba(0,137,59,1)";
const locolor = colour05;
const hicolor = colour03;

const styles = (theme) => ({
  notificationBox: {
    textAlign: "center",
    width: "100%",
    marginBottom: "20px",
    display: "block",
  },
  labelText: {
    marginTop: "-3px",
    fontWeight: "600"
  },
  noteText: {
    fontSize: "0.8em"
  },
  redText: {
    color: "#cc0000"
  },
  greenText: {
    color: "#00563F"
  },
  blueText: {
    color: "#4040ff"
  },
  paperPadding: {
    padding: "10px",
    marginBottom: "10px"
  },
  outOfStock: {
    color: '#c77',
    fontSize: '0.8em'
  },
  asap: {
    fontSize: '0.8em'
  },
  smallPrepare: {
    fontSize: '0.8em'
  },
  channel: {
    fontSize: '0.8em',
    fontWeight: '600'
  }
});

const GreenSwitch = withStyles({
  switchBase: {
    color: "#00563F",
    '&$checked': {
      color: "#00563F",
    },
    '&$checked + $track': {
      backgroundColor: '#00563F',
    },
    MuiSwitchBar: '#000000'
  },
  checked: {},
  track: {},
})(Switch);

class OrderRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  update() { }

  componentDidMount() {

  }

  handleChange(event) {

  }

  render() {

    const { ASAP, PlacedAt, CustomerOrderNumber, ReadyFor, Status, OrderLines,OrderCost,Channel, TableNumber } = this.props.order;

    const { classes } = this.props;

    return (
      <React.Fragment>

        <Box mb={2}>
          <Card elevation={3} title={"hjkh"}>
            <CardContent>


              <Grid container>


                <Grid xs={8}>


{(Channel==ORDER_TYPES.MOP) && <Typography variant="h5" component="h2" color="textSecondary">
                 <span className={classes.channel}>Mobile Order</span>
                 <span className={classes.smallPrepare}> Prepare for</span>  <b>
                      {ASAP && moment(PlacedAt).format('HH:mm')}

                      {!ASAP && moment(ReadyFor).format('HH:mm')}

                      {ASAP && <span className={classes.asap}> (ASAP)</span>}</b>
                  </Typography>}

{(Channel==ORDER_TYPES.SOP) && <Typography variant="h5" component="h2" color="textSecondary">
                <span className={classes.channel}>Self Order Point</span>
                 <span className={classes.smallPrepare}> Ordered at</span>  <b>
                      {ASAP && moment(PlacedAt).format('HH:mm')}

                      {!ASAP && moment(ReadyFor).format('HH:mm')}

                      {ASAP && <span className={classes.asap}> (ASAP)</span>}</b>
                  </Typography>}

{(Channel==ORDER_TYPES.CFT) && <Typography variant="h5" component="h2" color="textSecondary">
                 <span className={classes.channel}>Colleague Till</span>
                 <span className={classes.smallPrepare}> Ordered at</span>  <b>
                      {ASAP && moment(PlacedAt).format('HH:mm')}

                      {!ASAP && moment(ReadyFor).format('HH:mm')}

                      {ASAP && <span className={classes.asap}> (ASAP)</span>}</b>
                  </Typography>}





                </Grid>

                <Grid xs={4} alignContent={"flex-end"}>
                  <Typography className={classes.title} color="textSecondary" align={"right"}>
                    Ref <b>{CustomerOrderNumber}</b>
                  </Typography>
                  {TableNumber && TableNumber != 0 && <Typography className={classes.title} color="textSecondary" align={"right"}>
                    Table: <b>{TableNumber}</b>
                  </Typography>}
                </Grid>



              </Grid>


              <Typography variant="h6" component="h3">
                {(Status == "PENDING" || Status == "UNKNOWN") && <span>Pending..</span>}
                {(Status == "INKITCHEN") && <span className={classes.blueText}>In Kitchen</span>}
                {(Status == "READY") && <span className={classes.greenText}>Ready</span>}
                {(Status == "COMPLETE") && <span className={classes.greenText}>Complete</span>}
              </Typography>

              <Box mt={2} mb={2}>



                {OrderLines.map((orderline, idx2) => (

                  <Grid container spacing={1} key={idx2}>

                    <Grid xs={1} >
                      <Typography align={"center"}>{orderline.Quantity} x</Typography>
                    </Grid>
                    <Grid xs={11}>
                            {!orderline.IsDiscount && <Typography align={"left"}>{orderline.Description} </Typography>}
                            {orderline.IsDiscount && <Typography align={"left"}>Discount - <i>{orderline.Description}</i></Typography>}
                    </Grid>

                  </Grid>


                ))}

              </Box>

              <Typography className={classes.pos} color="textSecondary">
               Total price: <b>£{OrderCost.toFixed(2)}</b>
              </Typography>

            </CardContent>

          </Card>

        </Box>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OrderRow);
