import Creators from "./actions"
import LoginCreators from "../../loginPage/duck/actions"
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";

const savingUserAction = Creators.savingUser;
const editAccountAction = Creators.editAccount;
const changePasswordAction = Creators.changePassword;
const changingPasswordAction = Creators.changingPassword;
const resetSavedAction = Creators.resetSaved;
const updateUserAction = LoginCreators.updateUser;

const editAccount = (user) => {
	return (dispatch, getState) => {
		dispatch(savingUserAction());

		new DuckApiCalls().putData("account/editAccount", { user }).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				dispatch(editAccountAction(payload));

				if(payload)
					dispatch(updateUserAction(user));
			})
		}).catch(console.error);
	}
}

const changePassword = (passwords) => {
	return (dispatch, getState) => {
		dispatch(changingPasswordAction());

		new DuckApiCalls().postData("account/changePassword", passwords).then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);

			return response.json().then(payload => {
				dispatch(changePasswordAction(payload));
			})
		}).catch(console.error);
	}
}

const resetSaved = () => {
	return dispatch => {
		dispatch(resetSavedAction());
	}
}

export default {
	editAccount,
	changePassword,
	resetSaved
}