// reducers.js
import types from "./types";

const INITIAL_STATE = {
    menus: [],
    loading: true
};

const menusReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.RECEIVE_MENUS_JSON:
            return {
                ...state,
                menus: action.data.menus,
                loading: action.data.loading
            };

        default:
            return state;
    }
};

export default menusReducer;
