import React from "react";
import { Grid, Paper, withStyles, Typography, Box, FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { purple } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';


const MorrisonsAgendaBold = "MorrisonsAgenda Bold";
const colour03 = "rgba(237,28,36,1)";
const colour05 = "rgba(0,137,59,1)";
const locolor = colour05;
const hicolor = colour03;

const styles = (theme) => ({
  notificationBox: {
    textAlign: "center",
    width: "100%",
    marginBottom: "20px",
    display: "block",
  },
  labelText: {
    marginTop: "-3px",
    fontWeight: "600"
  },
  noteText: {
    fontSize: "0.8em"
  },
  redText: {
    color: "#cc0000"
  },
  greenText: {
    color: "#00563F"
  },
  sku: {
    fontSize: "0.8em"
  },
  paperPadding: {
    padding: "10px",
    marginBottom: "10px"
  },
  outOfStock: {
    color: "#cc0000",
    fontSize: '0.8em'
  },
  indentedDiv: {
    marginLeft: "25px"
  },
  maxHeightSummary: {
    margin: "0px",
    '&div': {
      backgroundColor: "#f00",
      margin: "0px"
    }
  },
  subsOut: {
    position: "absolute",
    right: "60px",
    top: "22px",
    color: "#cc0000"
  }
});

const GreenSwitch = withStyles({
  switchBase: {
    color: "#00563F",
    '&$checked': {
      color: "#00563F",
    },
    '&$checked + $track': {
      backgroundColor: '#00563F',
    },
    MuiSwitchBar: '#000000'
  },
  checked: {},
  track: {},
})(Switch);

const RedSwitch = withStyles({
  switchBase: {
    color: "#ff563F",
    '&$checked': {
      color: "#ff563F",
    },
    '&$checked + $track': {
      backgroundColor: '#555555',
    },
    MuiSwitchBar: '#000000'
  },
  checked: {},
  track: {},
})(Switch);

class MenuItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowExpanded: false
    };
  }

  update() { }

  componentDidMount() {

  }

  toggleDrawer = () => {
    this.setState({ rowExpanded: !this.state.rowExpanded });
  }



  render() {

    const { MenuItemName, Stocked, Sku, Ranged, HasComponents, ShowComponents, Components } = this.props.menuItem;
    const { classes, setSkuStock, rangeMode } = this.props;
    const { rowExpanded } = this.state;
    const qtyOutOfStock = Components ? Components.filter(a => !a.Stocked).length : 0;
    const qtyDeranged = Components ? Components.filter(a => !a.Ranged).length : 0;
    return (
      <React.Fragment>

        {(Ranged || rangeMode) && <Accordion expanded={rowExpanded} onChange={this.toggleDrawer}>
          <AccordionSummary
            expandIcon={HasComponents && <ExpandMoreIcon />}
            className={classes.maxHeightSummary}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {rangeMode && <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={<RedSwitch color={"primary"} size={"large"} checked={Ranged} onChange={(event) => setSkuStock(Sku, event.target.checked)} />}
              label={<span>
                {Ranged && <Typography><b>{MenuItemName} <span className={classes.sku}>({Sku})</span></b></Typography>}
                {!Ranged && <Typography>{MenuItemName} <span className={classes.sku}>({Sku})</span></Typography>}</span>}
            />}
            {!rangeMode && <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={<GreenSwitch color={"primary"} size={"large"} checked={Stocked} onChange={(event) => setSkuStock(Sku, event.target.checked)} />}
              label={<span>
                {Stocked && <Typography><b>{MenuItemName}</b></Typography>}
                {!Stocked && <Typography>{MenuItemName} <span className={classes.outOfStock}>(Out of stock)</span></Typography>}</span>}
            />
            
       
            }

            {!rangeMode && (qtyOutOfStock > 0) && <div className={classes.subsOut}> {qtyOutOfStock} item{qtyOutOfStock > 1 ? "s" : ""} </div>}
            {rangeMode && (qtyDeranged > 0) && <div className={classes.subsOut}> {qtyDeranged} item{qtyDeranged > 1 ? "s" : ""} </div>}

          </AccordionSummary>
          <AccordionDetails>
            <div>
              {Components.map((subItem, midx) => (
                <div className={classes.indentedDiv}>
                  {rangeMode && <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={<RedSwitch color={"primary"} size={"large"} checked={subItem.Ranged} onChange={(event) => setSkuStock(subItem.Sku, event.target.checked)} />}
                    label={<span>
                      {subItem.Ranged && <Typography><b>{subItem.MenuItemName} <span className={classes.sku}>({Sku})</span></b></Typography>}
                      {!subItem.Ranged && <Typography>{subItem.MenuItemName} <span className={classes.sku}>({Sku})</span></Typography>}</span>}
                  />}

                  {!rangeMode && <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={<GreenSwitch color={"primary"} size={"large"} checked={subItem.Stocked} onChange={(event) => setSkuStock(subItem.Sku, event.target.checked)} />}
                    label={<span>
                      {subItem.Stocked && <Typography><b>{subItem.MenuItemName}</b></Typography>}
                      {!subItem.Stocked && <Typography>{subItem.MenuItemName} <span className={classes.outOfStock}>(Out of stock)</span></Typography>}</span>}
                  />}



                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MenuItemRow);
