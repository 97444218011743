import PromotionsComponent from './PromotionsComponent';
import PromotionsPage from './PromotionsPage';
import React from "react";
import { Route, Switch } from "react-router-dom";

class PromotionsContainer extends React.Component {
	render() {
		return (
			<Switch>
				<Route exact path="/promotions" component={PromotionsComponent} />
				<Route path="/promotions/:promotion" component={PromotionsPage} />
			</Switch>
		)
	}
}
export default PromotionsContainer;