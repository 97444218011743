import React from 'react';
import { Button } from '@material-ui/core';

const DatePickerInput = ({ value, onClick }) => (
    <Button 
    variant="contained" color="primary" 
    onClick={onClick}>
      {value}
    </Button>
);

export default DatePickerInput;