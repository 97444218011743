const REQUEST_ESTATE_ACTIONS = "REQUEST_ESTATE_ACTIONS";
const RECEIVE_ESTATE_ACTIONS = "RECEIVE_ESTATE_ACTIONS";
const REQUEST_ESTATE_MENU_ITEMS = "REQUEST_ESTATE_MENU_ITEMS";
const RECEIVE_ESTATE_MENU_ITEMS = "RECEIVE_ESTATE_MENU_ITEMS";
const REQUEST_ESTATE_STORES = "REQUEST_ESTATE_STORES";
const RECEIVE_ESTATE_STORES = "RECEIVE_ESTATE_STORES";

export default {
    REQUEST_ESTATE_ACTIONS, 
    RECEIVE_ESTATE_ACTIONS, 
    REQUEST_ESTATE_STORES, 
    RECEIVE_ESTATE_STORES,
    REQUEST_ESTATE_MENU_ITEMS, 
    RECEIVE_ESTATE_MENU_ITEMS
}