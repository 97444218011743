import React from 'react';
import Moment from 'react-moment';
import classNames from "classnames";

class WbDateTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = { date: null };
    }
    updateDateTime() {
        this.setState({date: new Date()});
    }
    componentDidMount() {
        this.updateDateTime();
        this.timer = setInterval(() => this.updateDateTime(), 500);
    }
    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }
    render() {
        const { date } = this.state;
        const { classes } = this.props;
        return(<div className={classNames(classes.wbDateTime)} >
            <div><Moment format="h:mm:ss A">{date}</Moment></div>
            <div><Moment format="DD/MM/YYYY">{date}</Moment></div>
        </div>);
    }
}

export default WbDateTime;