import Creators from "./actions";
import DuckApiCalls from "../utils/duckApiCalls"

const updateTitleAction = Creators.updateTitle;
const getKPIsAction = Creators.getKPIs;
const getVersionAction = Creators.getVersion;
const getSummariesAction = Creators.getSummaries;
const disableSidebarAction = Creators.disableSidebar;

const updateTitle = (title) => {
  return (dispatch, getState) => {
    dispatch(updateTitleAction(title));
  };
};

const getKPIs = () => {
	return (dispatch, getState) => {
		new DuckApiCalls().getData("dashboard/getKpis").then(response => {
			response.json().then(payload => {
				dispatch(getKPIsAction(payload));
			});
		})
	}
}

const getVersion = () => {
	return (dispatch, getState) => {
		new DuckApiCalls().getData("dashboard/getVersion").then(response => {
			response.json().then(payload => {
				dispatch(getVersionAction(payload));
			})
		})
	}
}

const getSummaries = () => {
	return (dispatch, getState) => {
		new DuckApiCalls().getData("reporting/summaries").then(response => {
			response.json().then(payload => {
				dispatch(getSummariesAction(payload));
			});
		})
	}
}

const disableSidebar = (disable) => {
	return (dispatch, getState) => {
	  dispatch(disableSidebarAction(disable));
	};
  };

export default {
	updateTitle,
	getKPIs,
	getVersion, 
	getSummaries,
	disableSidebar
};