import Creators from "./actions";
import DuckApiCalls from "../../../utils/duckApiCalls";
import UnauthorizedError from "../../../utils/unauthorizedError";

const requestProductsAction = Creators.requestProducts;
const receiveProductsAction = Creators.receiveProducts;
const requestClassificationsAction = Creators.requestClassifications;
const receiveClassificationsAction = Creators.receiveClassifications;
const updateClassificationsAction = Creators.updateProductClassification;
const requestStoresAction = Creators.requestStores;
const receiveStoresAction = Creators.receiveStores;
const requestStoreZonesAction = Creators.requestStoreZones;
const receiveStoreZonesAction = Creators.receiveStoreZones;
const requestChangeRowsPerPageAction = Creators.requestChangeRowsPerPage;
const requestChangePageAction = Creators.requestChangePage;
const requestProductSearchAction = Creators.requestProductSearch;
const requestChangeSortByAction = Creators.requestChangeSortBy;
const requestChangeTabAction = Creators.requestChangeTab;

const getProducts = () => {
	return (dispatch, getState) => {
		dispatch(requestProductsAction());

		const { page, rowsPerPage, sortColumn, sortDirection, searchPhrase } = getState().kitchenFlows;

		let query = `startAt=${page}&maxResults=${rowsPerPage}&sortBy=${sortColumn}&sortDirection=${sortDirection}&searchText=${searchPhrase}`;

		new DuckApiCalls().getData(`kitchenflows/GetProducts?${query}`)
			.then(response => {
			if (response.status === 401) {
				throw new UnauthorizedError(dispatch);
			}
				
			return response.json().then(payload => {
				dispatch(receiveProductsAction({
					products: payload.Products,
					totalResults: payload.TotalResults,
					loading: false
				}));
			});
		}).catch(console.error);
	};
};

const getClassifications = () => {
	return dispatch => {
		dispatch(requestClassificationsAction());

		new DuckApiCalls().getData("kitchenflows/GetKitchenClassifications").then(response => {
			if (response.status === 401) {
				throw new UnauthorizedError(dispatch);
			}
				
			return response.json().then(payload => {
				dispatch(receiveClassificationsAction({
					classifications: payload,
					loading: false
				}));
			});
		}).catch(console.error);
	};
};

const updateProductClassification = (product) => {
	return dispatch => {
		new DuckApiCalls().putData("kitchenflows/UpdateProductClassification", { SKU: product.SKU, KitchenClassificationId: product.KitchenClassificationId }).then(response => {
			if (response.status === 401) {
				throw new UnauthorizedError(dispatch);
			}

			return response.json().then(payload => {
				if (payload) {
					dispatch(updateClassificationsAction(product));
				}
			});
		}).catch(console.error);
	};
};

const getStores = () => {
	return dispatch => {
		dispatch(requestStoresAction());

		new DuckApiCalls().getData("kitchenflows/GetStores").then(response => {
			if (response.status === 401) {
				throw new UnauthorizedError(dispatch);
			}
				
			return response.json().then(payload => {
				dispatch(receiveStoresAction({
					stores: payload,
					loading: false
				}));
			});
		}).catch(console.error);
	};
};

const getStoreZones = (storeId) => {
	return dispatch => {
		dispatch(requestStoreZonesAction(storeId));

		new DuckApiCalls().getData(`kitchenflows/GetStoreZones?storeId=${storeId}`).then(response => {
			if (response.status === 401) {
				throw new UnauthorizedError(dispatch);
			}
				
			return response.json().then(payload => {
				dispatch(receiveStoreZonesAction({
					zones: payload,
					loading: false
				}));
			});
		}).catch(console.error);
	};
};

const changeRowsPerPage = (rows) => {
	return (dispatch) => {
		dispatch(requestChangeRowsPerPageAction(rows));
		dispatch(getProducts());
	};
};

const changePage = (newPage) => {
	return (dispatch) => {
		dispatch(requestChangePageAction(newPage));
		dispatch(getProducts());
	};
};

const productSearch = (productSearchPhrase) => {
	return (dispatch) => {
		dispatch(requestProductSearchAction(productSearchPhrase));
		dispatch(getProducts());
	};
}

const changeSortBy = (column) => {
	return (dispatch) => {
		dispatch(requestChangeSortByAction(column));
		dispatch(getProducts());
	};
}

const changeTab = (tabIndex) => {
	return (dispatch) => {
		dispatch(requestChangeTabAction(tabIndex));
	};
}

export default {
  getProducts,
  getClassifications,
  updateProductClassification,
  getStores,
  getStoreZones,
  changeRowsPerPage,
  changePage,
  productSearch,
  changeSortBy,
  changeTab
};
