import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    noDataChart: {
      opacity: "0.5",
      pointerEvents:"none"
    },
});

class AmBasketsByDateChart extends React.Component {
    constructor(props) {
        super(props);
        am4core.useTheme(am4themes_animated);
    }
    componentDidMount() {
		let chart = am4core.create("basketsbydatechartdiv", am4charts.XYChart);
		chart.numberFormatter.numberFormat = '#,###';
		chart.data = this.props.data;
		let title = chart.titles.create();
		title.text = "Baskets by Date";
		title.fontSize = 21;
		title.align = "left";
		title.marginBottom = 30;	
		if(this.props.data.length > 0) {
			chart.exporting.menu = new am4core.ExportMenu();	
			chart.exporting.filePrefix = title.text;	
		}		
		var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		dateAxis.renderer.minGridDistance = 60;
		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		var series = chart.series.push(new am4charts.LineSeries());
		series.dataFields.valueY = "Total";
		series.dataFields.dateX = "Date";
		series.tooltipText = "{value}";
		series.stroke = am4core.color("#004e37");
		series.tooltip.pointerOrientation = "vertical";

		chart.cursor = new am4charts.XYCursor();
		chart.cursor.snapToSeries = series;
		chart.cursor.xAxis = dateAxis;
		chart.scrollbarX = new am4core.Scrollbar();

		chart.events.on("ready", function(ev) {
			valueAxis.min = valueAxis.minZoomed;
			valueAxis.max = valueAxis.maxZoomed;
		});


		this.chart = chart;
    }
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
	render() {
		const { data, classes } = this.props
		return (<div id="basketsbydatechartdiv" style={{ width: "100%", height: "500px" }} className={ classNames( data.length  === 0 ? classes.noDataChart : null )}></div>);		
	}
}


export default withStyles(styles)(AmBasketsByDateChart);