import React from 'react';
import WbHelpers from './WbHelpers';

const padding = 10;
const barHeight = 40; 
const itemLimit = 6;    // limit of items
const barPadding = 5;   // padding between bars

export default class WbSuperSimpleBarChart extends React.Component {
    formatNumber(num) {
        return WbHelpers.formatNumber(num);
    }
    barColour(index,value,classes) {
        const { topFill } = this.props;
        if(index === 0 && topFill !== undefined)
            return topFill;
        else
            return index % 2 ? classes.wbSbBarColor : classes.wbSbBarAltColor;
    } 	
	render() {
	    const { width, values, reverseorder, classes } = this.props;
        let wbwidth = width;
        let isPopulated = !(values === undefined || values.length === 0);        
        let wbheight = itemLimit * (barHeight + barPadding); // max space for 6 values, 6 values tops               
        let vals = values.sort((a,b) => { return a.value - b.value; })
        if(!reverseorder)
            vals.reverse();
        vals = vals.slice(0,itemLimit);
        let maxValue = Math.max.apply(Math, vals.map(function(o) { return o.value; }));
        let scaling = wbwidth / maxValue;
		return(<div style={{ width: wbwidth + "px", height: wbheight + "px", backgroundColor:"transparent"}}>
            {
            vals.map((item, index) => {
                return (
                    <div key={item.caption}
                    style={{marginBottom: barPadding + "px",height: barHeight,width: "100%", backgroundColor:"transparent"}}>
                        <div className={this.barColour(index,item.value,classes)} style={{ width: item.value * scaling,height: barHeight}}></div>
                        <div style={{color:"rgb(255, 255, 255)", position:"relative", top:"-35px",textAlign: "left", left: padding + "px", fontSize: "1.75rem", fontWeight: "bold",mixBlendMode: index === 0 ? "normal":"difference", textTransform: "uppercase"}}>{item.caption} - {this.formatNumber(item.value)}</div>
                    </div>)})
            }
            { isPopulated === false ? (
                 <div style={{fontSize:"1.75rem", fontWeight:"bold", textAlign:"center",lineHeight: wbheight + "px"}}>--</div>
            ):(null) }      
        </div>);
	}	
}