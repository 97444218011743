import React from "react";
import { Grid, Paper, withStyles, Typography, Box, FormControlLabel, CircularProgress, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import { purple } from '@material-ui/core/colors';
import MenuItemRow from './MenuItemRow';


const MorrisonsAgendaBold = "MorrisonsAgenda Bold";
const colour03 = "rgba(237,28,36,1)";
const colour05 = "rgba(0,137,59,1)";
const locolor = colour05;
const hicolor = colour03;

const styles = (theme) => ({
  notificationBox: {
    textAlign: "center",
    width: "100%",
    marginBottom: "20px",
    display: "block",
  },
  labelText: {
    marginTop: "-3px",
    fontWeight: "600"
  },
  noteText: {
    fontSize: "0.8em"
  },
  redText: {
    color: "#cc0000"
  },
  redButton: {
    color: "#ffffff",
    backgroundColor: "#ff0000"
  },
  greenText: {
    color: "#00563F"
  },
  paperPadding: {
    padding: "10px",
    marginBottom: "10px"
  },
  minHeight: {
    minHeight: "200px"
  }
});


class OpsStockComponent extends React.Component {
  constructor(props) {

    super(props);
    this.state = {

    };
  }

  update() { }



  getTerminalIdentifier() {
    if (this.props.ops && this.props.ops.storeDetail && this.props.ops.storeDetail.Terminals) {
      let goodTerminal = this.props.ops.storeDetail.Terminals.filter(a => a.terminalName && a.terminalName.includes(this.props.termName));
      if (goodTerminal && goodTerminal.length > 0) {
        return goodTerminal[0].terminalName;
      }
      return this.props.ops.storeDetail.Terminals[0].terminalName;
    }
    return null;
  }

  componentDidMount() {
    this.props.updateTitle("Dashboard");
    this.props.getMenuData(this.getTerminalIdentifier());

  }

  handleChange(event) {
    this.props.setMopState(this.getStoreNumber(), event.target.checked);
  }

  render() {
    const { classes } = this.props;
    const { NiceName, name, FirstLineAddress } = this.props.ops.storeDetail;
    const { menuData, menuDataLoading, rangeMode } = this.props.ops;
    const { setSkuStock, setRangeMode, setStockMode } = this.props;

    return (
      <React.Fragment>
        {NiceName && <Box m={2}>

          {rangeMode && <div className={classes.notificationBox}>
            <span> Manage <b>{this.props.title}</b> ranging for : </span>
            <b>
              <span> {NiceName} - {FirstLineAddress}</span>
            </b><br>
            </br><br>
            </br>
            <span className={classes.redText}>ITEMS DERANGED HERE WILL NOT APPEAR ON SELF ORDER POINT OR MOBILE ORDER POINT</span><br>
            </br>
          </div>}

          {!rangeMode && <div className={classes.notificationBox}>
            <span> Manage <b>{this.props.title}</b> stock for : </span>
            <b>
              <span> {NiceName} {FirstLineAddress && '-'} {FirstLineAddress}</span>
            </b>
          </div>}

          {!menuDataLoading && menuData && menuData.Categories.map((category, idx) => (
            <Paper className={classes.paperPadding} key={idx}><Box pt={2} pb={2}><Typography variant="h6" >{category.CategoryName}</Typography></Box>
              {category.MenuItems && category.MenuItems.map((menuItem, midx) => (<MenuItemRow key={midx} menuItem={menuItem} setSkuStock={setSkuStock} rangeMode={rangeMode}  ></MenuItemRow>))}
            </Paper>
          ))}

          {!menuDataLoading && !rangeMode && <Button
            variant="contained"
            color="secondary"
            onClick={setRangeMode}
            className={classes.redButton}
          >
            Switch to Ranging mode
                  </Button>
          }

          {!menuDataLoading && rangeMode && <Button
            variant="contained"
            color="secondary"
            onClick={setStockMode}
          >
            Switch to Stock mode
                  </Button>
          }

        </Box>}

        {menuDataLoading && <Grid align={"center"}  >
          <div className={classes.minHeight}>


            <CircularProgress />

          </div>
        </Grid>}

      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OpsStockComponent);
