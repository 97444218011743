// move off somewhere else (see ReportHelpers)
const WbHelpers = {
    formatCurrency: function(number) {
        return number.toLocaleString("en-gb", {
            style: "currency",
            currency: "GBP"
        });
    },
    formatNumber: function(number) {
        return number.toLocaleString("en-gb");
    }   
}
export default WbHelpers;