import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import './App.css';
import Dashboard from "./dashboard"
import LoginContainer from './pages/loginPage/LoginContainer';
import PortalSettings from "./utils/portalSettings";

const MorrisonsAgendaBold = "MorrisonsAgenda Bold";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: PortalSettings.primary
		},
		secondary: {
			main: PortalSettings.secondary
		}
	},
	typography: {
		fontFamily: [
		  'MorrisonsAgendaBold',
		  'Roboto',
		  '"Helvetica Neue"',
		  'Arial',
		  'sans-serif',
		  '"Apple Color Emoji"',
		  '"Segoe UI Emoji"',
		  '"Segoe UI Symbol"',
		].join(','),
	  }

});

//Redirect the user to the login page if their not logged in
const RestrictedRoute = ({state, ...props}) => {
	return (
		state.authenticated ? 
		<Route {...props}/> :
		<Redirect to={{ pathname: "/login", state: {from: props.location} }} />
	);
};

class App extends Component {
	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<Switch>
					<Route exact path="/login" component={LoginContainer}></Route>
					<RestrictedRoute state={this.props.login} path="/" component={Dashboard}></RestrictedRoute>
				</Switch>
			</MuiThemeProvider>
		);
  	}
}

export default withRouter(connect(state => {return {login: state.login}})(App));