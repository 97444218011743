const ReportHelpers = {
    formatCurrency: function(number){
        return number.toLocaleString("en-gb", {
            style: "currency",
            currency: "GBP"
        });
    },
    formatNumber: function(number){
        return number.toLocaleString("en-gb");
    },
    sum: function(items, key) {
        return items.reduce((a, b) => a + (b[key] || 0), 0);
    } 
}
export default ReportHelpers;