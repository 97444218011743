import types from './types';

const INITIAL_STATE = {
    touchScreenOrders: {
        "Overall": {
          "TotalBaskets": 0,
          "AverageBasketTotal": .0,
          "BasketsTotal": .0
        },
        "ByDay": [],
        "ByHour": [],
        "ByStore": [],
        "DbMs": 0
      },
    loading: false
};

const touchScreenOrdersReportReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.RECEIVE_TOUCH_SCREEN_ORDERS_STATS: {
            return {
                ...state,
                touchScreenOrders: action.data.touchScreenOrders,
                loading: action.data.loading
            };
        }
        case types.REQUEST_TOUCH_SCREEN_ORDERS_STATS: {
            return {
                ...state,
                loading: action.data.loading
            };
        }
        default:
            return state;
    }
}

export default touchScreenOrdersReportReducer;