import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  withStyles,
  Checkbox,
  CircularProgress,
  Button,
  ButtonBase,
  Drawer,
  Divider,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  FormControlLabel,
  Menu,
  IconButton,
  MenuItem,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import types from "./duck/types";
import classNames from "classnames";
import { checkRole } from "../../utils/userUtils";
import moment from "moment";
import PageTitle from "../../PageTitle";

const userColumns = [
  { col: "Name", sort: true },
  "Email",
  { col: "Roles", sort: true },
  { col: "LastLoggedIn", sort: true, title: "Last logged in" },
];

const styles = (theme) => ({
  root: {
    // padding: theme.spacing.unit * 3
  },
  title: {
    fontSize: "20px",
    color: "#999999",
  },
  loading: {
    textAlign: "center",
    padding: theme.spacing() * 2,
  },
  actions: {
    "& button": {
      textDecoration: "underline",

      "& + button": {
        paddingLeft: "5px",
      },
    },
  },
  drawer: {
    width: "25%",

    [theme.breakpoints.down("md")]: {
      width: "50%",
    },

    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  drawerTitle: {
    fontSize: "20px",
    color: theme.palette.common.white,
  },
  drawerField: {
    padding: theme.spacing() * 2,
    paddingBottom: 0,

    "& + .drawerField": {
      paddingTop: theme.spacing() * 2,
    },
  },
  search: {
    maxWidth: "0",
    transition: "max-width 0.5s ease",
  },
  searchSlide: {
    maxWidth: "100%",
  },
});

function displayColumn(user, column) {
  var data = user[column.col || column];

  if (Array.isArray(data))
    return data.reduce((prev, curr) => prev + ", " + curr.Role, "").substr(2);

  //Format dates



  var date = moment(data);
  if (date.isValid()) {
      if (date>moment('1/1/2020')) {
        return date.format("HH:mm DD/MM/YYYY");
      }

  }

  if (moment.isMoment(data)) {
    return "Never";
  }

  return data;
}

const UsersRow = (props) => {
  const {
    state,
    user,
    onSelect,
    onEdit,
    onSuspend,
    onDelete,
    classes,
    isEditor,
    currentUser
  } = props;

  var isSelected = state.selected.indexOf(user.UserId) !== -1;

  return (
    <TableRow>
      <TableCell padding="checkbox">
       {!user.IsOpsUser &&  <Checkbox
          color="primary"
          checked={isSelected}
          onChange={(e) => onSelect(e)}
        />}
      </TableCell>

      {userColumns.map((column) => (
        <TableCell key={column.col || column}>
          {displayColumn(user, column)}
        </TableCell>
      ))}

      <TableCell className={classes.actions}>
        {isEditor && !user.IsOpsUser &&  (
          <ButtonBase disableRipple={true} onClick={onEdit}>
            Edit
          </ButtonBase>
        )}

        {!currentUser && (
          <ButtonBase disableRipple={true} onClick={onSuspend}>
            {user.IsSuspended ? "Unsuspend" : "Suspend"}
          </ButtonBase>
        )}

       {!user.IsOpsUser && <ButtonBase disableRipple={true} onClick={onDelete}>
          Delete
        </ButtonBase>}
      </TableCell>
    </TableRow>
  );
};

UsersRow.propTypes = {
  user: PropTypes.object,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onSuspend: PropTypes.func,
  onDelete: PropTypes.func,
  classes: PropTypes.object,
  isEditor: PropTypes.bool
};

const DeleteDialog = (props) => {
  const { open, onClose, onAccept, multiple } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Are you sure you want to delete {multiple ? "these users" : "this user"}
        ?
      </DialogTitle>

      <DialogActions>
        <Button onClick={onAccept}>Yes</Button>
        <Button onClick={onClose}>No</Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  multiple: PropTypes.bool,
};

const EditDrawer = (props) => {
  const {
    user,
    open,
    onClose,
    onSave,
    classes,
    roles,
    onUserSave,
    newUser,
    errorCause,
    currentUser,
  } = props;

  function onChange(ev) {
    user[ev.target.name] = ev.target.checked || ev.target.value;
  }

  function onChangeRole(ev) {
    user.Roles = ev.target.value
      .filter((empty) => empty !== "")
      .map((selected) => roles.filter((role) => role.RoleId === selected)[0]);
    onUserSave(user);
  }

  return (
    //Apply the class to the paper within the drawer
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawer }}
    >
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography component="p" className={classes.drawerTitle}>
              {newUser ? "New user" : `Editing user ${user && user.Name}`}
            </Typography>
          </Toolbar>
        </AppBar>

        <Divider />

        <Grid container component="form" onSubmit={onSave}>
          {errorCause === "failed" && (
            <Grid item xs={12}>
              <Typography className={classes.newUserError} color="error">
                An unknown error occured, please try again
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} className={classes.drawerField}>
            <TextField
              name="Name"
              autoComplete="off"
              required
              fullWidth
              label="Name"
              placeholder="Name"
              variant="outlined"
              defaultValue={user && user.Name}
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12} className={classes.drawerField}>
            <TextField
              name="Email"
              type="email"
              autoComplete="off"
              required
              fullWidth
              label="Email"
              placeholder="Email Address"
              variant="outlined"
              defaultValue={user && user.Email}
              onChange={onChange}
              error={errorCause === "exists"}
              helperText={
                errorCause === "exists" ? "Email address is already in use" : ""
              }
            />
          </Grid>

          {newUser && (
            <Grid item xs={12} className={classes.drawerField}>
              <TextField
                name="Password"
                type="password"
                autoComplete="off"
                required
                fullWidth
                label="Password"
                placeholder="Password"
                variant="outlined"
                onChange={onChange}
              />
            </Grid>
          )}

          {!currentUser && (
            <Grid item xs={12} className={classes.drawerField}>
              <TextField
                name="Password"
                type="password"
                autoComplete="off"
                fullWidth
                label="Change Password"
                placeholder="Password"
                variant="outlined"
                onChange={onChange}
              />
            </Grid>
          )}

          {!newUser && !currentUser && (
            <Grid item xs={12} className={classes.drawerField}>
              <FormControlLabel
                label="Is Suspended?"
                control={
                  <Checkbox
                    color="primary"
                    name="IsSuspended"
                    defaultChecked={user && user.IsSuspended}
                    onChange={onChange}
                  />
                }
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.drawerField}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="roles" shrink>
                User Roles
              </InputLabel>

              <Select
                fullWidth
                multiple
                displayEmpty
                value={user && user.Roles.map((role) => role.RoleId)}
                onChange={onChangeRole}
                renderValue={(selected) => {
                  //Fake placeholder
                  if (selected.length === 0) {
                    return <em>No Roles Assigned</em>;
                  }

                  return selected
                    .map(
                      (s) => roles.filter((role) => role.RoleId === s)[0].Role
                    )
                    .join(",");
                }}
                input={<OutlinedInput notched id="roles" labelWidth={80} />}
              >
                {roles &&
                  roles.map((role) => (
                    <MenuItem key={role.RoleId} value={role.RoleId}>
                      {role.Role}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.drawerField}>
            <Grid container justify="space-between">
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </Grid>

              <Grid item>
                <Button variant="contained" color="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

EditDrawer.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  classes: PropTypes.object,
};

//Move everything in to one place for sorting, paging and filtering the users
function sortPageFilter(users, state) {
  const {
    sortColumn,
    sortDirection,
    rowsPerPage,
    page,
    searchPhrase,
    showOps,
  } = state;
  console.log("Ops=" + showOps);
  return users
    .map((user, index) => [user, index]) //Retain index
    .sort((a, b) => {
      var order =
        (a[0][sortColumn] > b[0][sortColumn] ? 1 : -1) *
        (sortDirection === "desc" ? -1 : 1);

      if (order !== 0) return order;

      //Use index instead
      return a[1] - b[1];
    })
    .map((a) => a[0]) //Map back
    .filter((user) => {
      for (var k in user)
        if (
          user[k] &&
          user[k]
            .toString()
            .toLowerCase()
            .indexOf(searchPhrase.toLowerCase()) !== -1
        )
          return true;
      return false;
    })
    .filter((b) => !b.IsOpsUser || showOps)
    .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage);
}

class UsersComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      sortColumn: "LastLoggedIn",
      sortDirection: "desc",
      rowsPerPage: 25,
      page: 0,
      showDeleteDialog: false,
      onDeleteDialogAccept: null,
      showEditDrawer: false,
      editingUser: null,
      showSearch: false,
      showMenu: false,
      menuAnchorElement: null,
      searchPhrase: "",
      showNewDrawer: false,
      showOps: false,
      newUser: {
        Roles: [],
      },
    };
  }

  componentDidMount() {
    this.props.updateTitle("Users");
    this.props.getUsers();
    this.props.getRoles();
  }

  //Checkbox selection
  onSelectAll = (event) => {
    const { users } = this.props;

    if (event.target.checked) {
      this.setState({ selected: users.filter(a=>!a.IsOpsUser).map((user) => user.UserId) });
      return;
    }

    this.setState({ selected: [] });
  };

  onSelect = (ev, user) => {
    const { selected } = this.state;

    if (ev.target.checked) {
      this.setState({
        selected: [...selected, user.UserId],
      });
      return;
    }

    //Deselect by removing the userid from the array
    selected.splice(selected.indexOf(user.UserId), 1);
    this.setState(this.state);
  };

  //Sorting
  onSort = (column) => {
    const { sortColumn, sortDirection } = this.state;

    this.setState({
      sortColumn: column.col,
      //Only change the direction of the column if it's the current sorting col
      sortDirection:
        sortColumn === column.col && sortDirection === "desc" ? "asc" : "desc",
    });
  };

  //Paging
  onChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: event.target.value,
      page: 0,
    });
  };

  onChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  //User actions
  onUserEdit = (user) => {
    //Open the edit drawer and create a clone of the user being edited
    this.setState({
      showEditDrawer: true,
      editingUser: Object.assign({}, user),
    });
  };

  onUserSuspend = (user) => {
    this.props.toggleSuspendUser(user);
  };

  onUserDelete = (user) => {
    this.setState({
      showDeleteDialog: true,
      onDeleteDialogAccept: (confirmed) => {
        if (confirmed) this.props.deleteUser(user);
      },
    });
  };

  //Close delete dialog
  onCloseDialog = () => {
    this.setState({ showDeleteDialog: false });
  };

  //Edit drawer
  onDrawerClose = () => {
    //Was setting editingUser to null but causes text etc to dissapear when drawer animates when closing
    this.setState({ showEditDrawer: false });
  };

  onDrawerSave = (ev) => {
    ev.preventDefault();
    this.props.editUser(this.state.editingUser);
    this.onDrawerClose();
  };

  //New drawer
  onNewUser = () => {
    this.setState({
      showNewDrawer: true,
      newUser: {
        Roles: [],
      },
    });
  };

  onCreateOps = () => {
    this.props.createOpsUsers();
  };

  onNewDrawerClose = () => {
    //Was setting editingUser to null but causes text etc to dissapear when drawer animates when closing
    this.setState({ showNewDrawer: false });
    this.props.resetErrors();
  };

  onNewDrawerSave = (ev) => {
    ev.preventDefault();

    this.props.newUser(this.state.newUser);
    this.onNewDrawerClose();
  };

  //Options
  onSearchClick = () => {
    this.setState({ showSearch: !this.state.showSearch });
  };

  onMoreClick = (ev) => {
    this.setState({
      showMenu: !this.state.showMenu,
      menuAnchorElement: ev.currentTarget,
    });
  };

  onMenuItemClick = (type) => {
    var users = this.props.users
      .filter((user) => this.state.selected.indexOf(user.UserId) > -1)
      .map((user) => user.UserId);

    //Don't bother
    if (!users.length) return;

    //Use duck types to determine what to do
    switch (type) {
      case types.SUSPEND_USER: {
        this.props.massSuspendUsers(users);
        break;
      }

      case types.UNSUSPEND_USER: {
        this.props.massUnsuspendUsers(users);
        break;
      }

      case types.DELETE_USER: {
        this.setState({
          showDeleteDialog: true,
          onDeleteDialogAccept: (confirmed) => {
            if (confirmed) this.props.massDeleteUsers(users);
          },
        });
        break;
      }

      default:
        return;
    }

    this.setState({ showMenu: false });
  };

  render() {
    const {
      classes,
      users,
      loading,
      login,
      roles,
      newUserError,
      newUserErrorCause,
      creatingOpsUser,
    } = this.props;
    const {
      sortColumn,
      sortDirection,
      rowsPerPage,
      page,
      showDeleteDialog,
      showEditDrawer,
      editingUser,
      showSearch,
      showMenu,
      menuAnchorElement,
      onDeleteDialogAccept,
      selected,
      showNewDrawer,
      newUser,
      showOps,
    } = this.state;

    return (
      <div className={classes.root}>
        <Toolbar variant="dense">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item xd="auto">
                  <PageTitle />
                </Grid>

                <Grid item xs>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onNewUser}
                  >
                    Add User
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    onClick={this.onCreateOps}
                    disabled={creatingOpsUser}
                  >
                    Create Ops users
                  </Button>{" "}
                  {creatingOpsUser
                    ? "Creating new Operations users.. This may take a short while."
                    : ""}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md="auto">
              <Grid container alignItems="baseline" justify="flex-end">
                <Grid item xs>
                  <FormControlLabel
                    label="Show Ops users"
                    control={
                      <Checkbox
                        checked={showOps}
                        label={"Show ops"}
                        onChange={(ev) => {
                          this.setState({ showOps: ev.target.checked });
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs="auto">
                  <IconButton onClick={this.onSearchClick}>
                    <SearchIcon />
                  </IconButton>
                </Grid>

                <Grid
                  item
                  xs
                  className={classNames(
                    classes.search,
                    showSearch && classes.searchSlide
                  )}
                >
                  <TextField
                    type="search"
                    onChange={(ev) => {
                      this.setState({ searchPhrase: ev.target.value });
                    }}
                  />
                </Grid>

                <Grid item xs="auto">
                  <IconButton onClick={this.onMoreClick}>
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    open={showMenu}
                    anchorEl={menuAnchorElement}
                    onClose={this.onMoreClick}
                    keepMounted
                  >
                    <MenuItem
                      onClick={() => this.onMenuItemClick(types.SUSPEND_USER)}
                    >
                      Suspend
                    </MenuItem>

                    <MenuItem
                      onClick={() => this.onMenuItemClick(types.UNSUSPEND_USER)}
                    >
                      Unsuspend
                    </MenuItem>

                   <MenuItem
                      onClick={() => this.onMenuItemClick(types.DELETE_USER)}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox onChange={this.onSelectAll} />
              </TableCell>

              {userColumns.map((column) => (
                <TableCell key={column.col || column}>
                  <TableSortLabel
                    active={sortColumn === column.col && column.sort}
                    direction={sortDirection}
                    onClick={() => column.sort && this.onSort(column)}
                  >
                    {column.title || column.col || column}
                  </TableSortLabel>
                </TableCell>
              ))}

              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  className={classes.loading}
                  colSpan={userColumns.length + 2}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              sortPageFilter(users, this.state).map((user) => (
                <UsersRow
                  key={user.UserId}
                  state={this.state}
                  user={user}
                  isEditor={checkRole(login, "Editor")}
                  onSelect={(e) => this.onSelect(e, user)}
                  onEdit={() => this.onUserEdit(user)}
                  onSuspend={() => this.onUserSuspend(user)}
                  onDelete={() => this.onUserDelete(user)}
                  classes={classes}
                  currentUser={login.user.Email === user.Email}
                />
              ))
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          page={page}
          count={users.length}
          onChangePage={this.onChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 75]}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
        />

        <DeleteDialog
          open={showDeleteDialog}
          multiple={selected.length > 0}
          onAccept={() => {
            onDeleteDialogAccept(true);
            this.onCloseDialog();
          }}
          onClose={this.onCloseDialog}
        />

        <EditDrawer
          user={editingUser}
          open={showEditDrawer}
          onSave={this.onDrawerSave}
          onClose={this.onDrawerClose}
          onUserSave={(user) => this.setState({ editingUser: user })}
          classes={classes}
          roles={roles}
          currentUser={editingUser && login.user.Email === editingUser.Email}
        />

        <EditDrawer
          user={newUser}
          open={showNewDrawer || newUserError}
          onSave={this.onNewDrawerSave}
          onClose={this.onNewDrawerClose}
          onUserSave={(user) => this.setState({ newUser: user })}
          classes={classes}
          roles={roles}
          newUser={true}
          errorCause={newUserErrorCause}
        />
      </div>
    );
  }
}

export default withStyles(styles)(UsersComponent);
