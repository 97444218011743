import types from "./types"

const INITIAL_STATE = {
	loading: true,
	embedToken: "",
	embedUrl: "",
	reportId: ""
};

const reportReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
		case types.RETRIEVE_REPORT: {
			return {
				...state,
				loading: true
			}
		}

		case types.RECIEVE_REPORT: {
			return {
				...state,
				...action.data,
				loading: false
			}
		}

		default:
			return state;
	}
};

export default reportReducer;