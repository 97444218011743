import types from "./types";

const INITIAL_STATE = {
	title: "Dashboard",
	kpis: null,
	kpiValues: null,
	summaries: null,
	version: "",
	isSidebarDisabled:false
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.UPDATE_TITLE:
			return {
				...state,
				title: action.data
			};
			
		case types.GET_KPIS:
			return {
				...state,
				kpis: action.data,
				kpiValues: Object.keys(action.data).reduce((map, key) => {
					map[key] = action.data[key].Value;
					return map;
				}, {})
			}

		case types.GET_VERSION:
			return {
				...state,
				version: action.data
			}

		case types.GET_SUMMARIES:
			return {
				...state,
				summaries: action.data
			}

		case types.DISABLE_SIDEBAR:
			console.log("DISABLE_SIDEBAR: " + action.data);
			return {
				...state,
				isSidebarDisabled: action.data
			}

        default:
            return state;
    }
};

export default dashboardReducer;