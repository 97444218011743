import React from 'react';
import { withStyles } from '@material-ui/core';
import { Grid, Typography, Toolbar } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerHelper';
import ReportHelpers from '../ReportHelpers';
import ReactTable from 'react-table-v6'
import classNames from "classnames";
import moment from 'moment';
import PageTitle from '../../../PageTitle';

const styles = theme => ({
    root: {
		//padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 3}px`,
		//minWidth: "1300px"
	},
	reportArea: {
		paddingTop:`${theme.spacing() * 4}px`,
		paddingLeft: `${theme.spacing() * 3}px`,
		paddingRight: `${theme.spacing() * 3}px`,
	},
    title: {
        fontSize: "20px",
        color: "#999999"
	},
	toFromField: {
		textAlign: "right",
		color: "#999",
		fontWeight: "bold"
	},
	toFromFieldCaption: {
		marginRight: `${theme.spacing() * 2}px`
	},
	tableHeading: {
		color: "#999999",
		paddingBottom: theme.spacing() * 2
	},
    pointerCursor: {
        cursor: "pointer"
	},
	hidden: {
		display:"none"
	},
	stopClicks: {
		pointerEvents:"none"
	},   
	loading: {
        textAlign: "center",
        padding: theme.spacing() * 2
	}, 
	tableDefaultHeaderOrFooter: {
		textAlign: "left",
		fontWeight: "bold"
	},
	tableNumericHeaderOrFooter: {
		textAlign: "right",
		fontWeight: "bold"
	},
	tableFooterTotal: {
		textAlign: "right",
		fontWeight: "bold"
	},
	tableNumericCell: {
		textAlign: "right"
	},
	tableDefs: {
		overflowX: "auto",
		height: "82vh"
	},
	tableMargin: {
		marginRight: "10px",
	},
	datePickerPopper: { zIndex: 3000 }
});

class EstateEventsReportComponent extends React.Component {
	constructor(props) {
		super(props);
		let dt = new Date();
		let ed = moment(dt).toDate();
		let sd =  moment(dt).subtract(7, "days").toDate();
		let md = moment(dt).subtract(3, "months").toDate();
		let xd = dt;
		this.state = { 
			maxDate: xd,
			minDate: md,
			startDate: sd, 
			endDate: ed,
			actionSelected: -1
		};
	}
	componentDidMount() {
		// clear out old values
		this.props.estateEventsReport.estateStores = [];
		this.props.estateEventsReport.estateMenuItems = [];
		this.props.estateEventsReport.estateActions = [];
		this.props.updateTitle("Estate Events");
		this.props.getEstateActions(this.state.startDate,this.state.endDate);
	}

	itemClick = (table, selectedItem) => {
		if(table === "ACTION") {
			this.props.estateEventsReport.estateStores = [];
			this.props.estateEventsReport.estateMenuItems = [];
			if(selectedItem.includes("SKU") === true)
				this.props.getEstateMenuItems(this.state.startDate,this.state.endDate,selectedItem);
			this.props.getEstateStores(this.state.startDate,this.state.endDate,selectedItem);
		}
		else {
			console.log("TABLE REQ'D: " + table);
		}
	}

	setEndDate = (date) => {
		this.setState({endDate: date});
		this.update(this.state.startDate,date);
	}
	setStartDate = (date) => {
		this.setState({startDate: date});
		this.update(date,this.state.endDate);
	}

	update = (start, end) => {
		this.props.estateEventsReport.estateStores = [];
		this.props.estateEventsReport.estateMenuItems = [];
		this.props.estateEventsReport.estateActions = [];
		this.props.getEstateActions(start,end);
	}

	render() {
		const { classes } = this.props;
		const { startDate, endDate, minDate, maxDate } = this.state;
		const { estateActionsLoading, estateActions,  
				estateMenuItemsLoading, estateMenuItems, 
				estateStoresLoading, estateStores } = this.props.estateEventsReport;
	
		const actionsColumns = 
		[
			{
				Header: 'Action',
				accessor: 'Action', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
				Footer: <div className={classes.tableFooterTotal}>Total</div>
			},
			{
				Header: 'Occurrences',
				accessor: 'Occurrences', 
				headerClassName: classes.tableNumericHeaderOrFooter,
				className: classes.tableNumericCell,
				Cell: row => <span>{ReportHelpers.formatNumber(row.value)}</span>,
				Footer: info => {
					return <span className={classes.tableNumericHeaderOrFooter}>{ReportHelpers.formatNumber(estateActions.reduce((a, b) => a + (b.Occurrences || 0), 0))}</span>
				},
				width: 125
			},
		];

		const menuItemsColumns = 
		[
			{
				Header: 'SKU',
				accessor: 'SKU', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
				width: 110
			},
			{
				Header: 'Menu Item',
				accessor: 'MenuItemName', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
				Footer: <div className={classes.tableFooterTotal}>Total</div>
			},
			{
				Header: 'Occurrences',
				accessor: 'Occurrences', 
				headerClassName: classes.tableNumericHeaderOrFooter,
				className: classes.tableNumericCell,
				Cell: row => <span>{ReportHelpers.formatNumber(row.value)}</span>,
				Footer: info => {
					return <span className={classes.tableNumericHeaderOrFooter}>{ReportHelpers.formatNumber(estateMenuItems.reduce((a, b) => a + (b.Occurrences || 0), 0))}</span>
				},
				width: 125
			},
		];

		const storesColumns = 
		[
			{
				Header: 'Store Number',
				accessor: 'StoreNumber', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
				width: 125
			},
			{
				Header: 'Store Name',
				accessor: 'StoreName', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
				Footer: <div className={classes.tableFooterTotal}>Total</div>
			},
			{
				Header: 'Occurrences',
				accessor: 'Occurrences', 
				headerClassName: classes.tableNumericHeaderOrFooter,
				className: classes.tableNumericCell,
				Cell: row => <span>{ReportHelpers.formatNumber(row.value)}</span>,
				Footer: info => {
					return <span className={classes.tableNumericHeaderOrFooter}>{ReportHelpers.formatNumber(estateStores.reduce((a, b) => a + (b.Occurrences || 0), 0))}</span>
				},
				width: 125
			},
		];

		return (
            <div className={classes.root}>
			    <Toolbar variant="dense">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={6} md="auto">
							<PageTitle/>
                        </Grid>

						<Grid item xs={6}>
							<Grid container spacing={8} justify="flex-end" alignItems="center">
								<Grid item xs={"auto"} className={classes.toFromField}>
									<span className={classes.toFromFieldCaption}>From</span>
									<DatePicker 
										selected={startDate}
										onChange={date => this.setStartDate(date)}
										selectsStart
										minDate={minDate}
										maxDate={maxDate}
										startDate={startDate}
										endDate={endDate}
										dateFormat="dd/MM/yyyy"
										showPopperArrow={false}
										popperPlacement="bottom-end"
										customInput={<DatePickerInput />}
										/>
								</Grid>

								<Grid item xs={"auto"} className={classes.toFromField}>
									<span className={classes.toFromFieldCaption}>To</span>
									<DatePicker
										popperPlacement="bottom-end"
										selected={endDate}
										onChange={date => this.setEndDate(date)}
										selectsEnd
										startDate={startDate}
										endDate={endDate}
										minDate={startDate}
										maxDate={maxDate}
										dateFormat="dd/MM/yyyy"
										showPopperArrow={false}
										customInput={<DatePickerInput />}
										/>
                    			</Grid>
							</Grid>
						</Grid>
                    </Grid>
                </Toolbar>

				<Grid container className={classes.reportArea}>
					<Grid item xs={12} md={4}>
						<Typography variant="h6" className={classes.tableHeading}>Actions</Typography>

						<div className={classes.hidden}>ITEMS: {estateActions.length}</div>
						<ReactTable 
							className={classNames(classes.tableDefs, classes.tableMargin)}
							showPagination={false}					
							loading={estateActionsLoading}
							columns={actionsColumns}
							data={estateActions}
							getTdProps={(state, rowInfo, column, instance) => {
								return {
									onClick: (e, handleOriginal) => {
										this.setState({
											actionSelected: rowInfo.index
										});
										this.itemClick("ACTION",rowInfo.row.Action);
										if (handleOriginal) {
											handleOriginal()
										}
									},           
									style: {
										cursor: "pointer",
										background: rowInfo === undefined ? null : rowInfo.index === this.state.actionSelected ? '#eee' : '#fafafa',
									},
								}
							}}/>
					</Grid>

					<Grid item xs={12} md={4}>
						<Typography variant="h6" className={classes.tableHeading}>Menu Items</Typography>
						<div className={classes.hidden}>ITEMS: {estateMenuItems.length}</div>
						<ReactTable 					
							className={classNames(classes.tableDefs, classes.tableMargin)}
							showPagination={false}						
							loading={estateMenuItemsLoading}
							columns={menuItemsColumns}					
							data={estateMenuItems}/>
					</Grid>

					<Grid item xs={12} md={4}>
						<Typography variant="h6" className={classes.tableHeading}>Stores</Typography>
						<div className={classes.hidden}>ITEMS: {estateStores.length}</div>
						<ReactTable 
							className={classes.tableDefs}
							columns={storesColumns}
							showPagination={false}						
							loading={estateStoresLoading}
							data={estateStores}/>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styles)(EstateEventsReportComponent);