import React from 'react';
import { withStyles } from '@material-ui/core';
import { Grid, Toolbar } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerHelper';
import ReportHelpers from '../ReportHelpers';
import ReactTable from 'react-table-v6'
import classNames from "classnames";
import moment from 'moment';
import PageTitle from '../../../PageTitle';

const styles = theme => ({
    root: {
		//padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 3}px`,
		//minWidth: "1300px"
	},
	reportArea: {
		paddingTop:`${theme.spacing() * 4}px`,
		paddingLeft: `${theme.spacing() * 3}px`,
		paddingRight: `${theme.spacing() * 3}px`,
	},
    title: {
        fontSize: "20px",
        color: "#999999"
	},
	toFromField: {
		textAlign: "right",
		color: "#999",
		fontWeight: "bold"
	},
	toFromFieldCaption: {
		marginRight: `${theme.spacing() * 2}px`
	},
	tableHeading: {
		color: "#999999",
		paddingBottom: theme.spacing() * 2
	},
    pointerCursor: {
        cursor: "pointer"
	},
	hidden: {
		display:"none"
	},
	stopClicks: {
		pointerEvents:"none"
	},   
	loading: {
        textAlign: "center",
        padding: theme.spacing() * 2
	}, 
	tableDefaultHeaderOrFooter: {
		textAlign: "left",
		fontWeight: "bold"
	},
	tableNumericHeaderOrFooter: {
		textAlign: "right",
		fontWeight: "bold"
	},
	tableFooterTotal: {
		textAlign: "right",
		fontWeight: "bold"
	},
	tableNumericCell: {
		textAlign: "right"
	},
	tableDefs: {
		overflowX: "auto",
		height: "85vh"
	},
	tableMargin: {
	},
	datePickerPopper: { zIndex: 3000 }
});

class KioskMonitoringReportComponent extends React.Component {
	constructor(props) {
		super(props);
		let dt = new Date();
		let ed = moment(dt).toDate();
		let sd =  moment(dt).subtract(7, "days").toDate();
		let md = moment(dt).subtract(3, "months").toDate();
		let xd = dt;
		this.state = { 
			maxDate: xd,
			minDate: md,
			startDate: sd, 
			endDate: ed,
			actionSelected: -1
		};
	}
	componentDidMount() {
		// clear out old values
		this.props.updateTitle("Kiosk Monitoring");
		//this.props.getEstateActions(this.state.startDate,this.state.endDate);
	}
	setEndDate = (date) => {
		this.setState({endDate: date});
		this.update(this.state.startDate,date);
	}
	setStartDate = (date) => {
		this.setState({startDate: date});
		this.update(date,this.state.endDate);
	}
	update = (start, end) => {
		//this.props.getEstateActions(start,end);
	}

	render() {
		const { classes } = this.props;
		const { startDate, endDate, minDate, maxDate } = this.state;
	
		const columns = 
		[
			{
				Header: 'Name',
				accessor: 'Name', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
			},
			{
				Header: 'Terminal',
				accessor: 'Terminal', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
			},
			{
				Header: 'Days',
				accessor: 'Days', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
			},
			{
				Header: 'Open %',
				accessor: 'Open', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
			},
			{
				Header: 'Deranged Items',
				accessor: 'DerangedItems', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
			},
			{
				Header: 'Printer Issue',
				accessor: 'PrinterIssue', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
			},
			{
				Header: 'Disabled %',
				accessor: 'Disabled', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
			},
			{
				Header: 'Available %',
				accessor: 'Available', 
				headerClassName: classes.tableDefaultHeaderOrFooter,
			},
			{
				Header: 'Total Orders',
				accessor: 'TotalOrders', 
				headerClassName: classes.tableNumericHeaderOrFooter,
			},
			{
				Header: 'Average Basket',
				accessor: 'AverageBasket', 
				headerClassName: classes.tableNumericHeaderOrFooter,
			},
		];

		return (
            <div className={classes.root}>
			    <Toolbar variant="dense">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={8} md="auto">
							<PageTitle/>
                        </Grid>

						<Grid item xs={4}>
							<Grid container flexWrap="nowrap" spacing={8} justify="flex-end" alignItems="center">
								<Grid item xs={6} className={classes.toFromField}>
									<span className={classes.toFromFieldCaption}>From</span>
									<DatePicker 
										selected={startDate}
										onChange={date => this.setStartDate(date)}
										selectsStart
										minDate={minDate}
										maxDate={maxDate}
										startDate={startDate}
										endDate={endDate}
										dateFormat="dd/MM/yyyy"
										showPopperArrow={false}
										popperPlacement="bottom-end"
										customInput={<DatePickerInput />}
										/>
								</Grid>

								<Grid item xs={6} className={classes.toFromField}>
									<span className={classes.toFromFieldCaption}>To</span>
									<DatePicker
										popperPlacement="bottom-end"
										selected={endDate}
										onChange={date => this.setEndDate(date)}
										selectsEnd
										startDate={startDate}
										endDate={endDate}
										minDate={startDate}
										maxDate={maxDate}
										dateFormat="dd/MM/yyyy"
										showPopperArrow={false}
										customInput={<DatePickerInput />}
										/>
                    			</Grid>
							</Grid>
						</Grid>
                    </Grid>
                </Toolbar>
				<Grid container className={classes.reportArea}>
					<Grid item xs={12}>
						<ReactTable 
							className={classNames(classes.tableDefs, classes.tableMargin)}						
							showPagination={true}
							columns={columns}/>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styles)(KioskMonitoringReportComponent);