import Creators from './actions';
import DuckApiCalls from "../../../../utils/duckApiCalls";
import UnauthorizedError from "../../../../utils/unauthorizedError";
import moment from 'moment';

const requestItemsStatsAction = Creators.requestItemsStatsActions;
const receiveItemsStatsAction = Creators.receiveItemsStatsActions;

const getItemsStats = (dateFrom, dateTo) => {
    return (dispatch, getState) => {
        dispatch(requestItemsStatsAction({
            loading: true
        }));
        new DuckApiCalls().getData("reporting/items?from=" + moment(dateFrom).format('DD-MM-YYYY') + "&to=" + moment(dateTo).format('DD-MM-YYYY')) 
        .then(response => {
			if(response.status === 401)
				throw new UnauthorizedError(dispatch);
            return response.json()
            .then(payload => {
                dispatch(receiveItemsStatsAction({
                    items: payload,
                    loading: false
                }));
            });
        })
        .catch(console.error);
    };
};

export default {
    getItemsStats
}