import React from "react"
import { connect } from 'react-redux';
import { withStyles, CircularProgress, Typography, Grid, Switch, FormControlLabel, MenuItem, Button, Accordion, AccordionSummary, AccordionDetails, Radio, Paper, AppBar, Collapse, IconButton, TextField, useRadioGroup } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import * as moment from 'moment';
import 'moment-timezone';
import { reduxForm, Field, Form, FieldArray, formValueSelector } from "redux-form";
import RemoveCircle from "@material-ui/icons/RemoveCircle"

//Our stuff
import dashboardOperations from "../../duck/operations"
import promotionOperations from "./duck/operations"
import { ReduxTextField, ReduxSKUList, ReduxSelect, ReduxRadioGroup, ReduxSwitch, ReduxDateField, ReduxTimeField, ReduxNullField, ReduxHasSelect } from "../../utils/reduxForms";
import StoreGroupDialog from "./components/StoreGroupDialog";
import PageTitle from "../../PageTitle";

const styles = theme => ({
	title: {
		fontSize: 26,
		color: "#999999"
	},
	circle: {
		borderRadius: "50%",
		backgroundColor: "#C3C1C1",
		color: "#FFF",
		width: 20,
		height: 20,
		textAlign: "center",
		lineHeight: "20px"
	},
	label: {
		color: "#333",
		fontSize: 20
	},
	desc: {
		marginTop: "-4px",
		width: "90%"
	},
	step: {
		borderBottom: "1px solid #DDD",
		paddingTop: theme.spacing() * 2,
		paddingBottom: theme.spacing() * 2,
		margin: 0
	},
	lastStep: {
		marginBottom: 60,
		paddingTop: theme.spacing() * 2
	},
	back: {
		borderBottom: "1px solid #DDD",
		paddingBottom: theme.spacing() * 2
	},
	save: {
		padding: 8
	},
	everyText: {
		fontWeight: "bold",
		marginBottom: "-16px",
		marginTop: "8px"
	},
	expandTitle: {
		marginBottom: 10
	},
	addNewQualifier: {
		marginTop: theme.spacing() * 2
	},
	error: {
		fontSize: "0.75rem",
		color: theme.palette.error.main,
		margin: "8px 0 0 0",
		fontFamily: "Roboto, Helvetica, Arial, sans-serif"
	},
	paperSpacing: {
		padding: 16
	},
	appBar: {
		top: 'auto',
		bottom: 0,
	},
	collapse: {
		width: "100%"
	},
	expansionSummary: {
		margin: "0 !important",
		"& > :last-child": {
			paddingRight: "0"
		}
	},
	expansionError: {
		border: "1px solid red"
	},
	menu: {
		maxHeight: 500
	}
});

//Validation
const required = val => val || val === 0 ? undefined : "Field is required";
const qualifier = val => val && val.length ? undefined : "At least one qualifier must be added";
const behaviour = val => val != null ? undefined : "A promotional behaviour type must be selected";
const qualifierType = val => val !== undefined ? undefined : "A qualifier type must be selected";
const sku = val => val !== undefined && val.indexOf("D") === 0 ? undefined : "Promotions SKUs should begin with 'D'";
const rewards = (val, allValues) => {
	var groups = null;

	if (allValues.InnerPromotion && allValues.InnerPromotion.Rewards) groups = allValues.InnerPromotion.Rewards.SKUs;

	if (val === "2")
		if (groups && groups.length > 0) {
			var skus = allValues.InnerPromotion.Rewards.SKUs[0].SKUs;

			if (!skus)
				return "No SKUs provided";

			var nonEmpty = skus.filter(a => a !== "");

			if (nonEmpty.length <= 0)
				return "Reward SKU list(s) selected but no SKUs added";
		} else
			return "Reward SKU list(s) selected but no reward groups created";

	if (val === "1" && allValues.InnerPromotion)
		if (allValues.InnerPromotion.Rewards.Categories === undefined || allValues.InnerPromotion.Rewards.Categories.length <= 0)
			return "'All items in these categories' selected but no categories have been chosen";

	return undefined;
}

const endDate = (val, allValues) => {
	//End date can be null

	if (!allValues.InnerPromotion)
		return undefined;

	if (!allValues.InnerPromotion.Schedule)
		return undefined;

	if (!allValues.InnerPromotion.Schedule.EndDate)
		return undefined;

	if (!allValues.InnerPromotion.Schedule.StartDate)
		return "Start date must be set for end date to take effect";

	if (allValues.InnerPromotion.Schedule.StartDate > allValues.InnerPromotion.Schedule.EndDate)
		return "End date must be after start date";

	return undefined;
}

const Qualifiers = (props) => {
	const { fields, classes, meta } = props;

	return <div>
		{fields.map((member, index) => (
			<Accordion key={index}>
				<AccordionSummary classes={{ content: classes.expansionSummary }}>
					<Grid container alignItems="center" className={meta.error ? classes.expansionError : ""}>
						<Grid item xs>
							<Typography>
								{"Qualifier " + (index + 1)}
							</Typography>
						</Grid>

						<Grid item>
							{index !== 0 && <IconButton onClick={() => { fields.splice(index, 1); }}>
								<RemoveCircle style={{ color: "#CC0000" }}></RemoveCircle>
							</IconButton>}
						</Grid>
					</Grid>
				</AccordionSummary>

				<AccordionDetails>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Paper className={classes.paperSpacing}>
								<Typography component="h2" variant="subtitle1">
									Qualifier Type
								</Typography>

								<Field name={`${member}.Type`} component={ReduxRadioGroup} validate={qualifierType} error={classes.error}>
									<RadioText validate={required} label="Minimum product value" value="0" text={`${member}.MinProductValue`} />
									<RadioText validate={required} label="Minimum basket value" value="1" text={`${member}.MinBasketValue`} />

									<RadioText validate={required} label="Card scan" value="2" renderElement={(props) => {
										return (<Field name={props.checked ? `${member}.Card` : ""} component={ReduxSelect}>
											<MenuItem value="">Please select</MenuItem>
											<MenuItem value="1">More Card (9826135x..x)</MenuItem>
											<MenuItem value="2">No Card</MenuItem>
										</Field>);
									}} />

									<RadioText validate={required} label="SKU list" value="3" showWhenChecked renderElement={(props) => {
										return (<Field name={props.checked ? `${member}.SKUs` : ""} component={ReduxSKUList}></Field>)
									}} />
								</Field>
							</Paper>
						</Grid>

						<Grid item xs={12}>
							<Paper className={classes.paperSpacing}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h2" variant="subtitle1">
											Qualifier Details
										</Typography>
									</Grid>

									<Grid item>
										<Typography>Customise the browse by promotions feature for each qualifier</Typography>
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Field name={`${member}.Title`} component={ReduxTextField} props={{ fullWidth: true }} label="Item list title"></Field>
									</Grid>

									<Grid item xs={12}>
										<Field name={`${member}.Description`} component={ReduxTextField} props={{ fullWidth: true }} label="Item list description"></Field>
									</Grid>

									<Grid item xs={12}>
										<Field name={`${member}.ButtonText`} component={ReduxTextField} props={{ fullWidth: true }} label="Basket button text" placeholder="e.g. Add Muffin"></Field>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		))}

		{meta.error && <p className={classes.error}>{meta.error}</p>}

		<Button className={classes.addNewQualifier} variant="outlined" onClick={() => { fields.push({}); }}>Add new qualifier</Button>
	</div>;
};

const Rewards = (props) => {
	var { fields } = props;

	//Add a default box
	if (!fields.length)
		fields.push({ Title: "", SKUs: [] });

	return (
		<Grid container spacing={2} alignItems="flex-end">
			{fields.map((member, index) => (
				<Grid key={index} item xs={true}>
					<Grid item>
						<Grid container alignItems="flex-end">
							<Grid item xs>
								<Typography paragraph>
									Reward list {index + 1} (one SKU per line)
								</Typography>
							</Grid>

							<Grid item>
								{index !== 0 && <IconButton onClick={() => fields.splice(index, 1)} size="small">
									<RemoveCircle style={{ color: "#CC0000" }}></RemoveCircle>
								</IconButton>}
							</Grid>
						</Grid>
					</Grid>

					<Grid item>
						<Field name={`${member}.Title`} component={ReduxTextField} props={{ label: "Item list title", fullWidth: true, placeholder: "Teatime meal deal", margin: "dense" }}></Field>
						<Field name={`${member}.SKUs`} component={ReduxSKUList}></Field>
					</Grid>
				</Grid>
			))}

			<Grid item xs>
				<Button variant="outlined" onClick={() => fields.push({ Title: "", SKUs: [] })}>Add another reward</Button>
			</Grid>
		</Grid>
	);
};

const RadioText = (props) => {
	//Fix checked not being passed down anymore
	const radioGroup = useRadioGroup();
	const checked = radioGroup.value === props.value;
	props = { ...props, checked };

	const { textAfter, renderElement, validate, showWhenChecked, ...otherProps } = props;

	return (
		<div>
			<FormControlLabel {...otherProps} control={<Radio color="primary" />} label={props.label}></FormControlLabel>

			{(showWhenChecked ? props.checked : true) && props.renderElement ? props.renderElement(props) : props.text ? props.checked ? <Field validate={validate} name={props.text} component={ReduxTextField}></Field> : <TextField /> : ""}

			{props.textAfter && <Typography style={{ display: "inline-block", marginLeft: 5 }}>{props.textAfter}</Typography>}
		</div>
	);
}

const Every = (props) => {

	const { input } = props;
	const { onChange } = input;

	function changed(ev, checked) {

		var days = typeof (input.value) === "string" ? input.value.split(",").map(e => { return parseInt(e) }).filter((value, index, self) => { return self.indexOf(value) === index; }) : input.value || [];

		if (days.length > 7)
			days = [1, 2, 3, 4, 5, 6, 0];

		if (checked)
			days.push(ev.target.value);
		else {
			var idx = days.indexOf(parseInt(ev.target.value));
			if (idx !== -1)
				days.splice(idx, 1);
			else
				console.error(`Promotions: Couldn't find index of ${ev.target.value}`);
		}

		onChange(days.join(","));

	}

	console.debug("Promotions - Every: " + input.value);

	return (
		[1, 2, 3, 4, 5, 6, 0].map((day) => (
			<Grid key={day} item>
				<FormControlLabel value={`${day}`} control={<Switch checked={input.value.indexOf(day) !== -1} onChange={changed} color="primary" />} label={moment().day(day).format("ddd")}></FormControlLabel>
			</Grid>
		))
	)
};

const StoreList = (props) => {
	const radioGroup = useRadioGroup();
	const checked = radioGroup.value === props.value;
	props = { ...props, checked };
	return (
		<div>
			<Grid item xs={12}>
				<FormControlLabel {...props} control={<Radio color="primary" />} label={props.label}></FormControlLabel>
				<Grid item xs={6}>
					<Field name={props.checked ? props.list : ""} props={{ rows: 8 }} component={ReduxSKUList}></Field>
				</Grid>
			</Grid>
		</div>
	);
};

class PromotionsPage extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			showGroupDialog: false,
			isSubmitting: false
		};
	}

	componentDidMount() {
		// [MR-96] May move this to somewhere more central 
		// so the time zones applies to more areas 
		// e.g. users last login
		moment.tz.setDefault("Europe/London");
		var id = this.props.match.params.promotion;
		console.log(`Promotion id: ${id}`);
		this.props.disableSidebar(true);
		this.props.getPromotions(id, (s) => { this.props.disableSidebar(false); });
		this.props.getCategories();
		this.props.getStores();
	}

	cancel() {
		this.props.history.push("/promotions");
	}

	submit(values) {
		//Reset category if it's not selected

		if (values.RewardType === "2" && values.InnerPromotion.Rewards.Categories)
			values.InnerPromotion.Rewards.Categories = null;

		if (values.InnerPromotion &&
			values.InnerPromotion.Rewards &&
			values.InnerPromotion.Rewards.SKUs &&
			values.InnerPromotion.Rewards.SKUs.length == 1 &&
			values.InnerPromotion.Rewards.SKUs[0].SKUs &&
			values.InnerPromotion.Rewards.SKUs[0].SKUs.length == 0) {
			values.InnerPromotion.Rewards = null;
			//debugger;
		}

		if (values.InnerPromotion &&
			values.InnerPromotion.Rewards &&
			values.InnerPromotion.Rewards.SKUs &&
			values.InnerPromotion.Rewards.SKUs.length == 1 &&
			values.InnerPromotion.Rewards.SKUs == null) {
			values.InnerPromotion.Rewards = null;
			//debugger;
		}

		// If no additional items required to complete the promotion ensure the rewards element is null
		if (values.RewardType === "")
			values.InnerPromotion.Rewards = null;

		if (values.InnerPromotion.Schedule && typeof (values.InnerPromotion.Schedule.Every) === "string")
			values.InnerPromotion.Schedule.Every = values.InnerPromotion.Schedule.Every.split(",").filter(a => a !== '');

		//If all stores selected then set it to null
		if (values.StoreType === "" || values.StoreType == null)
			values.InnerPromotion.Stores = null;
		else
			values.InnerPromotion.Stores.ExcludingStores = values.StoreType === "1";

		//Temp radiobutton vars
		delete values.StoreType;
		delete values.RewardType;

		if (values.InnerPromotion.Qualifiers)
			values.InnerPromotion.Qualifiers.forEach(function (q) {
				delete q.Type;
			});

		//Set upsell to null if it's not selected
		if (values.InnerPromotion.Upsell) {
			if (!values.InnerPromotion.Upsell.Selected)
				values.InnerPromotion.Upsell = null;
		}

		this.setState({ isSubmitting: true });
		this.props.disableSidebar(true);

		//Create if there's no promotion id
		if (values.PromotionId)
			this.props.updatePromotion(values, (s) => {
				this.setState({ isSubmitting: false });
				this.props.disableSidebar(false);
				this.props.history.push("/promotions");
			});
		else
			this.props.createPromotion(values, (s) => {
				this.setState({ isSubmitting: false });
				this.props.disableSidebar(false);
				this.props.history.push("/promotions");
			});

		//Back to list
		//this.props.history.push("/promotions");

	}

	closeGroupDialog() {
		this.setState({
			showGroupDialog: false
		});
	}

	openGroupDialog() {
		this.setState({
			showGroupDialog: true
		});

		this.props.getGroups();
	}

	render() {

		// submitting? Anyone?
		const { loading, classes, initialValues, submitting, valid, pristine, initialized, upsellSelected, promotions, categories } = this.props;

		const { showGroupDialog, isSubmitting } = this.state;

		return (
			<div>
				{loading || isSubmitting || (!initialized && this.props.match.params.promotion !== "new") ? (
					<Grid container justify="center">
						<CircularProgress></CircularProgress>
					</Grid>
				) : (




					<Form


						onSubmit={this.props.handleSubmit(values => this.submit(values))}>
						<Grid container className={classes.back} alignItems="center" spacing={2}>
							<Grid item xd="auto">
								<PageTitle />
							</Grid>

							<Grid item>
								<Typography component="h2" className={classes.title}>
									{initialValues ? " :: Editing promotion: " + initialValues.Name : " :: Create a promotion"}
								</Typography>
							</Grid>
						</Grid>

						<Grid container spacing={2}

							alignItems="center" className={classes.step}>
							<Grid item>
								<div className={classes.circle}>1</div>
							</Grid>

							<Grid item>
								<Typography component="h3" className={classes.label}>
									Promotion name
								</Typography>
							</Grid>

							<Grid item>
								<Field inputProps={{ size: 50, maxLength: 200 }} name="Name" validate={required} type="text" component={ReduxTextField} />
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.step}>
							<Grid item>
								<div className={classes.circle}>2</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Promotion details
										</Typography>
									</Grid>

									<Grid item>
										<Typography>Promotion information when shown in the basket?</Typography>
									</Grid>
								</Grid>

								<Grid container spacing={8} alignItems="center">
									<Grid item xs={3}>
										<Typography component="p">
											Promotion text
										</Typography>
									</Grid>

									<Grid item>
										<Field inputProps={{ size: 50, maxLength: 200 }} name="InnerPromotion.DiscountText" validate={required} type="text" component={ReduxTextField} props={{ margin: "dense" }} />
									</Grid>
								</Grid>

								<Grid container spacing={8} alignItems="center">
									<Grid item xs={3}>
										<Typography component="p">
											Promotion receipt name
										</Typography>
									</Grid>

									<Grid item>
										<Field inputProps={{ maxLength: 20, size: 20 }} name="InnerPromotion.DiscountReceiptName" validate={required} type="text" component={ReduxTextField} props={{ margin: "dense" }} />
									</Grid>
								</Grid>

								<Grid container spacing={8} alignItems="center">
									<Grid item xs={3}>
										<Typography component="p">
											Promotion SKU
										</Typography>
									</Grid>

									<Grid item>
										<Field inputProps={{ size: 50, maxLength: 255 }} name="InnerPromotion.DiscountSKU" validate={required && sku} type="text" component={ReduxTextField} props={{ margin: "dense" }} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.step}>
							<Grid item>
								<div className={classes.circle}>3</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center" className={classes.expandTitle}>
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Qualifiers
										</Typography>
									</Grid>

									<Grid item>
										<Typography>What does the customer need to buy/do to qualify for the promotion?</Typography>
									</Grid>
								</Grid>

								<FieldArray name="InnerPromotion.Qualifiers" validate={qualifier} component={Qualifiers} props={{ classes: classes }}></FieldArray>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.step}>
							<Grid item>
								<div className={classes.circle}>4</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Complete the promotion
										</Typography>
									</Grid>

									<Grid item>
										<Typography>Which additional items is the customer offered to complete the promotion?</Typography>
									</Grid>
								</Grid>

								<Field name="RewardType" component={ReduxRadioGroup} validate={rewards} error={classes.error}>
									<RadioText label="No additional items are required to complete the deal" value="" />

									<RadioText label="All items in these categories" value="1" renderElement={() => {
										return (
											<Field name="InnerPromotion.Rewards.Categories" component={ReduxSelect} props={{ multiple: true }}>
												<MenuItem value="" disabled>Select multiple</MenuItem>
												{categories && categories.map(category => (
													<MenuItem key={category.Id} value={category.Id}>{category.Name}</MenuItem>
												))}
											</Field>
										)
									}} />

									<RadioText label="Reward SKU list(s)" value="2" renderElement={() => {
										return <FieldArray name="InnerPromotion.Rewards.SKUs" component={Rewards}></FieldArray>
									}} />
								</Field>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.step}>
							<Grid item>
								<div className={classes.circle}>5</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Promotion behaviour
										</Typography>
									</Grid>

									<Grid item>
										<Typography>What happens when the promotion is completed?</Typography>
									</Grid>
								</Grid>

								<Grid container>
									<Field name="InnerPromotion.Type" component={ReduxRadioGroup} validate={behaviour} error={classes.error}>
										<RadioText validate={required} label="Fixed total price for deal" value="0" text="InnerPromotion.Amount" />
										<RadioText validate={required} label="Fixed price of reward item" value="1" text="InnerPromotion.Amount" />
										<RadioText validate={required} label="£ Discount on total basket" value="2" text="InnerPromotion.Amount" />
										<RadioText validate={required} label="% Discount on total basket" value="3" text="InnerPromotion.Amount" />
										<RadioText validate={required} label="Apply" value="4" text="InnerPromotion.Amount" textAfter="additional MORE points to customer's account" />
										<RadioText validate={required} label="Cheapest item free" value="5" />
									</Field>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.step}>
							<Grid item>
								<div className={classes.circle}>6</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Promotion layering
										</Typography>
									</Grid>

									<Grid item>
										<Typography>How does this promotion layer with other promotions?</Typography>
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={12}>
										<Field name="InnerPromotion.Layering.AllowLayering" component={ReduxSwitch} type="checkbox" label="Can be layered with other 'layered' promotions"></Field>
									</Grid>

									<Grid item xs={12}>
										<Field name="InnerPromotion.Layering.BestValueSearch" component={ReduxSwitch} type="checkbox" label="Best value search"></Field>
									</Grid>

									<Grid item xs={12}>
										<Field name="InnerPromotion.Layering.ApplyOnce" component={ReduxSwitch} type="checkbox" label="Only apply once"></Field>
									</Grid>

									<Grid item xs={12}>
										<Field name="InnerPromotion.Layering.HideQualifiedItems" component={ReduxSwitch} type="checkbox" label="Hide qualified items from other promotions"></Field>
									</Grid>

									<Grid item xs={12}>
										<Field name="InnerPromotion.Layering.RelatedPromotionIds" type="checkbox" component={ReduxHasSelect} props={{ component: ReduxSwitch, label: "Only when part of this promotion" }}></Field>

										<Field name="InnerPromotion.Layering.RelatedPromotionIds" component={ReduxSelect} props={{ multiple: true }}>
											<MenuItem value="" disabled>Select multiple</MenuItem>
											{promotions.map(promotion => (
												<MenuItem key={promotion.PromotionId} value={promotion.PromotionId}>{promotion.Name}</MenuItem>
											))}
										</Field>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.step}>
							<Grid item>
								<div className={classes.circle}>7</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Scheduling
										</Typography>
									</Grid>

									<Grid item>
										<Typography>When does this promotion run?</Typography>
									</Grid>
								</Grid>

								<Grid container spacing={2} alignItems="flex-start">
									<Grid item>
										<Field name="InnerPromotion.Schedule.StartDate" component={ReduxDateField} props={{ label: "Start date" }}></Field>
									</Grid>

									<Grid item>
										<Field name="InnerPromotion.Schedule.EndDate" validate={endDate} error={classes.error} component={ReduxDateField} props={{ label: "End date" }}></Field>
									</Grid>

									<Grid item>
										<Field name="InnerPromotion.Schedule.EndDate" validate={endDate} component={ReduxNullField} props={{ component: ReduxSwitch, label: "No end date" }}></Field>
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={12}>
										<Typography component="span" className={classes.everyText}>Every</Typography>

										<Grid container spacing={2}>
											<Field name="InnerPromotion.Schedule.Every" component={Every}></Field>
										</Grid>
									</Grid>
								</Grid>

								<Grid container alignItems="center" spacing={2}>
									<Grid item>
										<Typography>Between</Typography>
									</Grid>

									<Grid item>

										<Field name="InnerPromotion.Schedule.BetweenStart" component={ReduxTimeField}></Field>
									</Grid>

									<Grid item>
										<Typography>and</Typography>
									</Grid>

									<Grid item>
										<Field name="InnerPromotion.Schedule.BetweenEnd" component={ReduxTimeField}></Field>
									</Grid>

									<Grid item>
										<Field name="InnerPromotion.Schedule.BetweenEnd" component={ReduxNullField} props={{ component: ReduxSwitch, label: "All day" }}></Field>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.step}>
							<Grid item>
								<div className={classes.circle}>8</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Stores
										</Typography>
									</Grid>

									<Grid item>
										<Typography>Where does this promotion run?</Typography>
									</Grid>
								</Grid>

								<Grid container>
									<Grid item>
										<Field name="StoreType" component={ReduxRadioGroup}>
											<FormControlLabel value="" control={<Radio color="primary" />} label="All stores"></FormControlLabel>
											<StoreList value="1" list="InnerPromotion.Stores.StoreIds" label="All stores except these (enter store numbers, one per line)"></StoreList>
											<StoreList value="2" list="InnerPromotion.Stores.StoreIds" label="Just these stores (enter store numbers, one per line)"></StoreList>
										</Field>

										<Button variant="contained" style={{ marginTop: 16 }} onClick={() => this.openGroupDialog()}>Load store numbers from group</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.step}>
							<Grid item>
								<div className={classes.circle}>9</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Upsells
										</Typography>
									</Grid>

									<Grid item>
										{/* <Typography>Should this promotion try to upsell?</Typography> */}
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={12}>
										<Field name="InnerPromotion.Upsell.Selected" component={ReduxSwitch} type="checkbox" props={{ label: "Should this promotion try to upsell?" }}></Field>
									</Grid>

									<Collapse in={upsellSelected} collapsedHeight="0" className={classes.collapse}>
										<Grid container>
											<Grid item xs={12}>
												<Field name="InnerPromotion.Upsell.Title" component={ReduxTextField} props={{ label: "Title", fullWidth: true, placeholder: "Add a Muffin for £1.00?", margin: "dense" }}></Field>
											</Grid>

											<Grid item xs={12}>
												<Field name="InnerPromotion.Upsell.Message" component={ReduxTextField} props={{ label: "Message", fullWidth: true, placeholder: "We'll apply the discount to your basket.", margin: "dense" }}></Field>
											</Grid>

											<Grid item xs={12}>
												<Field name="InnerPromotion.Upsell.Reason" component={ReduxTextField} props={{ label: "Reason", fullWidth: true, placeholder: "MUFFINFORPOUND", margin: "dense" }}></Field>
											</Grid>
										</Grid>
									</Collapse>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.step}>
							<Grid item>
								<div className={classes.circle}>10</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Storeline Ratio
										</Typography>
									</Grid>

									<Grid item>
										{/* <Typography>Should this promotion try to upsell?</Typography> */}
									</Grid>
								</Grid>

								<Grid container>
									<Field name="InnerPromotion.StorelineRatio" component={ReduxRadioGroup} validate={behaviour} error={classes.error}>
										<FormControlLabel value="0" control={<Radio color="primary" />} label="Split the discount across all matching menu items" />
										<FormControlLabel value="1" control={<Radio color="primary" />} label="Split the discount across rewards only" />
										<FormControlLabel value="2" control={<Radio color="primary" />} label="Split the items by half and apply mostly to the first half (for meal deals)" />
										<FormControlLabel value="3" control={<Radio color="primary" />} label="Split the discount across the entire basket" />
									</Field>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.step}>
							<Grid item>
								<div className={classes.circle}>11</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Roundels
										</Typography>
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={12}>
										<Field name="InnerPromotion.Roundels.ApplyToQualifiers" component={ReduxSwitch} type="checkbox" label="Apply to Qualifiers"></Field>
									</Grid>

									<Grid item xs={12}>
										<Field name="InnerPromotion.Roundels.ApplyToRewards" component={ReduxSwitch} type="checkbox" label="Apply to Rewards"></Field>
									</Grid>
									<Grid container spacing={8} alignItems="center">
										<Grid item xs={3}>
											<Typography component="p">
												Priority (higher priority appears first)
											</Typography>
										</Grid>

										<Grid item>
											<Field inputProps={{ maxLength: 20, size: 20 }} name="InnerPromotion.Roundels.Priority" type="number" component={ReduxTextField} props={{ margin: "dense" }} />
										</Grid>
									</Grid>
									<Grid container spacing={8} alignItems="center">
										<Grid item xs={3}>
											<Typography component="p">
												PDP Roundel
											</Typography>
										</Grid>

										<Grid item>
											<Field inputProps={{ maxLength: 100 }} name="InnerPromotion.Roundels.PdpRoundel" type="text" component={ReduxTextField} props={{ margin: "dense" }} />
										</Grid>
									</Grid>
									<Grid container spacing={8} alignItems="center">
										<Grid item xs={3}>
											<Typography component="p">
												PLP Roundel
											</Typography>
										</Grid>

										<Grid item>
											<Field inputProps={{ maxLength: 100 }} name="InnerPromotion.Roundels.PlpRoundel" type="text" component={ReduxTextField} props={{ margin: "dense" }} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.lastStep}>
							<Grid item>
								<div className={classes.circle}>12</div>
							</Grid>

							<Grid item className={classes.desc}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography component="h3" className={classes.label}>
											Age Restriction
										</Typography>
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={12}>
										<Field name="InnerPromotion.AgeRestriction.IsAgeRestricted" component={ReduxSwitch} type="checkbox" label="Is this promotion age restricted? (under 16)"></Field>
									</Grid>									
								</Grid>
							</Grid>
						</Grid>

						<AppBar position="fixed" className={classes.appBar} color="secondary">
							<Grid container spacing={2} className={classes.save} justify="flex-end" alignItems="center">
								<Grid item>
									<Button variant="contained" onClick={() => this.cancel()} disabled={isSubmitting || pristine}>Cancel</Button>
								</Grid>

								{!valid && <Grid item>
									<Typography component="span" color="error">Please fill in the required fields</Typography>
								</Grid>}

								<Grid item>
									<Button variant="contained" color="primary" type="submit" disabled={isSubmitting || !valid}>Save</Button>
								</Grid>
							</Grid>
						</AppBar>
					</Form>
				)}

				<StoreGroupDialog open={showGroupDialog} handleClose={() => this.closeGroupDialog()} {...this.props} />
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));
	const disableSidebar = (disable) => dispatch(dashboardOperations.disableSidebar(disable));
	const getPromotions = (id, callback) => dispatch(promotionOperations.getPromotions(id, callback));
	const updatePromotion = (promotion, callback) => dispatch(promotionOperations.updatePromotion(promotion, callback));
	const createPromotion = (promotion, callback) => dispatch(promotionOperations.createPromotion(promotion, callback));
	const getCategories = () => dispatch(promotionOperations.getCategories());

	//Store groups functionality
	const getGroups = () => dispatch(promotionOperations.getGroups());
	const getStores = () => dispatch(promotionOperations.getStores());
	const createGroup = (group) => dispatch(promotionOperations.createGroup(group));
	const removeGroup = (group) => dispatch(promotionOperations.removeGroup(group));
	const updateGroup = (group) => dispatch(promotionOperations.updateGroup(group));

	return { updateTitle, getPromotions, updatePromotion, createPromotion, getCategories, getStores, getGroups, createGroup, removeGroup, updateGroup, disableSidebar }
}

function isNumber(value) {
	return typeof value === 'number' && isFinite(value);
}

function mapValues(values) {
	if (!values)
		return;

	//Fix radio buttons
	if (values.InnerPromotion.Qualifiers) {
		values.InnerPromotion.Qualifiers.forEach(q => {
			if (isNumber(q.MinProductValue))
				q.Type = 0;
			else if (isNumber(q.MinBasketValue))
				q.Type = 1;
			else if (q.Card)
				q.Type = 2;
			else if (q.SKUs)
				q.Type = 3;
		});
	}

	if (values.InnerPromotion.Rewards)
		values.RewardType = values.InnerPromotion.Rewards.Categories && values.InnerPromotion.Rewards.Categories.length > 0 ? "1" : values.InnerPromotion.Rewards.SKUs ? "2" : "";

	if (values.InnerPromotion.Stores)
		if (values.InnerPromotion.Stores.StoreIds)
			values.StoreType = values.InnerPromotion.Stores.ExcludingStores ? "1" : "2";

	if (values.InnerPromotion.Upsell)
		values.InnerPromotion.Upsell.Selected = true;

	//Default to true
	if (!values.InnerPromotion.Layering)
		values.InnerPromotion.Layering = { AllowLayering: true };

	//Fix dates
	if (values.InnerPromotion.Schedule) {

		//
		var startDate = moment(values.InnerPromotion.Schedule.StartDate);
		if (startDate.isValid()) {
			values.InnerPromotion.Schedule.StartDate = startDate.format("YYYY-MM-DD");
		}

		//
		var endDate = moment(values.InnerPromotion.Schedule.EndDate);
		if (endDate.isValid())
			values.InnerPromotion.Schedule.EndDate = endDate.format("YYYY-MM-DD");

		//
		var betweenStart = moment(values.InnerPromotion.Schedule.BetweenStart);
		if (betweenStart.isValid())
			values.InnerPromotion.Schedule.BetweenStart = betweenStart.format("HH:mm:ss");

		//
		var betweenEnd = moment(values.InnerPromotion.Schedule.BetweenEnd);
		if (betweenEnd.isValid())
			values.InnerPromotion.Schedule.BetweenEnd = betweenEnd.format("HH:mm:ss");
	}

	return values;
}

const validate = values => {
	var errors = {
		"InnerPromotion": {
			"Type": ""
		}
	};

	//Reward check
	if (!values.RewardType || values.RewardType === "0") {
		if (values.InnerPromotion && values.InnerPromotion.Type === "1" && (values.InnerPromotion.Rewards.Categories || values.InnerPromotion.Rewards.SKUs)) {
			errors["InnerPromotion"]["Type"] = "Must set a reward if reward item behaviour is selected";
		}
	}

	return errors;
}

var redux = connect(state => ({
	promotions: state.promotions.promotions,
	categories: state.promotions.categories,
	stores: state.promotions.stores,
	groups: state.promotions.groups,
	initialValues: mapValues(state.promotions.promotion),
	upsellSelected: formValueSelector("promotion")(state, "InnerPromotion.Upsell.Selected")
}), mapDispatchToProps)(reduxForm({ form: "promotion", validate })(PromotionsPage))

export default withStyles(styles)(redux);