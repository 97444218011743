import mkLogo from "../images/MK_logo_on_Black.svg";
import wallboardLogo from '../images/MK_logo_no_bg.svg';
import logo from '../images/cafe_logo_green_23.png';

var mk = false;
 

mk=false;


export default mk ? {
	logo: mkLogo,
	wallboardLogo,
	primary: "#000",
	secondary: "#FFF",
	name: "Morrisons Market Kitchen Portal",
	nameShort: "Market Kitchen Portal",
	opsNameShort: "Market Operations Portal",
	wallboardName: "MARKET KITCHEN SALES MONITOR",
	showScreenshots: true,
	mk:true
} : {
	logo,
	wallboardLogo: logo,
	primary: "#00563F",
	secondary: "#FFF",
	name: "Morrisons Café Order Point Portal",
	nameShort: "Café Order Point Portal",
	opsNameShort: "Café Ops",
	showScreenshots: false,
	mk: false,
	wallboardName: "MORRISONS CAFÉ SALES MONITOR",
};