import React from "react";
import { TextField, FormControlLabel, Select, RadioGroup, Switch } from "@material-ui/core";
import { isBoolean } from "lodash";

const ReduxTextField = (props) => {
	const { input, meta, type, ...otherProps } = props;
	return <TextField {...otherProps} value={input.value} onChange={input.onChange} error={!!meta.error} helperText={meta.error} />
};

const ReduxSKUList = (props) => {
	const { input, meta, type, ...otherProps } = props;

	//Combine the array to a list of values with new line
	var value = input.value ? input.value instanceof Array ? input.value.join("\n") : input.value : "";
	
	//Save as an array by spliting my new lines
	return <TextField multiline style={{width: "100%"}} rows="10" variant="outlined" {...otherProps} value={value} onChange={(ev) => {
		input.onChange(ev.target.value.split("\n"));
	}}/>
};

const ReduxSelect = (props) => {
	const { input, meta, type, ...otherProps } = props;
	if(otherProps.multiple)
	{
		//Make sure it's an empty array
		if(!input.value)
			input.value = [];

		otherProps.renderValue = selected => {
			if (selected.length === 0) {
				return <em>{otherProps.children[0].props.children}</em>;
			}

			return selected.join(', ');
		};
	}

	return <Select displayEmpty {...otherProps} value={input.value} onChange={input.onChange} />
};

const ReduxRadioGroup = (props) => {
	const { input, meta, type, ...otherProps } = props;
	return (
		<div>
			<RadioGroup {...otherProps} value={`${input.value}`} onChange={input.onChange} />
			{meta.error && <p className={otherProps.error || ""}>{meta.error}</p>}
		</div>
	);
};

const ReduxSwitch = (props) => {
	const { input, meta, type, ...otherProps } = props;
	return <FormControlLabel label={otherProps.label} control={<Switch color="primary" {...otherProps} checked={isBoolean(input.checked) ? input.checked : false} onChange={input.onChange} />}/>
};

const ReduxDateField = (props) => {
	const { input, meta, type, timeonly, ...otherProps } = props;
	return <div>
		<TextField variant="outlined" type={timeonly ? "time" : "date"} label={otherProps.label} InputLabelProps={{shrink: true}} margin="dense" value={input.value} onChange={input.onChange}></TextField>	
		{meta.error && <p className={otherProps.error || ""}>{meta.error}</p>}
	</div>
};

const ReduxTimeField = (props) => {
	return ReduxDateField({...props, timeonly: true});
};

const ReduxNullField = (props) => {
	const { input, component, ...others } = props;

	others.input = {
		checked: !input.value,
		onChange: function(ev) {
			input.onChange(null);
		}
	};

	return component(others);
};

const ReduxHasSelect = (props) => {
	const { input, component, ...others } = props;

	others.input = {
		checked: input.value && input.value.length > 0,
		onChange: function(ev) {
			input.onChange(null);
		}
	};

	return component(others);
};

export {
	ReduxTextField,
	ReduxSKUList,
	ReduxSelect,
	ReduxRadioGroup,
	ReduxSwitch,
	ReduxDateField,
	ReduxTimeField,
	ReduxNullField,
	ReduxHasSelect
};