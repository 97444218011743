import { connect } from "react-redux";
import ScreenshotsComponent from "./ScreenshotsComponent";
import { screenshotsOperations } from "./duck";
import { dashboardOperations } from '../../duck'

const mapStateToProps = state => {
    const { stores, loading,cacheBust } = state.screenshots;

    return {
        stores,
        cacheBust,
        loading
    };
};

const mapDispatchToProps = dispatch => {
    const onLoadScreenshotsJson = () => dispatch(screenshotsOperations.loadScreenshotsJson());
    const updateTitle = (title) => dispatch(dashboardOperations.updateTitle(title));

    return {
        onLoadScreenshotsJson,
        updateTitle
    };
};

const ScreenshotsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ScreenshotsComponent);

export default ScreenshotsContainer;
