import React from 'react';
import ReportHelpers from '../ReportHelpers';
import ReactTable from 'react-table-v6'
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
  tableGeneralHeaderFooter: {
    textAlign: "left", 
    fontWeight: "bold" 
  },
  tableNumericHeaderFooter: {
    textAlign: "right", 
    fontWeight: "bold" 
  },
  tableNumericColumn: {
    textAlign: "right", 
  },
  tableGeneralColumn: {
    textAlign: "left", 
  },
  noDataTable: {
    opacity: "0.5",
    pointerEvents:"none"
  },
});


class BasketsByIndividualKioskTable extends React.Component {
    render() {
        const { classes,tableHeight, data,heading } = this.props;
        const columns = 
        [
            {
              Header: <div className={classes.tableGeneralHeaderFooter}>Store</div>,
              accessor: 'Store', 
              Cell: row => <div className={classes.tableGeneralColumn}>{row.value}</div>
            },
            {
              Header: <div className={classes.tableGeneralHeaderFooter}>Till Number</div>,
              accessor: 'TillNumber', 
              Cell: row => <div className={classes.tableGeneralColumn}>{row.value}</div>,
              width: 110,
              sortable: false,
            },
            {
              Header: props => <div className={classes.tableNumericHeaderFooter}>Total</div>,
              accessor: 'Total',
              Cell: row => <div className={classes.tableNumericColumn}>{ReportHelpers.formatNumber(row.value)}</div>,
              Footer: info => {
                  return <div className={classes.tableNumericHeaderFooter}>{ReportHelpers.formatNumber(data.reduce((a, b) => a + (b.Total || 0), 0))}</div>
              },
              width: 120
            }
          ]
        return (<div style={{ width: "95%" }} className={ classNames( data.length  === 0 ? classes.noDataTable : null )}>
          <div className={classes.chartTitle}>{heading}</div>
          <ReactTable style={{ width: "100%", height: tableHeight + "px" }}
              showPagination={true}
              showPageSizeOptions={false}
              noDataText="No data."          
              data={data}
              columns={columns}/>
        </div>)
    }
}

export default withStyles(styles)(BasketsByIndividualKioskTable);